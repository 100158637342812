import Vue from 'vue'
import Vuex from 'vuex'
import StoreService, { IOrder, IQuantityPriceList, ISaleOrder, ISaleOrderItem, IShippingMethod } from '@/services/store.service';
import eventBus from '@/services/eventBus';
import CatalogService, { IAttr, IBrand, ICategory, ICountry, IGenericFilterValue, IInitialData, IPresentation, IProduct, IValueOption } from '@/services/catalog.service';
import BannersService, { IBanner } from '@/services/banners.service';
import AuthService, { IAddress, Identity } from '@/services/auth.service';
import { ILocalSaleOrderItem } from '@/services/local.store.service';

export const SORT_METHOD_STOCK_HL = 1;
export const SORT_METHOD_STOCK_LH = 2;
export const SORT_METHOD_PRICE_HL = 3;
export const SORT_METHOD_PRICE_LH = 4;
import VuexPersistence from 'vuex-persist'

export interface IPresentationItem {
    qty: number;
    presentation: IPresentation;
}

export interface State {
    download: boolean,
    count: number,
    cart: ISaleOrder | null,
    order: IOrder | null,
    banners: IBanner[],
    countries: ICountry[],
    website: any | null,

    brands: IBrand[],
    categories: ICategory[],
    identity: Identity | null,
    addresses: IAddress[],
    shippingMethods: IShippingMethod[],

    quantityPriceLists: IQuantityPriceList[],
    products: IProduct[],
    filters: IAttr[],
    
    localCartItems: ILocalSaleOrderItem[],

    sortMethod: number,
    // -- filters

    sortOptions: any[],
    
    attributes: any[],
    productsCatalog: any[],
    selectedAttributes: any[],

    tags: string[],
    selectedTags: string[],
    selectedBrands: any[],

    genders: IGenericFilterValue[],
    selectedGenders: IGenericFilterValue[],

    dressings: IGenericFilterValue[],
    selectedDressings: IGenericFilterValue[],

    pockets: IGenericFilterValue[],
    selectedPockets: IGenericFilterValue[],

    seasons: IGenericFilterValue[],
    selectedSeasons: IGenericFilterValue[],

    tiros: IGenericFilterValue[],
    selectedTiros: IGenericFilterValue[],
    
    cuts: IGenericFilterValue[],
    selectedCuts: IGenericFilterValue[],

    types: IGenericFilterValue[],
    selectedTypes: IGenericFilterValue[],
    
    // --
    showSearch: boolean,
    brandsFilter: IGenericFilterValue[],
    selectedPackages: IPresentationItem[],
    selectedBoxes: IPresentationItem[],
    
}

export interface SelectOption {
    value: number|string,
    text: number|string
}

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        productsCatalog: [],
        download: false,
        count: 0,
        cart: null,
        order: null,
        banners: [],
        countries: [],
        website: null,

        brands: [],
        categories: [],

        identity: null,
        addresses: [],
        shippingMethods: [],

        quantityPriceLists: [],
        products: [],
        filters: [],
        localCartItems: [],

        sortMethod: 1,
    
        // -- filters
        sortOptions: [
            {
                id: SORT_METHOD_STOCK_HL,
                name: 'Mayor existencias primero',
            },
            {
                id: SORT_METHOD_STOCK_LH,
                name: 'Menor existencias primero',
            },
            {
                id: SORT_METHOD_PRICE_HL,
                name: 'Mayor precio primero',
            },
            {
                id: SORT_METHOD_PRICE_LH,
                name: 'Menor precio primero',
            }
        ],
        tags: [],
        brandsFilter: [],
        selectedTags: [],
        selectedBrands: [],
    
        attributes: [],
        selectedAttributes: [],

        genders: [],
        selectedGenders: [],
    
        dressings: [],
        selectedDressings: [],
    
        pockets: [],
        selectedPockets: [],
    
        seasons: [],
        selectedSeasons: [],
    
        tiros: [],
        selectedTiros: [],
        
        cuts: [],
        selectedCuts: [],
    
        types: [],
        selectedTypes: [],
        
        showSearch: false,


        selectedPackages: [],
        selectedBoxes: [],
    } as State,
    plugins: [new VuexPersistence().plugin],
    getters: {
        baseUrl: state => {
            return process.env.VUE_BASE_URL;
        },
        getProductsCatalog: state => {
            return state.productsCatalog
        },
        getDownLoad: state => {
            return state.download
        },
        // shopping cart
        itemCount: state => {
            let count = 0;
            if(state.cart != null) {
                let items = state.cart.items.filter((item:ISaleOrderItem) => item.type == 'product');
                for(let item of items) {
                    count += item.product_uom_qty;
                }
            }

            return count;
        },
        hasItems: state => {
            let count = 0;
            if(state.cart != null) {
                let items = state.cart.items.filter((item:ISaleOrderItem) => item.type == 'product');
                for(let item of items) {
                    count += item.product_uom_qty;
                }
            }

            return count > 0;
        },
        productItems: state => {
            if(state.cart != null)
                return state.cart.items.filter((item:ISaleOrderItem) => item.type == 'product');
            return [];
        },
        serviceItems: state => {
            if(state.cart != null)
                return state.cart.items.filter((item:ISaleOrderItem) => item.type == 'service');

            return [];
        },
        shippingAddressId: state => {
            if(state.cart != null
                && Array.isArray(state.cart.partner_shipping_id)
                && state.cart.partner_shipping_id.length == 2) {
                return state.cart.partner_shipping_id[0];
            }

            return null;
        },
        shippingMethodId: state => {
            if(state.cart != null
                && Array.isArray(state.cart.carrier_id)
                && state.cart.carrier_id.length == 2) {
                return state.cart.carrier_id[0];
            }

            return null;
        },
        validShippingMethods: state => {
            if(state.shippingMethods == null)
                return [];
                
            return state.shippingMethods.filter((item:IShippingMethod) => item.quote.price > 0 || (item.quote.price == 0 && item.quote.apply) );
        },
        nextDiscountLevel: state => {
            let item_count = 0;
            if(state.cart != null) {
                let items = state.cart.items.filter((item:ISaleOrderItem) => item.type == 'product');
                for(let item of items) {
                    item_count += item.product_uom_qty;
                }
            }

            let nextLevel = state.quantityPriceLists.find((qpl:IQuantityPriceList) => qpl.x_studio_amount_of_pieces > item_count);
            
            if(nextLevel != undefined) {
                return nextLevel;
            }

            return null;
        },
        countryOptions: state => {
            return state.countries.map((item:ICountry) => {
                return {
                    value: item.id,
                    text: item.name
                } as SelectOption;
            });
        },

        // auth
        isLoggedIn: state => {
            return state.identity != null;
        },

        isRetailCustomer: state => {
            return state?.identity?.data?.type == "RETAIL";
        },

        isWholesellerCustomer: state => {
            return state?.identity?.data?.type == "WHOLESELLER";
        },

        isRestrictedWholesellerCustomer: state => {
            // console.log(state.identity?.data.pricelist_name)
            return state?.identity?.data?.type == "WHOLESELLER" 
            && state?.identity?.data?.pricelist_name != null
            && state?.identity?.data?.pricelist_name.toLowerCase() == 'mayoreo'  ;
        },

    
        // banners
        headerBanners: state => {
            return state.banners.filter((item:IBanner) => item.location == 'HEADER');
        },
        contentBanners: state => {
            return state.banners.filter((item:IBanner) => item.location == 'CONTENT');
        },

        // brands
        carouselBrands: state => {
            return state.brands.filter((brand:IBrand) => brand.logo != null);
        },
        // categories
        rootCategories: state => {
            return state.categories.filter((item:ICategory) => item.parent_id == null);
        },

        featuredCategories: state => {
            return state.categories.filter((item:ICategory) => item.featured == true);
        },
        activeFilters: state => {
            let filters = state.filters.filter( (f:IAttr) => f.values.filter((v:IValueOption) => v.selected == true).length != 0 );
            let attrs = {};
            for(let i = 0; i < filters.length; i++) {
                attrs[filters[i].key] = filters[i].values
                            .filter((v:IValueOption) => v.selected == true)
                            .map((v:IValueOption) => v.value);
            }

            return attrs;
        },
        getFilters: state => {

            return [];
            // let oldFilters:IAttr[] = state.filters;
            // let filters:IAttr[] = oldFilters;
            // // for each product
            // for(let i = 0; i < state.products.length; i++) { 
    
            //   // for each filter
            //   for(let j = 0; j < state.products[i].filters.length; j++){
                
            //     let fi = filters.findIndex((attr:IAttr) => { return attr.name == state.products[i].filters[j].name; });
            //     if(fi == -1) {
            //       // if does not exists add it
            //       filters.push({ key: state.products[i].filters[j].key, name: state.products[i].filters[j].name, values: [] });
    
            //       fi = filters.length -1;
            //     }
    
            //     for(let k = 0; k < state.products[i].filters[j].values.length; k++) {
                  
            //       // find if value is already added registred
            //       let vi = filters[fi].values.findIndex((attr:IValueOption) => { 
            //         return attr.value == state.products[i].filters[j].values[k].value; 
            //       });
    
            //       if(vi == -1) {
            //         filters[fi].values.push(state.products[i].filters[j].values[k]);
            //         // sort filters first they wich are numbers
            //           let num_values = filters[fi].values.filter((fv:IValueOption) => !isNaN(parseInt(fv.text)));
            //           num_values.sort((a:IValueOption, b:IValueOption) => { return parseInt(a.text) - parseInt(b.text); });
            //           let string_values = filters[fi].values.filter((fv:IValueOption) => isNaN(parseInt(fv.text)));
            //           string_values.sort((a:IValueOption, b:IValueOption) => {
            //             if (a.text > b.text) {
            //               return 1;
            //             }
            //             if (a.text < b.text) {
            //               return -1;
            //             }
            //             // a must be equal to b
            //             return 0;
            //           });
    
            //           filters[fi].values = [];
            //           for(let x = 0; x < num_values.length; x++) { filters[fi].values.push(num_values[x])  }
            //           for(let x = 0; x < string_values.length; x++) { filters[fi].values.push(string_values[x])  }
            //         // ---
            //       } else {
            //         filters[fi].values[vi].count += 1;
            //       }
            //     }
    
            //   }
            // }
    
            // if(JSON.stringify(oldFilters) !== JSON.stringify(filters)) {

            //   if(JSON.stringify(state.filters) === JSON.stringify(filters)) {
            //         state.filters = filters;
            //         eventBus.$emit('load-models');
            //     } else {
            //         state.filters = filters;
            //     }
            // }

            // return filters;
        },
        localCartTotal: state => {
            let total = 0;
            for(let i = 0; i < state.localCartItems.length; i++) {
                total +=  state.localCartItems[i].total;
            }

            return total;
        },
        localCartItemCount: state => {
            let qty = 0;
            for(let i = 0; i < state.localCartItems.length; i++) {
                // console.log(state.localCartItems[i]);
                qty +=  state.localCartItems[i].qty;
            }

            return qty;
        },

        getProducts(state) {
            return state.products;
        },
        getAttributes: state => {
            return state.attributes;
        },
        getTags: state => {
            return state.tags;
        },
        getBrandsFilter: state => {
            return state.brandsFilter;
        },
        getGenders: state => {
            return state.genders;
        },
        getDressings: state => {
            return state.dressings;
        },
        getPockets: state => {
            return state.pockets;
        },
        getSeasons: state => {
            return state.seasons;
        },
        getTiros: state => {
            return state.tiros;
        },
        getCuts: state => {
            return state.cuts;
        },
        getTypes: state => {
            return state.types;
        },

        getSelectedAttributes: state => {
            return state.selectedAttributes;
        },
        getSelectedTags: state => {
            return state.selectedTags;
        },
        getSelectedBrands: state => {
            return state.selectedBrands;
        },
        getSelectedGenders: state => {
            return state.selectedGenders;
        },
        getSelectedDressings: state => {
            return state.selectedDressings;
        },
        getSelectedPockets: state => {
            return state.selectedPockets;
        },
        getSelectedSeasons: state => {
            return state.selectedSeasons;
        },
        getSelectedTiros: state => {
            return state.selectedTiros;
        },
        getSelectedCuts: state => {
            return state.selectedCuts;
        },
        getSelectedTypes: state => {
            return state.selectedTypes;
        },

        getAllCategories(state) {
            return state.categories;
        },
        getAllBrands(state) {
            return state.brands;
        },

        getSortOptions(state) {
            return state.sortOptions;
        },

        getShowSearch(state) {
            return state.showSearch;
        },
        getPackageItems(state) {
            return state.selectedPackages;
        },
        getBoxItems(state) {
            return state.selectedBoxes;
        }
    },
    mutations: {
        SET_DOWNLOAD (state, value) {
            state.download = value
        },
        increment (state) {
            state.count++
        },
        loadBanners(state) {
            BannersService.getAll().then((models:IBanner[]) => {
                state.banners = models;
            }).catch(error => {
                state.banners = [];
            });
        },
        loadCountries(state) {
            CatalogService.getCountries().then((models:ICountry[]) => {
                state.countries = models;
            }).catch(error => {
                state.countries = [];
            });
        },
        setShoppingCart(state, model) {
            state.cart = model;
            if(model.items.length != 0) {
                eventBus.$emit('shopping-cart-updated', true);
            }
        },
        loadShoppingCart(state) {
            StoreService.getShoppingCart().then((model:ISaleOrder) => {
                state.cart = model;
                if(model.items.length != 0) {
                    eventBus.$emit('shopping-cart-updated', true);
                }
            }).catch(error => {
                state.cart = null;
            });
        },
        setShippingMethods(state, models) {
            state.shippingMethods = models;
        },
        loadShippingMethods(state) {
            if(state.cart != null) {
                StoreService.quoteShipping(state.cart.id, `${state.cart.partner_shipping_id[0]}`).then((models:any[]) => {
                    state.shippingMethods = models;
                }).catch(error => {
                    state.shippingMethods = [];
                });
            }
        },
        loadBrands(state) {
            CatalogService.getBrands().then((models:IBrand[]) => {
                state.brands = models;
            }).catch(error => {
                state.brands = [];
            });
        },
        loadCategories(state) {
            CatalogService.getCategories().then((models:ICategory[]) => {
                state.categories = models;
            }).catch(error => {
                state.categories = [];
            });
        },
        loadCategoriesMenu(state) {
            CatalogService.getCategoriesMenu().then((models:ICategory[]) => {
                state.categories = models;
            }).catch(error => {
                state.categories = [];
            });
        },
        loadQuantityPriceLists(state) {
            StoreService.getQuantityPriceLists().then((models:IQuantityPriceList[]) => {
                state.quantityPriceLists = models;
            }).catch(error => {
                state.quantityPriceLists = [];
            });
        },
        // Identity
        setIdentity(state, identity:Identity) {
            state.identity = identity;
            AuthService.setIdentity(identity);  
        },
        loadIdentity(state) {
            state.identity = AuthService.getIdentity();
        },
        setAddresses(state, models:IAddress[]) {
            state.addresses = models;
            // AuthService.getAddresses().then((models:IAddress[]) => {
                
            // }).catch(error => {
            //     state.addresses = [];
            // });
        },
        loadAddresses(state) {
            AuthService.getAddresses().then((models:IAddress[]) => {
                state.addresses = models;
            }).catch(error => {
                state.addresses = [];
            });
        },
        // setProducts (state, products) {
        //     state.products = products;
        // },
        setFilters (state, filters) {

            // if(JSON.stringify(state.filters) === JSON.stringify(filters)) {
            //     state.filters = filters;
            //     eventBus.$emit('load-models');
            // } else {
            //     state.filters = filters;
            // }
        },
        resetFilters (state) {
            // console.log('filters reseted');

            let filters:IAttr[] = state.filters;
            for(let i = 0; i < filters.length; i++) {
                for(let j = 0; j < filters[i].values.length; j++) {
                    filters[i].values[j].count = 0;
                }
            }
    
            state.filters = filters;
        },
        addLocalCartItem(state, item:ILocalSaleOrderItem) {
            let ii = state.localCartItems.findIndex((lsoi:ILocalSaleOrderItem) => lsoi.id == `${item.product.id}_${item.variant.id}` );
            if(ii != -1) {
                state.localCartItems[ii].qty += item.qty;
                state.localCartItems[ii].total += state.localCartItems[ii].qty * parseFloat(state.localCartItems[ii].product.price);
            } else {
                state.localCartItems.push(item);
            }
        },
        removeLocalCartItem(state, id:string) {
            let ii = state.localCartItems.findIndex((lsoi:ILocalSaleOrderItem) => lsoi.id == id);
            if(ii != -1) {
                state.localCartItems.splice(ii, 1);
            }
        },
        clearLocalCartItems(state) {
            state.localCartItems = [];
        },

        SET_PRODUCTS(state, products) {

            switch(state.sortMethod)
            {
                case SORT_METHOD_STOCK_HL:
                    state.products.sort((a,b) => { return b.stock - a.stock; })
                    break;
                case SORT_METHOD_STOCK_LH:
                    state.products.sort((a,b) => { return a.stock - b.stock; })
                    break;
                case SORT_METHOD_PRICE_HL:
                    state.products.sort((a,b) => { return b.min_price - a.min_price; })
                    break;
                case SORT_METHOD_PRICE_LH:
                    state.products.sort((a,b) => { return a.min_price - b.min_price; })
                    break;
            }
    
            // let tags:string[] = [];
            // let attributes:any[] = [];
            // for(let i = 0; i < products.length; i++) {
            //     tags.push(...products[i].tags);
                
            //     for(let j = 0; j < products[i].variants_attributes.length; j++) {
            //         const ii = attributes.findIndex((attribute) => attribute.id == products[i].variants_attributes[j].id);
            //         if(ii == -1) {
            //             attributes.push(products[i].variants_attributes[j])
            //         } else {
            //             for( let k = 0; k < products[i].variants_attributes[j].values.length; k++ ) {
            //                 const ij = attributes[ii].values.findIndex((value) => value.id ==  products[i].variants_attributes[j].values[k].id )
            //                 if(ij == -1) {
            //                     attributes[ii].values.push(products[i].variants_attributes[j].values[k]);
            //                 }
            //             }
            //         }
            //     }
            // }
    
            // set products
            state.products = products
    
            // Set tags
            // state.tags = [...new Set(tags)].sort();
    
            // // Set attributes
            // state.attributes = attributes;
    
            // state.products = products;
        },


        SET_PRODUCTS_CATALOG(state, products) {

            state.productsCatalog = products;
        },
        SET_ATTRIBUTES(state, attributes) {

            state.attributes = attributes;
        },
        SET_TAGS(state, tags) {
            state.tags = tags;
        },
        SET_GENDERS(state, values) {
            state.genders = values;
        },
        SET_BRANDS(state, values) {
            state.brandsFilter = values;
        },
        SET_DRESSINGS(state, values) {
            state.dressings = values;
        },
        SET_POCKETS(state, values) {
            state.pockets = values;
        },
        SET_SEASONS(state, values) {
            state.seasons = values;
        },
        SET_TIROS(state, values) {
            state.tiros = values;
        },
        SET_CUTS(state, values) {
            state.cuts = values;
        },
        SET_TYPES(state, values) {
            state.types = values;
        },
        SET_ATTRIBUTE_FILTER(state, values) {
            state.selectedAttributes = values;
        },

        TOGGLE_ATTRIBUTE_FILTER(state, attribute) {
            const ii = state.selectedAttributes.findIndex((selectedAttribute) => selectedAttribute == attribute);
            if(ii != -1) {
                state.selectedAttributes.splice(ii, 1);
            } else {
                state.selectedAttributes.push(attribute);
            }
        },

        TOGGLE_GENDER_FILTER(state, value) {
            const ii = state.selectedGenders.findIndex((selectedGender) => selectedGender == value);
            if(ii != -1) {
                state.selectedGenders.splice(ii, 1);
            } else {
                state.selectedGenders.push(value);
            }
        },
        SET_BRANDS_FILTER(state, values) {
            state.selectedBrands = values;
        },

        SET_GENDER_FILTER(state, value) {
            state.selectedGenders = value != null ? [ value ] : [];
        },
        
        SET_SORT_METHOD(state, value) {
            state.sortMethod = value
        },
        APPLY_SORT(state) {
            switch(state.sortMethod)
            {
                case SORT_METHOD_STOCK_HL:
                    state.products.sort((a,b) => { return b.stock - a.stock; })
                    break;
                case SORT_METHOD_STOCK_LH:
                    state.products.sort((a,b) => { return a.stock - b.stock; })
                    break;
                case SORT_METHOD_PRICE_HL:
                    state.products.sort((a,b) => { return b.min_price - a.min_price; })
                    break;
                case SORT_METHOD_PRICE_LH:
                    state.products.sort((a,b) => { return a.min_price - b.min_price; })
                    break;
            }
        },

        TOGGLE_SEARCH(state) {
            state.showSearch = !state.showSearch;
        },
        SET_PACKAGE_QTY(state, packageItem) {
            const ii = state.selectedPackages.findIndex((selectedPackage) => selectedPackage != undefined && selectedPackage.presentation.id == packageItem.presentation.id);
            if(ii != -1) {
                if(packageItem.qty > 0) {
                    state.selectedPackages[ii] = packageItem;
                } else {
                    state.selectedPackages.splice(ii, 1);
                }
            } else {
                if(packageItem.qty > 0) {
                    state.selectedPackages.push(packageItem);
                }
            }
        },
        SET_BOX_QTY(state, boxItem) {
            const ii = state.selectedBoxes.findIndex((selectedBox) => selectedBox && selectedBox.presentation.id == boxItem.presentation.id);
            if(ii != -1) {
                if(boxItem.qty > 0) {
                    state.selectedBoxes[ii] = boxItem;
                } else {
                    state.selectedBoxes.splice(ii, 1);
                }
            } else {
                if(boxItem.qty > 0) {
                    state.selectedBoxes.push(boxItem);
                }
            }
        }
    },
    actions: {

        setDownload(ctx, value) {
            ctx.commit('SET_DOWNLOAD', value);
        },
        setProducts(ctx, products) {
            ctx.commit('SET_PRODUCTS', products);
        },
        setProductsCatalog(ctx, products) {
            ctx.commit('SET_PRODUCTS_CATALOG', products);
        },
        appendProducts(ctx, products) {
            let current = ctx.state.products as IProduct[];
            current.push(...products)
            ctx.commit('SET_PRODUCTS', current);
        },

        setAttributes(ctx, attributes) {

            attributes.sort((a, b) => {
                if(a.name < b.name) { return -1; }
                if(a.name > b.name) { return 1; }
                
                return 0;
            })

            for(let i = 0; i < attributes.length; i++) {
                attributes[i].values.sort((c, d)  => {
                    if(c.name.trim().toLowerCase() < d.name.trim().toLowerCase()) { return -1; }
                    if(c.name.trim().toLowerCase() > d.name.trim().toLowerCase()) { return 1; }
                    
                    return 0;
                })
            }

            ctx.commit('SET_ATTRIBUTES', attributes);
        },
        setTags(ctx, tags) {
            ctx.commit('SET_TAGS', tags);
        },
        sertBrands(ctx, brands) {
            ctx.commit('SET_BRANDS', brands);
        },
        setGenders(ctx, values) {
            ctx.commit('SET_GENDERS', values);
        },
        setDressings(ctx, values) {
            ctx.commit('SET_DRESSINGS', values);
        },
        setPockets(ctx, values) {
            ctx.commit('SET_POCKETS', values);
        },
        setSeasons(ctx, values) {
            ctx.commit('SET_SEASONS', values);
        },
        setTiros(ctx, values) {
            ctx.commit('SET_TIROS', values);
        },
        setCuts(ctx, values) {
            ctx.commit('SET_CUTS', values);
        },
        setTypes(ctx, values) {
            ctx.commit('SET_TYPES', values);
        },

        toggleAttributeFilter({ commit, state }, value_id) {
            commit('TOGGLE_ATTRIBUTE_FILTER', value_id);
        },

        setAttributesFilter({ commit, state }, values) {
            commit('SET_ATTRIBUTE_FILTER', values);
        },

        toggleGenderFilter({ commit, state }, gender) {
            commit('TOGGLE_GENDER_FILTER', gender);
        },
        setBrandsFilter({ commit, state }, values) {
            commit('SET_BRANDS_FILTER', values);
        },

        setGenderFilter({ commit, state }, gender) {
            commit('SET_GENDER_FILTER', gender);
        },

        applySort(ctx, value) {
            ctx.commit('SET_SORT_METHOD', value)
            ctx.commit('APPLY_SORT')
        },

        toggleSearch({ commit, state }) {
            commit('TOGGLE_SEARCH');
        },

        // verifyIdentity (context) {
        //     AuthService.getProfile().then((identity:Identity) => {
        //         context.commit('setIdentity', identity);
        //     }, err => {
        //         context.commit('setIdentity', null);
        //     });
        // },
        loadInitialData (context) {
            CatalogService.getInitialData().then((model:IInitialData) => {
                // context.state.identity = model.identity;
                // context.commit('setIdentity', model.identity);
                context.state.website = model.webSite;
                context.state.countries = model.countries;
                context.state.banners = model.banners;
                context.state.brands = model.brands;
                context.state.categories = model.categories;
                context.state.cart = model.shoppingCart;
                context.state.quantityPriceLists = model.quantityPriceLists;

            }).catch(error => {
                context.state.identity = null;
                AuthService.setIdentity(null); 
                context.state.brands = [];
                context.state.countries = [];
                context.state.banners = [];
                context.state.categories = [];
                context.state.cart = null;
                context.state.quantityPriceLists = [];
            });
        },
        setPackageQty({ commit }, { presentation, qty }) {

            commit('SET_PACKAGE_QTY', {
                presentation,
                qty
            } as IPresentationItem)
        },
        setBoxQty({ commit }, { presentation, qty }) {
            commit('SET_BOX_QTY', {
                presentation,
                qty
            } as IPresentationItem)
        }
    }
})

export default store;