import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import eventBus from './eventBus';
import AuthService, { Identity } from '@/services/auth.service';

export interface IGenericResponse {
    success:           boolean;
    data:              any;
    error:             any;
}


axios.interceptors.request.use((request:AxiosRequestConfig) => {

    request.baseURL = process.env.VUE_APP_API_URL;

    // Add auth headers when no login request
    // if(request!.url!.includes('default/is-logged-in')
    //     || request!.url!.includes('default/profile')
    //     || request!.url!.includes('default/update-profile')) {
        
    // }

    const identity:Identity|null = AuthService.getIdentity();
    if(identity != null) {
        if(request.headers != undefined) {
            request.headers['token'] = identity.token;
        } 
    }

    eventBus.$emit('loading', true);
    return request;
}, function(err) {
    return Promise.reject(err);
});


axios.interceptors.response.use((successRes:AxiosResponse) => {
        eventBus.$emit('loading', false);
        return successRes!.data;
    }, 
    function(error) {
        eventBus.$emit('loading', false);        
        return Promise.reject(error);
    }
);