<template>
  <iframe
    width="100%"
    height="600"
    frameborder="0"
    scrolling="no"
    marginheight="0"
    marginwidth="0"
    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=Av.%2016%20de%20Septiembre%2046,%20Zona%20Centro,%2038980%20Uriangato,%20Guanajuato+(Luna%20Store)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
  >
  </iframe>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
iframe {
  height: 100%;
  width: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gainsboro;
}

// @media screen and (min-width: $md) {
//   iframe {
//     height: 100%;
//   }
// }
</style>
