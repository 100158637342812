
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// import { IProduct } from "@/services/catalog.service";

@Component
export default class BannerIntro extends Vue {
  @Prop() private title;
  @Prop() private path;
  @Prop() private bg;
  @Prop() private height;
  @Prop() private heightMobile;
  // @Prop() private model!: IProduct;

  data() {
    return {
      setHeight: null,
      isMobile: false,
    };
  }
  mounted() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  }

  @Watch("isMobile")
  setIsMobile() {
    this.setHeigth();
  }
  resizeHandler() {
    this.$data.isMobile = window.innerWidth < 640;
    this.setHeigth();
  }
  setHeigth() {
    if (this.$data.isMobile) {
      this.$data.setHeight = this.heightMobile;
    } else {
      this.$data.setHeight = this.height;
    }
  }
}
