var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.models.length != 0)?_c('div',{staticClass:"carousel-home py-3",attrs:{"id":"top-products"}},[_c('h2',{staticClass:"text-center mt-3 mb-5"},[_vm._v(_vm._s(_vm.title))]),_c('swiper',{attrs:{"navigation":true,"spaceBetween":30,"loop":true,"breakpoints":{
      '500': {
        slidesPerView: 2,
      },
      '900': {
        slidesPerView: 3,
      },
      '1200': {
        slidesPerView: 4,
      },
      '1600': {
        slidesPerView: 4,
      },
      '2000': {
        slidesPerView: 7,
      },
    }}},[_vm._l((_vm.models),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide"},[_c('product-item',{attrs:{"model":item}})],1)}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }