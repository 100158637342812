
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";
import AuthService, { Identity } from "@/services/auth.service";
import eventBus from "@/services/eventBus";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {},
})
export default class ResetPasswordForm extends Vue {
  // @Validate({required, email})
  // email = '';

  // @Validate({required})
  // password = '';

  data() {
    return {
      form: {
        email: null,
      },
      resetPasswordError: null,
      sent: false,
      success: null,
    };
  }

  created() {
    if (
      this.$route.params["id"] != undefined &&
      this.$route.params["key"] != undefined
    ) {
      AuthService.passwordResetRequestConfirm(
        this.$route.params.id,
        this.$route.params.key
      ).then(
        (response: IGenericResponse) => {
          this.$data.success = response.success;
          if (response.success == true) {
            eventBus.$emit(
              "showSuccessModal",
              "Verifica tu correo en busca de tu nueva contraseña."
            );
            this.$router.replace("/auth");
          } else {
            eventBus.$emit(
              "showErrorModal",
              "Datos de validación incorrectos."
            );
          }
        },
        (err) => {
          console.log(err);
          eventBus.$emit("showErrorModal", "Datos de validación incorrectos.");
        }
      );
    }
  }

  @Validations()
  validations() {
    return {
      form: {
        email: { email, required },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      this.$data.sent = true;
      AuthService.passwordResetRequest(this.$data.form.email).then(
        (response: IGenericResponse) => {
          // console.log(response.success);
          if (response.success == true) {
            // Correcto
            this.$data.form.email = null;
            this!["$v"].$reset();
            eventBus.$emit(
              "showSuccessModal",
              "Verifica tu correo en busca del enlace de confirmación."
            );
          } else {
            eventBus.$emit(
              "showErrorModal",
              "Ocurrió un error, valida que la dirección ingresada sea correcta."
            );
          }
        },
        (error) => {
          eventBus.$emit(
            "showErrorModal",
            "Ocurrió un error, valida que la dirección ingresada sea correcta."
          );
        }
      );
    } else {
      eventBus.$emit(
        "showErrorModal",
        "Ocurrió un error, valida que la dirección ingresada sea correcta."
      );
    }
  }

  toggleLogin() {
    eventBus.$emit("toggle-login", true);
  }
}
