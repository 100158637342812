
import { Component, Prop, Vue } from "vue-property-decorator";
import Login from "./Login.vue";
import Signup from "@/components/auth/Signup.vue";

@Component({
  components: {
    login: Login,
    signup: Signup,
  },
})
export default class Auth extends Vue {
  created() {
    if (this.$route.params.signup) {
      this.setLogin(false);
      this.$data.login = false;
    } else {
      this.setLogin(true);
      this.$data.login = true;
    }
  }

  data() {
    return {
      login: true,
    };
  }

  setLogin(login: boolean) {
    this.$data.login = login;
  }
}
