
import {
  IAttr,
  IAttribute,
  IProduct,
  IValueOption,
} from "@/services/catalog.service";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import FilterOpt from "@/components/products/FilterOpt.vue";
// import DownloadCatalogButton from "@/components/products/DownloadCatalogButton.vue";
import { SORT_METHOD_STOCK_HL } from "@/store";
// import GenderFilter from "./Filters/GenderFilter.vue";
import AttributeFilter from "./Filters/AttributeFilter.vue";
@Component({
  components: {
    "filter-opt": FilterOpt,
    // "download-catalog-button": DownloadCatalogButton,
    // "gender-filter": GenderFilter,
    "attribute-filter": AttributeFilter,
  },
})
export default class Filters extends Vue {
  @Prop() private models!: IProduct[];
  @Prop() private clearModel!: boolean;
  data() {
    return {
      sortMethod: null, //SORT_METHOD_STOCK_HL,
      loading: true,
      selected: [],
      filterSelectedBrands: [],
    };
  }
  applySort() {
    this.$store.dispatch("applySort", this.$data.sortMethod);
  }

  created() {
    let attributes = this.$route.query?.attributes
      ? (
        Array.isArray(this.$route.query?.attributes)
          ? this.$route.query?.attributes
          : [this.$route.query?.attributes]
      )
      : []

    let brands = this.$route.query?.brands
    ? (
      Array.isArray(this.$route.query?.brands)
        ? this.$route.query?.brands
        : [this.$route.query?.brands]
    )
    : []

    this.$store.dispatch("setAttributesFilter", attributes)
    this.$data.selected = attributes

    this.$store.dispatch("setBrandsFilter", brands)
    this.$data.filterSelectedBrands = brands
  }

  @Watch("clearModel")
  onModelChange(newVal: any, oldVal): void {
    if (this.clearModel) {
      // this.clearBrands();
      // console.log("limpiar modelo");
      // this.clearAttribute();
      // console.log("limpiar atributes");
      this.$data.selected = [];
      this.$store.dispatch("setAttributesFilter", []);
      // console.log("limpiar brand");
      this.$store.dispatch("setBrandsFilter", []);
      this.$data.filterSelectedBrands = [];
      // // console.log(this.$route.params);
    } else {
      // this.$data.clearModel = false;
      // console.log("emitir false");
    }
  }

  // beforeRouteEnter(to, from, next) {
  //   console.log(to);
  //   console.log(from);
  //   next();
  // }
  get products() {
    return this.$store.getters.getProducts;
  }

  toggleAttributeFilter(values_id) {
    this.$store.dispatch("setAttributesFilter", values_id);
  }

  toggleBrandFilter(values_id) {
    values_id = values_id.filter(v => Boolean(v))
    this.$store.dispatch("setBrandsFilter", values_id);
  }
  clearBrands() {
    this.$data.filterSelectedBrands = [];
    this.$store.dispatch("setBrandsFilter", []);
    this.applyFilters();
  }
  clearAttribute() {
    this.$store.dispatch("setAttributesFilter", []);
    this.$data.seleted = [];
    this.applyFilters();
  }
  clearFilters() {
    this.$data.filterSelectedBrands = [];
    this.$store.dispatch("setBrandsFilter", []);

    this.$store.dispatch("setAttributesFilter", []);

    this.$set(this, "selected", []);

    this.applyFilters();
  }

  // isTagFilterActive(tag) {
  //   // console.log(
  //   //   tag,
  //   //   this.selectedTags.indexOf(tag)
  //   // );
  //   return this.selectedTags.indexOf(tag) != -1;
  // }
  // toggleTagFilter(tag) {
  //   this.$store.dispatch("toggleTagFilter", tag);
  //   const name = this.$route.name ?? "productos";
  //   const query = {
  //     tags: this.selectedTags,
  //     attributes: this.selectedAttributes,
  //   };
  //   let to = {
  //     name,
  //     query,
  //   };
  //   this.$router.push(to);
  // }
  // isAttributeFilterActive(id) {
  //   return this.selectedAttributes.indexOf(id) != -1;
  // }
  // toggleAttributeFilter(value_id) {
  //   this.$store.dispatch("toggleAttributeFilter", value_id);
  //   this.redirect();
  // }
  // isGenderFilterActive(gender) {
  //   return this.selectedGenders.indexOf(gender) != -1;
  // }
  // toggleGenderFilter(gender) {
  //   this.$store.dispatch("toggleGenderFilter", gender);
  //   this.redirect();
  // }
  applyFilters() {
    const name = this.$route.name ?? "Products";

    let query = {
      brands: null,
      attributes: null
    };

    if (this.selectedBrands.length > 0) {
      query.brands = this.selectedBrands.filter((b) => Boolean(b))
    }

    if (this.selectedAttributes.length > 0) {
      query.attributes = this.selectedAttributes.filter((b) => Boolean(b))
    }

    let to = {
      name,
      params: { ...this.$route.params },
      query: { page: "1", ...query },
    };
    this.$router.push(to);
    

    // // console.log("aplicar");
    // const name = this.$route.name ?? "Products";
    // let query = {};
    // if (this.selectedBrands.length != 0) {
    //   query = {
    //     brands:
    //       this.selectedBrands.length != 0
    //         ? this.selectedBrands.filter((b) => Boolean(b))
    //         : null,
    //   };
    // }
    // if (this.selectedAttributes.length != 0) {
    //   query = {
    //     attributes:
    //       this.selectedAttributes.length != 0
    //         ? this.selectedAttributes.filter((l) => Boolean(l))
    //         : null,
    //   };
    // }
    // if (
    //   this.selectedBrands.length != 0 &&
    //   this.selectedAttributes.length != 0
    // ) {
    //   query = {
    //     brands:
    //       this.selectedBrands.length != 0
    //         ? this.selectedBrands.filter((b) => Boolean(b))
    //         : null,
    //     attributes:
    //       this.selectedAttributes.length != 0
    //         ? this.selectedAttributes.filter((l) => Boolean(l))
    //         : null,
    //     // tags: this.selectedTags.length != 0 ? this.selectedTags : null,
    //     // gender: this.selectedGenders.length != 0 ? this.selectedGenders : null,
    //     // search: this.$route.query.search ?? null,
    //   };
    // }

    // let to = {
    //   name,
    //   params: { ...this.$route.params },
    //   query: { page: "1", ...query },
    // };
    // this.$router.push(to);

    // // this.$data.sortMethod = null;
  }
  get sortOptions() {
    return this.$store.getters.getSortOptions;
  }
  get tags() {
    return this.$store.getters.getTags;
  }
  get selectedTags() {
    return this.$store.getters.getSelectedTags;
  }
  get brands() {
    return this.$store.getters.getBrandsFilter;
  }
  get selectedBrands() {
    return this.$store.getters.getSelectedBrands;
  }

  get attributes() {
    const attributes = this.$store.getters.getAttributes.filter((item) => {
      return item.id != 6 || item.name != "COLOR";
    });
    // .map((item) => {
    //   const orderValues = item.values.sort((a, b) => {
    //     if (a.name > b.name) 1;
    //     if (a.name < b.name) -1;
    //     return 0;
    //   });

    //   return {
    //     ...item,
    //     values: orderValues,
    //   };
    // });

    return attributes;
  }
  get selectedAttributes() {
    return this.$store.getters.getSelectedAttributes;
  }
  get genders() {
    return this.$store.getters.getGenders;
  }
  get selectedGenders() {
    return this.$store.getters.getSelectedGenders;
  }
  get dressings() {
    return this.$store.getters.getDressings;
  }
  get selectedDressings() {
    return this.$store.getters.getSelectedDressings;
  }
  get pockets() {
    return this.$store.getters.getPockets;
  }
  get selectedPockets() {
    return this.$store.getters.getSelectedPockets;
  }
  get seasons() {
    return this.$store.getters.getSeasons;
  }
  get selectedSeasons() {
    return this.$store.getters.getSelectedSeasons;
  }
  get tiros() {
    return this.$store.getters.getTiros;
  }
  get selectedTiros() {
    return this.$store.getters.getSelectedTiros;
  }
  get cuts() {
    return this.$store.getters.getCuts;
  }
  get selectedCuts() {
    return this.$store.getters.getSelectedCuts;
  }
  get types() {
    return this.$store.getters.getTypes;
  }
  get selectedTypes() {
    return this.$store.getters.getSelectedTypes;
  }
}
