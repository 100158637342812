
import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import ResetPasswordForm from "@/components/auth/ResetPasswordForm.vue";

@Component({
  metaInfo(this: RecoverPassword): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "reset-password-form": ResetPasswordForm,
    "page-header": PageHeader,
    "page-footer": PageFooter,
  },
})
export default class RecoverPassword extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: "Inicia sesión o crear una cuenta",
      description: "Registrate y obtén una cuenta.",
    };
  }
}
