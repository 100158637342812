
import { Component, Prop, Vue } from "vue-property-decorator";
import AuthService, { Identity } from "@/services/auth.service";
import Cart from "../shopping-cart/widget/Cart.vue";
import Login from "../auth/Login.vue";
import MyAccountMenu from "@/components/my-account/MyAccountMenu.vue";
import MobileNavbar from "@/components/page-header/MobileNavbar.vue";
import TopSlider from "../TopSlider.vue";
import SearchForm from "../SearchForm.vue";

@Component({
  components: {
    cart: Cart,
    login: Login,
    "my-account-menu": MyAccountMenu,
    "mobile-navbar": MobileNavbar,
    TopSlider,
    SearchForm,
  },
})
export default class NavBar extends Vue {
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  data() {
    return {
      sticky: false,
      showPopover: false,
    };
  }
  get categories() {
    const slugs = [
      "variedad-de-ropa-para-caballero",
      "variedad-de-ropa-para-dama",
    ];

    let filtro = [
      ...this.$store.getters.rootCategories.filter((item) =>
        slugs.includes(item.slug)
      ),
    ];

    return filtro;
  }

  signout() {
    // AuthService.setIdentity(null);
    // this.$store.commit('loadIdentity');
    this.$store.commit("setIdentity", null);
    this.$store.commit("loadShoppingCart");
    this.$router.push("/");
  }
  handleScroll() {
    // console.log(window.scrollY < 15);
    if (window.scrollY > 60) {
      this.$data.sticky = true;
    } else {
      this.$data.sticky = false;
    }
  }
  openPopover() {
    this.$data.showPopover = true;
  }
  closePopover() {
    this.$data.showPopover = false;
  }
  onClickOutside(event) {
    this.$data.showPopover = false;
    // console.log("Clicked outside. Event: ", event);
  }
}
