
import { Component, Prop, Vue } from "vue-property-decorator";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";
import AuthService, { Identity } from "@/services/auth.service";
import eventBus from "@/services/eventBus";
import StoreService, { ISaleOrder } from "@/services/store.service";
import { ILocalSaleOrderItem } from "@/services/local.store.service";

@Component({
  components: {},
})
export default class Login extends Vue {
  // @Validate({required, email})
  // email = '';

  // @Validate({required})
  // password = '';

  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      showPassword: false,
      loginError: null,
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        email: { email, required },
        password: { required },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      AuthService.login(this.$data.form.email, this.$data.form.password).then(
        (response: Identity) => {
          this.$store.commit("setIdentity", response);

          StoreService.getShoppingCart().then((model: ISaleOrder) => {
            this.$store.commit("setShoppingCart", model); //ISaleOrder
            let addItemToCartRequests = this.$store.state.localCartItems.map(
              (lsoi: ILocalSaleOrderItem) => {
                return StoreService.addItemToCart({
                  order_id: this.$store.state.cart.id,
                  name: lsoi.id,
                  product_id: lsoi.product.id,
                  variant_id: lsoi.variant.id,
                  qty: lsoi.qty,
                  price: lsoi.product.price,
                });
              }
            );

            Promise.all(addItemToCartRequests).then((responses) => {
              this.$store.commit("loadShoppingCart");
              this.$store.commit("clearLocalCartItems");
              this.$router.replace("/");
            });
          });
        },
        (error) => {
          this.$data.loginError = true;
          eventBus.$emit("showErrorModal", "Usuario o contraseña incorrectos.");
        }
      );
    } else {
      console.log("error");
    }
  }

  toggleLogin() {
    eventBus.$emit("toggle-login", false);
  }
}
