
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { IReturn, ISaleOrderItem } from '@/services/store.service';

@Component({
    components: { 

    }
})
export default class ReturnDetailSummary extends Vue {
    @Prop() private model!:IReturn;

    data() {
        return {
        };
    }

    getStatus() {
      let state = this.model.x_studio_request;
      if(this.model.x_studio_state == 'status2') {
        state += ' / Aceptada';
      } else if(this.model.x_studio_state == 'status3') {
        state += ' / Rechazada';
      }

      return state;
    }

    isValid() {
        return this.model.x_studio_request == 'Aplica';
    }

    isInvalid() {
        return this.model.x_studio_request == 'No aplica';
    }

    isReceibed() {
        return this.model.x_studio_state == 'status1';
    }

    isAccepted() {
        return this.model.x_studio_state == 'status2';
    }

    isRejected() {
        return this.model.x_studio_state == 'status3';
    }

}
