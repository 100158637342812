
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import QtyInput from "@/components/shopping-cart/QtyInput.vue";
import {
  IPresentation,
  IPresentationVariant,
  IProductDetail,
  IVariant,
} from "@/services/catalog.service";
import StoreService from "@/services/store.service";
import { IGenericResponse } from "@/services/interceptor";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { ILocalSaleOrderItem } from "@/services/local.store.service";
import { IPresentationItem } from "@/store";
import eventBus from "../../services/eventBus";

@Component({
  components: {
    "qty-input": QtyInput,
  },
})
export default class PresentationAddToCartForm extends Vue {
  @Prop() private product!: IPresentation;
  @Prop() private variant!: IPresentationVariant;

  data() {
    return {
      form: {
        qty: 0,
      },
      model: null,
      selected: null,
    };
  }

  created() {
    this.$data.model = this.product;
    this.$data.selected = this.variant;
    eventBus.$on("clear-selected-box-items", () => {
      this.$data.form.qty = 0;
    });
    // this.$data.maximum = this.$data.selected.maximum_pieces != 0 ? this.$data.selected.maximum_pieces : this.$data.selected.stock;
  }

  @Watch("product")
  onProductChange(newProduct: IPresentation): void {
    this.$data.model = newProduct;
  }

  @Watch("variant")
  onVariantChange(newVariant: IPresentationVariant): void {
    this.$data.selected = newVariant;
  }

  @Watch("form.qty")
  onFormQtyChange(newQty: number): void {
    this.$store.dispatch("setBoxQty", {
      presentation: this.$data.model,
      qty: this.$data.form.qty,
    } as IPresentationItem);
  }

  onSubmit(event) {
    event.preventDefault();
    this!["$v"].$touch();
    // if (!this!['$v'].$invalid) {

    //         const qty = parseInt(this.$data.form.qty);
    //         StoreService.addItemToCart({
    //             order_id: this.$store.state.cart.id,
    //             name: this.product.name,
    //             product_id: this.$data.model.id,
    //             variant_id: this.$data.selected.id,
    //             qty: qty,
    //             price: this.$data.model.price
    //         }).then((response:IGenericResponse) => {
    //             if(response.success == true) {
    //                 this.$data.selected = response.data.variant;
    //                 this.$data.model.price = this.$data.selected.price;
    //                 // this.$data.maximum = this.$data.selected.maximum_pieces != 0 ? this.$data.selected.maximum_pieces : this.$data.selected.stock;

    //                 this.$data.form.qty = 0;

    //                 this.$store.commit('setShoppingCart', response.data.shoppingCart);
    //                 this.$store.commit('setShippingMethods', response.data.quoteShipping);

    //                 // this.$router.push({ name: 'ShoppingCart' });
    //             }
    //         }).catch(error => {
    //             console.log(error);
    //         });

    // }
  }

  updateSelectedQty() {
    this.$store.dispatch("setPackageQty", {
      presentation: this.$data.model,
      qty: this.$data.form.qty,
    } as IPresentationItem);
  }

  dec() {
    if (this.$data.form.qty > 0) {
      this.$data.form.qty = parseInt(this.$data.form.qty) - 1;
      this.updateSelectedQty();
    }
  }

  inc() {
    const qty = parseInt(this.$data.form.qty);
    if (qty < this.$data.selected.stock) {
      this.$data.form.qty = qty + 1;
      this.updateSelectedQty();
    }
  }

  @Validations()
  validations() {
    return {
      form: {
        qty: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.$data.selected.stock),
        },
      },
    };
  }
}
