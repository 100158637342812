
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

@Component({
  components: {
    "vueper-slides": VueperSlides,
    "vueper-slide": VueperSlide,
  },
})
export default class FeaturedProducts extends Vue {
  @Prop() private title!: string;
  @Prop() private model!: string;
}
