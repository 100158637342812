
import { Component, Vue } from "vue-property-decorator";
import CouponInput from "@/components/shopping-cart/CouponInput.vue";
import StoreService from "@/services/store.service";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {
    "coupon-input": CouponInput,
  },
})
export default class CartSummary extends Vue {
  data() {
    return {
      tracing_whatsapp: true,
      tracing_whatsapp_yes: true,
      tracing_whatsapp_not: false,
    };
  }

  confirm() {
    if (
      this.$refs.confirmModal &&
      this.$store.state.cart.carrier_id != false &&
      this.$store.getters.itemCount != 0
    ) {
      this.$refs.confirmModal["open"]();
    }
  }

  getTotalItems() {
    return this.$store.state.cart.items
      .filter((item) => item.type == "product")
      .reduce((previous, current) => {
        return current.price_total + previous;
      }, 0);
  }
  getPriceMetEnvio() {
    const resp = this.$store.state.cart.items.find(
      (item) => item.type == "service"
    );
    // console.log(price_total);
    return resp ? resp.price_total : 0;
  }
  hasTracking(resp) {
    // console.log(resp);
    this.$data.tracing_whatsapp = resp;
    if (resp) {
      this.$data.tracing_whatsapp_yes = true;
      this.$data.tracing_whatsapp_not = false;
    } else {
      this.$data.tracing_whatsapp_not = true;
      this.$data.tracing_whatsapp_yes = false;
    }
  }
  validatePresentation(): boolean {
    const items = this.$store.getters.productItems.filter((item) => {
      // console.log(item);
      return (
        item.name.toLocaleLowerCase().includes("caja") ||
        item.name.toLocaleLowerCase().includes("paquete")
      );
    });
    // console.log(items.length > 0);
    return items.length > 0;
  }

  confirmOrder() {
    if (
      !(
        this.$store.getters.isRestrictedWholesellerCustomer &&
        this.$store.getters.itemCount < 10
      ) ||
      (this.$store.getters.isRestrictedWholesellerCustomer &&
        this.validatePresentation())
    ) {
      if (
        this.$refs.confirmModal &&
        this.$store.state.cart.carrier_id != false &&
        this.$store.getters.itemCount != 0
      ) {
        // console.log("pasooo");
        this.$refs.confirmModal["close"]();
        const order_id = this.$store.state.cart.id;
        const tracing_whatsapp = this.$data.tracing_whatsapp;
        StoreService.confirm(order_id, tracing_whatsapp)
          .then((response: IGenericResponse) => {
            this.$store.commit("loadShoppingCart");
            this.$store.commit("loadShippingMethods");
            this.$router.replace(`/pedidos/${order_id}/pagar`);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
}
