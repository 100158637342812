
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import VerticalAligned from "./VerticalAligned.vue";

@Component({
  components: {
    "vertical-aligned": VerticalAligned,
  },
})
export default class PageFooter extends Vue {
  data() {
    return {};
  }

  get categories() {
    const slugs = [
      "variedad-de-ropa-para-caballero",
      "variedad-de-ropa-para-dama-curvy",
      "variedad-de-ropa-para-dama",
    ];

    let filtro = [
      ...this.$store.getters.rootCategories.filter((item) =>
        slugs.includes(item.slug)
      ),
    ];

    return filtro;
  }
}
