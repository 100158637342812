
import { Component, Prop, Vue } from "vue-property-decorator";
import AddressForm from "@/components/my-account/AddressForm.vue";
import eventBus from "@/services/eventBus";

@Component({
  components: {
    "address-form": AddressForm,
  },
})
export default class AddAddressCardButton extends Vue {
  data() {
    return {};
  }

  created() {
    eventBus.$on("load-addresses", () => {
      this.$bvModal.hide("address-form-modal");
    });
  }
}
