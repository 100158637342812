
import { Component, Prop, Vue } from 'vue-property-decorator';
import StoreService, { IShippingMethod } from '@/services/store.service';
import { IGenericResponse } from '@/services/interceptor';

@Component({
    components: { 

    }
})
export default class ShippingMethod extends Vue {
    @Prop() private model!: IShippingMethod;

    data() {
        return {
        };
    }

    isActive() {
        if(this.model != undefined) {
            return this.model.carrier.id == this.$store.getters.shippingMethodId;
        }
        
        return false;
    }

    choose() {
        StoreService.setShippingMethod(this.$store.state.cart.id, this.model.carrier.id).then((response:IGenericResponse) => {
          if(response.success == true) {
              this.$store.commit('setShoppingCart', response.data.shoppingCart);
              this.$store.commit('setShippingMethods', response.data.quoteShipping);
            
              // this.$store.commit('loadShoppingCart');
              // this.$store.commit('loadShippingMethods');
          }
        }).catch(error => {
            console.log(error);
        });
    }
}
