
import { Component, Watch, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import CartItem from "@/components/public-shopping-cart/CartItem.vue";
import SignIn from "@/components/SignIn.vue";

@Component({
  metaInfo(this: PublicShoppingCart): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "cart-item": CartItem,
    "sign-in": SignIn,
  },
})
export default class PublicShoppingCart extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: "Mi carrito",
      description: "Luna Trend",
      cart: null,
    };
  }

  // created() {}

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.jpg$/);
    return images(image);
  }
}
