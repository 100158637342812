
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class TopBanner extends Vue {
    data() {
        return {
        };
    }

    hasNextLevel() {
        return this.$store.getters.nextDiscountLevel != null;
    }

    remainQtyToNextLevel() {
        if(this.hasNextLevel()) {
            return this.$store.getters.nextDiscountLevel.x_studio_amount_of_pieces - this.$store.getters.itemCount;
        }
    
        return 0;
    }

    getNextLevelDiscount() {
        if(this.hasNextLevel()) {
            return this.$store.getters.nextDiscountLevel.x_studio_discount * 100;
        }
    
        return 0;
    }
}
