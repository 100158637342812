import { render, staticRenderFns } from "./MyReturns.vue?vue&type=template&id=f672e124&scoped=true&"
import script from "./MyReturns.vue?vue&type=script&lang=ts&"
export * from "./MyReturns.vue?vue&type=script&lang=ts&"
import style0 from "./MyReturns.vue?vue&type=style&index=0&id=f672e124&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f672e124",
  null
  
)

export default component.exports