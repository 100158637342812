
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOrder } from "@/services/store.service";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";
import StripeService from "@/services/stripe.service";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {
    SweetModal,
    SweetModalTab,
  },
})
export default class StripeCardPayment extends Vue {
  @Prop() private model!: IOrder;

  data() {
    return {
      form: {
        card: null,
        holder: null,
        exp: null,
        cvc: null,
      },
      errorMsg: "No se pudo completar el pago.",
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        card: { required },
        holder: { required },
        exp: { required },
        cvc: { required },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      StripeService.payWithCard(this.model.id, this.$data.form).then(
        (response: IGenericResponse) => {
          if (this.$refs.successModal && this.$refs.errorModal) {
            if (response.success) {
              if (response.data.status == "succeeded") {
                if (this.$refs.successModal) {
                  this.$refs.successModal["open"]();
                  setTimeout(() => {
                    this.$router.replace(`/pedidos/${this.model.id}`);
                  }, 2000);
                }
              } else {
                this.$refs.errorModal["open"]();
              }
            } else {
              // console.log(response.data);
              if (response.data["message"] != undefined) {
                this.$data.errorMsg = response.data.message;
              }
              this.$refs.errorModal["open"]();
            }
          }
        },
        (error) => {
          this.$data.loginError = true;
        }
      );
    } else {
      console.log("error");
    }
  }
}
