
import { ILocalSaleOrderItem } from "@/services/local.store.service";
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import DeleteItemButton from "@/components/public-shopping-cart/DeleteItemButton.vue";

@Component({
  components: {
    "delete-item-button": DeleteItemButton,
  },
})
export default class CartItem extends Vue {
  @Prop() private item!: ILocalSaleOrderItem;

  data() {
    return {
      model: null,
    };
  }

  created() {
    this.$data.model = this.item;
  }

  @Watch("item")
  onItemChange(newItem: ILocalSaleOrderItem) {
    this.$data.model = newItem;
  }
}
