
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { IOrder, ISaleOrderItem } from "@/services/store.service";

@Component({
  components: {},
})
export default class OrderSummary extends Vue {
  @Prop() private model!: IOrder;

  data() {
    return {};
  }

  orderItemCount() {
    let count = 0;
    if (this.model != null) {
      let items = this.model.items.filter(
        (item: ISaleOrderItem) => item.type == "product"
      );
      for (let item of items) {
        count += item.product_uom_qty;
      }
    }

    return count;
  }

  getTotalItems() {
    return this.model.items
      .filter((item) => item.type == "product")
      .reduce((previous, current) => {
        return current.price_total + previous;
      }, 0);
  }

  orderHasItems() {
    let count = 0;
    if (this.model != null) {
      let items = this.model.items.filter(
        (item: ISaleOrderItem) => item.type == "product"
      );
      for (let item of items) {
        count += item.product_uom_qty;
      }
    }

    return count > 0;
  }

  orderProductItems() {
    if (this.model != null)
      return this.model.items.filter(
        (item: ISaleOrderItem) => item.type == "product"
      );
    return [];
  }

  orderServiceItems() {
    if (this.model != null)
      return this.model.items.filter(
        (item: ISaleOrderItem) => item.type == "service"
      );

    return [];
  }
}
