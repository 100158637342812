
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AuthService, { IAddress } from "@/services/auth.service";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";

@Component({
  metaInfo(this: MyAddresses): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
  },
})
export default class MyAddresses extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Mis direcciones`,
      description: "Administra tus direcciones.",
      models: [],
      fields: [
        // { key: "index", label: "#" },
        { key: "name", label: "Nombre" },
        { key: "contact_address_complete", label: "Dirección" },
        { key: "id", label: "" },
        // { key: 'update', label: '' },
      ],
    };
  }

  created() {
    this.loadModels();
  }

  loadModels() {
    AuthService.getAddresses()
      .then((models: IAddress[]) => {
        this.$data.models = models;
      })
      .catch((error) => {
        this.$data.model = null;
      });
  }
  deleteAddress(id) {
    // console.log("eliminar: ", id);
    AuthService.deleteAddress(parseInt(id), null)
      .then(() => {
        this.$store.commit("loadAddresses");
        window.location.href = "/mis-direcciones";
        // this.$router.go(-1);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
