
import {
  ISearchResultAttribute,
  ISearchResultAttributeValue,
} from "@/services/catalog.service";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AttributeFilter extends Vue {
  @Prop() attribute!: ISearchResultAttribute;

  data() {
    return {
      model: [],
    };
  }

  get attributes() {
    return this.$store.getters.getAttributes;
  }

  get selectedTags() {
    return this.$store.getters.getSelectedTags;
  }

  get selectedGenders() {
    return this.$store.getters.getSelectedGenders;
  }

  get selectedAttributes() {
    return this.$store.getters.getSelectedAttributes;
  }

  // toggleAttributeFilter(value_id) {
  //   this.$store.dispatch("toggleAttributeFilter", value_id);
  //   this.redirect();
  // }

  toggleAttributeFilter() {
    for (let i = 0; i < this.$data.model.length; i++) {
      this.$store.dispatch("toggleAttributeFilter", this.$data.model[i]);
    }

    // this.redirect();
  }

  created() {
    if (this.$route.query?.attributes) {
      let values: any[] = [];
      if (Array.isArray(this.$route.query?.attributes)) {
        values = this.$route.query?.attributes.map((av: any) => parseInt(av));
      }

      // console.log(values[0]);
      for (let i = 0; i < values.length; i++) {
        const fi = this.attribute.values.findIndex(
          (v: ISearchResultAttributeValue) => {
            if (values[i] != null) {
              return v.id == values[i];
            }

            return false;
          }
        );

        if (fi != -1) {
          this.$data.model.push(values[i]);
        }
      }
    }

    // comprar los filtros nuevos con los viejos
    // this.clear();
  }

  clear() {
    // console.log("limpiar");
    // console.log(this.selectedAttributes);
    // console.log(this.$data.model);
    for (let i = 0; i < this.$data.model.length; i++) {
      this.$store.dispatch("toggleAttributeFilter", this.$data.model[i]);
    }

    this.$data.model = [];

    // this.redirect();
  }

  redirect() {
    const name = this.$route.name ?? "Products";
    const query = {
      tags: this.selectedTags.length != 0 ? this.selectedTags : null,
      attributes:
        this.selectedAttributes.length != 0 ? this.selectedAttributes : null,
      gender: this.selectedGenders.length != 0 ? this.selectedGenders : null,
      search: this.$route.query.search ?? null,
    };

    let params = {};
    if (this.$route.params?.brand_id != undefined) {
      params = {
        ...params,
        brand_id: this.$route.params?.brand_id,
      };
    }

    if (this.$route.params?.category_id != undefined) {
      params = {
        ...params,
        category_id: this.$route.params?.category_id,
      };
    }

    let to = {
      name,
      params,
      query,
    };

    this.$router.push(to);
  }
}
