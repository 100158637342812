
import { Component, Prop, Vue } from "vue-property-decorator";
import { IAddress } from "@/services/auth.service";
import StoreService from "@/services/store.service";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {},
})
export default class CustomerAddress extends Vue {
  @Prop() private model!: IAddress;
  created() {
    this.choose();
  }

  data() {
    return {};
  }

  isActive() {
    if (this.model != undefined) {
      return this.model.id == this.$store.getters.shippingAddressId;
    }

    return false;
  }

  choose() {
    StoreService.setShippingAddress(this.$store.state.cart.id, this.model.id)
      .then((response: IGenericResponse) => {
        if (response.success == true) {
          this.$store.commit("setShoppingCart", response.data.shoppingCart);
          this.$store.commit("setShippingMethods", response.data.quoteShipping);

          //   document.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
