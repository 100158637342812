
import { ILocalSaleOrderItem } from '@/services/local.store.service';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class DeleteItemButton extends Vue {
    @Prop() private item!: ILocalSaleOrderItem;
    
    data() {
        return {
            model: null
        };
    }

    created() {
        this.$data.model = this.item;
    }

    @Watch('item')
    onItemChange(newItem:ILocalSaleOrderItem) {
        this.$data.model = newItem;
    }

    doIt() {
        this.$store.commit('removeLocalCartItem', this.$data.model.id);
    }
}
