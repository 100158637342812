
import { Component, Prop, Vue } from "vue-property-decorator";
import { IProduct } from "@/services/catalog.service";

@Component
export default class ProductItem extends Vue {
  @Prop() private model;
  // @Prop() private model!: IProduct;

  data() {
    return {};
  }

  get hasMoreColor() {
    return this.model.variants_attributes?.find((item) => {
      return item.name.toLowerCase() == "color" && item.values.length > 2;
    });
  }
  getAssetPath(image: string): string {
    let images = require.context("../../assets/", true, /\.(gif|jpg|png|svg)$/);
    return images(image);
  }
}
