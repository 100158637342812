
import { Component, Prop, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, integer, minLength, maxLength } from "vuelidate/lib/validators";
import AuthService, { IAddress, Identity } from "@/services/auth.service";
import CatalogService, { ICountryState } from "@/services/catalog.service";
import { SelectOption } from "@/store";
import eventBus from "@/services/eventBus";
import StoreService from "@/services/store.service";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  metaInfo(this: AddressForm): MetaInfo {
    return {};
  },
  components: {},
})
export default class AddressForm extends Vue {
  @Prop() address!: IAddress | null;

  data() {
    return {
      form: {
        name: null,
        street: null,
        street2: "",
        zip: null,
        city: null,
        state_id: null,
        country_id: null,
        l10n_mx_edi_colony: null,
      },
      error: false,
      submitComplete: false,
      errorMessage: "",
      states: [],
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        name: { required },
        street: { required },
        street2: {},
        zip: {
          required,
          integer,
          minLength: minLength(5),
          maxLength: maxLength(5),
        },
        city: { required },
        state_id: { required },
        country_id: { required },
        l10n_mx_edi_colony: { required },
      },
    };
  }

  created() {
    if (this.$store.getters.countryOptions.length) {
      this.$data.form.country_id = this.$store.getters.countryOptions[0].value;
      this.getCountryStates();
    }
  }

  getCountryStates() {
    CatalogService.getCountryStates(parseInt(this.$data.form.country_id))
      .then((models: ICountryState[]) => {
        this.$data.states = models.map((item: ICountryState) => {
          return {
            value: item.id,
            text: item.name,
          } as SelectOption;
        });
      })
      .catch((error) => {
        this.$data.states = [];
      });
  }

  onSubmit(event) {
    event.preventDefault();

    this.$data.submitComplete = false;
    this.$data.error = false;
    this.$data.errorMessage = "";

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      let data = { ...this.$data.form };

      if (this.address != null) {
        AuthService.updateAddress(this.address.id, data)
          .then((model: IAddress) => {
            AuthService.getAddresses()
              .then((models: IAddress[]) => {
                this.$store.commit("setAddresses", models);
                this.$store.commit("setShippingMethods", []);
                eventBus.$emit("load-addresses");
              })
              .catch((error) => {
                this.$store.commit("setAddresses", []);
                this.$store.commit("setShippingMethods", []);
                // eventBus.$emit('load-addresses');
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        AuthService.createAddress(data)
          .then((model: IAddress) => {
            StoreService.setShippingAddress(this.$store.state.cart.id, model.id)
              .then((response: IGenericResponse) => {
                if (response.success == true) {
                  this.$store.commit("setShoppingCart", response.data.shoppingCart);
                  this.$store.commit("setShippingMethods", response.data.quoteShipping);

                  AuthService.getAddresses()
                    .then((models: IAddress[]) => {
                      this.$store.commit("setAddresses", models);
                      eventBus.$emit("load-addresses");
                    })
                    .catch((error) => {
                      this.$store.commit("setAddresses", []);
                      eventBus.$emit("load-addresses");
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });

            // this.$store.commit('loadAddresses');
            // eventBus.$emit('load-addresses');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      console.log("error");
    }
  }
}
