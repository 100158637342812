
import { Component, Watch, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import CatalogService, {
  IConfig,
  IConfigValue,
  IProduct,
  IProductDetail,
  IProductQuery,
  IVariant,
  IVariantConfig,
  IVariantConfigValue,
  IVariantDetail,
} from "@/services/catalog.service";
import FeaturedProducts from "@/components/products/FeaturedProducts.vue";
import ProductGallery from "@/components/products/ProductGallery.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import StoreService from "@/services/store.service";
import Video from "@/components/Video.vue";
import Login from "@/components/auth/Login.vue";
import { IGenericResponse } from "@/services/interceptor";
import AddToCartForm from "@/components/shopping-cart/AddToCartForm.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import RelatedProducts from "@/components/RelatedProducts.vue";
import VideoProduct from "@/components/VideoProduct.vue";
import eventBus from "@/services/eventBus";
interface IConfigAttribute {
  index: number;
  value: number;
}

@Component({
  metaInfo(this: ProductDetail): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    breadcrumb: Breadcrumb,
    "featured-products": FeaturedProducts,
    "product-gallery": ProductGallery,
    "vimeo-video": Video,
    login: Login,
    "add-to-cart-form": AddToCartForm,
    ProductInfo,
    RelatedProducts,
    VideoProduct,
  },
})
export default class ProductDetail extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  // dec() {
  //   if (this.$data.form.qty > 0) {
  //     this.$data.form.qty = parseInt(this.$data.form.qty) - 1;
  //   }
  // }

  // inc() {
  //   this.$data.form.qty = parseInt(this.$data.form.qty) + 1;
  // }

  data() {
    return {
      title: "Nuestros productos",
      description: "Luna Trend.",
      model: null,
      breadcrumbPath: [],
      maximum: 0,
      variant_id: null,
      selected: null,
      config: [] as IConfigAttribute[],
      options: [],
      enablePurchase: true,
      initialPicture: null,
      form: {
        qty: 0,
      },
      loadingRelated: false,
      loading: false,
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        qty: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.$data.maximum),
        },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.$store.getters.isLoggedIn) {
      if (this.$refs.confirmModal) {
        this.$refs.confirmModal["open"]();
      }
    } else {
      this!["$v"].$touch();
      if (!this!["$v"].$invalid) {
        const qty = parseInt(this.$data.form.qty);
        StoreService.addItemToCart({
          order_id: this.$store.state.cart.id,
          name: this.$data.selected.name,
          product_id: this.$data.model.id,
          variant_id: this.$data.selected.id,
          qty: qty,
          price: this.$data.model.price,
        })
          .then((response: IGenericResponse) => {
            if (response.success == true) {
              this.$data.selected = response.data.variant;
              // this.$data.model.price = this.$data.selected.price;
              // this.$data.maximum = this.$data.selected.maximum_pieces != 0 ? this.$data.selected.maximum_pieces : this.$data.selected.stock;

              this.$data.form.qty = 0;

              this.$store.commit("setShoppingCart", response.data.shoppingCart);
              this.$store.commit(
                "setShippingMethods",
                response.data.quoteShipping
              );

              // this.$store.commit('loadShoppingCart');
              // this.$store.commit('loadShippingMethods');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log("error");
      }
    }
  }

  changeImgGallery(namePicture) {
    // console.log(namePicture);
    this.$data.initialPicture = namePicture;
  }
  selectVariant() {
    this.$data.selected = this.$data.model.variants.find(
      (variant: IVariant) => variant.id == this.$data.variant_id
    );
  }
  updateVariant(value) {
    // console.log("detail");
    // console.log(value); // catidad y seleccionado
    this.$data.model.variants.forEach((element) => {
      if (element.id == value.selected.id) {
        element.stock = value.selected.stock;
      }
    });
  }

  selectAttribute(i, $event) {
    if (this.$data.config[i] == undefined) {
      this.$data.config.push($event);
    } else {
      this.$data.config[i] = $event;
    }

    this.$data.selected = null;
    const sv = this.$data.config;

    // console.log("sv", sv);
    // console.log("variants", this.$data.model.variants);

    for (let i = 0; i < this.$data.model.variants.length; i++) {
      // console.log("a1");
      let vv: number[] = [];
      for (let j = 0; j < this.$data.model.variants[i].config.length; j++) {
        // console.log("b1");
        for (
          let k = 0;
          k < this.$data.model.variants[i].config[j].values.length;
          k++
        ) {
          // console.log("c1");
          vv.push(this.$data.model.variants[i].config[j].values[k].id);
        }
      }

      const diff = vv.filter((x) => !sv.includes(x));
      // console.log("vv", vv);
      // console.log("diff", diff);

      if (diff.length == 0) {
        this.$data.selected = this.$data.model.variants[i];
        break;
      }
    }

    // console.log("=========");
  }

  // getVariant(variant:IVariant) {
  //     // CatalogService.getVariant(variant.id).then((model:IVariantDetail) => {
  //     //   if(model.price != null) {
  //     //     this.$data.selected = model;
  //     //     this.$data.model.price = model.price;
  //     //     this.$data.maximum = variant.maximum_pieces != 0 ? variant.maximum_pieces : model.stock;
  //     //     this.$data.enablePurchase = true;
  //     //   } else {
  //     //       this.$data.enablePurchase = false;
  //     //   }
  //     // }, err => {
  //     //   console.log(err);
  //     // });
  // }
  hasDescription() {
    if (this.$data.model != null && this.$data.model.description != null) {
      return (
        this.$data.model.description.replace(/(<([^>]+)>)/gi, "").trim()
          .length != 0
      );
    }

    return false;
  }

  hasVideo() {
    if (this.$data.model != null && this.$data.model.vimeo_id != null) {
      return this.$data.model.vimeo_id.trim().length != 0;
    }

    return false;
  }

  async created() {
    await this.fetchData();
  }

  @Watch("$route")
  async onModelChange() {
    await this.fetchData();
  }

  async fetchData() {
    let id = parseInt(this.$route.params?.id);

    try {
      this.$data.model = null;
      eventBus.$emit("loading", false);
      this.$data.loading = true;

      const model: any = await CatalogService.getProduct(id);
      this.$data.loading = false;
      await this.getProductsRelated(model);
      this.$data.model = model;
      this.$data.initialPicture = null;
      this.$data.title = model.name;
      this.$data.description = `${model.sku} ${model.name}`;
      this.$data.keywords = model.meta_keywords;
      this.$data.keywords = `${this.$data.keywords}, ${model.sku}, ${model.name}`;
      this.$data.options = model.variants_attributes;
      this.$data.selected = null;
      if (model?.category != null) {
        this.$data.breadcrumbPath = [
          {
            text: model?.category.name,
            href: `/productos/categoria/${model?.category.id}/${model?.category.slug}`,
          },
          {
            text: model.name,
            active: true,
          },
        ];
      } else {
        this.$data.breadcrumbPath = [
          {
            text: model.name,
            active: true,
          },
        ];
      }
    } catch (error) {
      console.log(error);
    }
    // CatalogService.getProduct(id).then((model: IProductDetail) => {

    //   // if(this.$data.model.variants.length != 0) {
    //   //   this.$data.varant_id = this.$data.model.variants[0].id;
    //   //   this.selectVariant();
    //   // }
    // });
  }

  getProductsRelated(model) {
    let config = {
      brand_id: null,
      category_id: model.category?.id,
      search: null,
      page: 1,
      featured: false,
      top: null,
      filters: null,
      related: true,
    } as IProductQuery;
    this.$data.loadingRelated = true;

    CatalogService.getProducts(config)
      .then((response: IProduct[]) => {
        // console.log(this.$data.model.id);
        // console.log(response);
        const filtersProducts = response.filter((item) => {
          return item.id != this.$data.model.id;
        });

        this.$data.model = { related: filtersProducts, ...model };
        this.$data.loadingRelated = false;
      })
      .catch((error) => {
        this.$data.models = [];
      });
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.(gif|jpg|png|svg)$/);
    return images(image);
  }
}
