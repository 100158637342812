
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import StoreService, { ISaleOrder } from "@/services/store.service";
import eventBus from "@/services/eventBus";
import CartItem from "@/components/shopping-cart/widget/CartItem.vue";
import EmptyCart from "@/components/shopping-cart/EmptyCart.vue";

@Component({
  components: {
    "cart-item": CartItem,
    "empty-cart": EmptyCart,
  },
})
export default class Cart extends Vue {
  data() {
    return {
      show: false,
    };
  }

  created() {
    // this.$data.model = this.cart;
    // eventBus.$on('cart-updated', (cart:ISaleOrder) => {
    //     // this.$data.model = cart;
    //     this.$data.show = true;
    // });

    eventBus.$on("toggle-cart-sidebar", () => {
      if (this.$route.name != "ShoppingCart") {
        this.$data.show = !this.$data.show;
      }
    });
  }

  // @Watch('cart')
  // onCartChange(cart:ISaleOrder) {
  //     this.$data.model = cart;
  // }

  deleteItem(i: number) {
    // StoreService.removeItem(i);
  }

  // Clear user cart
  clear() {
    // StoreService.clear();
    this.$data.showBasketSidebar = false;
  }
  quantityItems() {
    // return this.$store.state.cart.items.reduce((previous, current) => {
    //   return previous + current.product_uom_qty;
    // }, 0);
    return this.$store.state.cart.items
      .filter((product) => product.type == "product")
      .reduce((previous, current) => {
        return previous + current.product_uom_qty;
      }, 0);

    // }
  }

  // toggleBasketSidebar() {
  //     eventBus.$emit('toggle-cart-sidebar');
  // }

  // Confirm user cart
  confirm() {
    // const data = { ...this.$data.model };
    // BasketService.confirm(data).then((response:IGenericResponse) => {
    //   this.clear();
    //   eventBus.$emit('user-carts-updated');
    //   this.$router.push('/mis-cotizaciones');
    // });
    this.$data.showBasketSidebar = false;
  }
}
