
import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

@Component({
  metaInfo(this: Privacy): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
  },
})
export default class Privacy extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {};
  }
}
