
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import StoreService, { ISaleOrderItem } from "@/services/store.service";
import CatalogService, { IVariantDetail } from "@/services/catalog.service";
import { Validations } from "vuelidate-property-decorators";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {},
})
export default class QtyInput extends Vue {
  @Prop() private model!: ISaleOrderItem;

  data() {
    return {
      variant: null,
      maximum: 0,
      disabled: false,
      form: {
        qty: null,
      },
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        qty: {
          required,
          // minValue: minValue(1), maxValue: maxValue(this.$data.maximum)
        },
      },
    };
  }

  dec() {
    if (this.$data.form.qty > 0) {
      this.$data.form.qty = parseInt(this.$data.form.qty) - 1;
      this.onSubmit();
    }
  }

  inc() {
    const qty = parseInt(this.$data.form.qty);
    if (qty < this.model.stock) {
      this.$data.form.qty = parseInt(this.$data.form.qty) + 1;
      this.onSubmit();
    }
  }

  updateQty() {
    if (this.$data.form.qty != this.model.product_uom_qty) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      const qty = parseInt(this.$data.form.qty);
      StoreService.updateItemCart({
        order_id: this.$store.state.cart.id,
        product_id: this.model.product_tmpl_id,
        name: this.model.name,
        variant_id: this.model.product_product_id,
        qty: qty,
        price: this.model.price_total,
      })
        .then((response: IGenericResponse) => {
          if (response.success == true) {
            this.$data.variant = response.data.variant;
            this.$data.maximum =
              response.data.variant.maximum_pieces != 0
                ? response.data.variant.maximum_pieces
                : response.data.variant.stock;

            this.$store.commit("setShoppingCart", response.data.shoppingCart);
            this.$store.commit(
              "setShippingMethods",
              response.data.quoteShipping
            );

            // this.$store.commit('loadShoppingCart');
            // this.$store.commit('loadShippingMethods');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("a4");
      console.log("error");
    }
  }

  @Watch("model", { deep: true })
  onModelChange(model: ISaleOrderItem): void {
    if (model != null && model != undefined) {
      this.$data.form.qty = model.product_uom_qty;
    }
  }

  created() {
    if (this.model != null) {
      this.$data.form.qty = this.model.product_uom_qty;
      // CatalogService.getVariant(this.model.product_product_id).then((model:IVariantDetail) => {
      //     this.$data.variant = model;
      //     this.$data.maximum = model.maximum_pieces != 0 ? model.maximum_pieces : model.stock;
      //     this.$data.disabled = false;
      // }, err => {
      //     this.$data.disabled = true;

      // StoreService.deleteCartItem(this.$store.state.cart.id, this.model.id).then((response:IGenericResponse) => {
      //     if(response.success == true) {
      //         this.$store.commit('setShoppingCart', response.data.shoppingCart);
      //         this.$store.commit('setShippingMethods', response.data.quoteShipping);

      //         // this.$store.commit('loadShoppingCart');
      //         // this.$store.commit('loadShippingMethods');
      //     }
      // }).catch(error => {
      //     console.log(error);
      // });
      // });
    }
  }
}
