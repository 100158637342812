
import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/auth.service";
import Loader from "@/components/Loader.vue";
import eventBus from "./services/eventBus";
import CatalogService, { ICategory } from "@/services/catalog.service";
import StoreService from "@/services/store.service";
import { IGenericResponse } from "./services/interceptor";
import Search from "@/components/search/Search.vue";
import WhatsAppBusiness from "./components/WhatsAppBusiness.vue";

@Component({
  components: {
    loader: Loader,
    search: Search,
    WhatsAppBusiness,
  },
})
export default class App extends Vue {
  data(): any {
    return {
      loading: false,
      errorModalMsg: null,
      successModalMsg: null,
    };
  }

  created() {
    this.$store.commit("loadIdentity");
    this.$store.dispatch("loadInitialData");
    // console.log(this.$store.state.website);

    eventBus.$on("shopping-cart-updated", (scp: boolean) => {
      const raw_items = this.$store.state.localCartItems;
      for (let i = 0; i < raw_items.legth; i++) {
        const item = {
          order_id: this.$store.state.cart.id,
          name: raw_items[i].id,
          product_id: raw_items[i].product.id,
          variant_id: raw_items[i].variant.id,
          qty: raw_items[i].qty,
          price: raw_items[i].product.price,
        };

        StoreService.addItemToCart(item)
          .then((response: IGenericResponse) => {
            // console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });

    eventBus.$on("loading", (loading: boolean) => {
      this.$data.loading = loading;
    });

    eventBus.$on("showErrorModal", (msg: string) => {
      if (this.$refs.errorModal) {
        this.$data.errorModalMsg = msg;
        this.$refs.errorModal["open"]();
      }
    });

    eventBus.$on("showSuccessModal", (msg: string) => {
      if (this.$refs.successModal) {
        this.$data.successModalMsg = msg;
        this.$refs.successModal["open"]();
      }
    });
  }
}
