import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
// import VueGtag from "vue-gtag"
import moment from 'moment-timezone'

import store from './store'

import './scss/bootstrap.scss'
import './services/interceptor.ts'
moment.tz.setDefault("America/Mexico_City")
moment.locale('es')

library.add(faFacebook)
library.add(faInstagramSquare)
library.add(faWhatsapp)
library.add(faMapMarkerAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Vue.use(VueGtag, {
//   config: { id: "UA-194232824-1" }
// }, router)

import ClickOutside from "vue-click-outside";

Vue.directive("click-outside", ClickOutside)

import VueCardFormat from 'vue-credit-card-validation';
import VimeoPlayer from 'vue-vimeo-player'

Vue.use(VimeoPlayer)
Vue.use(VueCardFormat);

// const GOOGLE_ANALITY='G-TD9RXZ5Y60'
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: GOOGLE_ANALITY
// })

import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: {id: "G-TD9RXZ5Y60"}
  },
  router
);




import SweetModal from 'sweet-modal-vue/src/plugin.js'
Vue.use(SweetModal)

import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

Vue.use(VueMeta)

Vue.use(Vuelidate)

Intl.NumberFormat

import Skeleton from 'vue-loading-skeleton';
Vue.use(Skeleton)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

Vue.filter('shortdate', function (value:number) {
  if (!value) return ''
  return moment.unix(value).calendar()
})
Vue.filter('getFormattedDate', function (dateString:string, type = 1) {
    // let date = new Date(dateString)
    const date = new Date(dateString+ ' GMT+06:00');
    let formatted = ''
      
    
    switch (type) {
      case 1:
        formatted = date.toLocaleString('es-MX', { day: 'numeric', year: 'numeric', month: '2-digit'})
        //     formatted = date.toLocaleDateString('es-MX', {day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC'})
        break;
    }

    return formatted

})

Vue.filter('number', function (value:number) {
  if (!value) return '0.00'
  const formatted = new Intl.NumberFormat("es-MX").format(value)
  return `${formatted}`
})

Vue.filter('currency', function (value:number) {
  if (!value) return '$0.00'
  const formatted = new Intl.NumberFormat("es-MX", { style: "currency", currency: "MXN" }).format(value)
  return `${formatted}`
})


Vue.filter('uppercase', function (value:string) {
  if (!value) return ''
  return value.toUpperCase();
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
