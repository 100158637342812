
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import CatalogService, {
  IProduct,
  IProductQuery,
} from "@/services/catalog.service";

import eventBus from "@/services/eventBus";
import ProductItem from "./ProductItem.vue";

@Component({
  components: {
    "product-item": ProductItem,
    "vueper-slides": VueperSlides,
    "vueper-slide": VueperSlide,
  },
})
export default class FeaturedProducts extends Vue {
  @Prop() private title!: string;

  data() {
    return {
      models: [],
    };
  }

  created() {
    let config = {
      brand_id: null,
      category_id: null,
      search: null,
      page: 1,
      featured: true,
      top: null,
      filters: null,
    } as IProductQuery;

    CatalogService.getProducts(config)
      .then((models: IProduct[]) => {
        this.$data.models = models;
      })
      .catch((error) => {
        this.$data.models = [];
      });
  }
}
