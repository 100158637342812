var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.models.length != 0)?_c('div',{attrs:{"id":"featured-products"}},[_c('h2',{staticClass:"text-center mt-3 mb-5"},[_vm._v(_vm._s(_vm.title))]),_c('vueper-slides',{staticClass:"no-shadow mx-auto my-3",attrs:{"arrows":true,"bullets":false,"slide-multiple":1,"visible-slides":4,"gap":3,"slide-ratio":1 / 2,"dragging-distance":200,"arrowsOutside":false,"fixed-height":"270px","breakpoints":{
      3000: { visibleSlides: 4 },
      2000: { visibleSlides: 4 },
      1600: { visibleSlides: 3 },
      1200: { visibleSlides: 3 },
      900: { visibleSlides: 2 },
      500: { visibleSlides: 1 },
    }},scopedSlots:_vm._u([{key:"arrow-left",fn:function(){return [_c('b-icon',{attrs:{"icon":"chevron-left","variant":"dark"}})]},proxy:true},{key:"arrow-right",fn:function(){return [_c('b-icon',{attrs:{"icon":"chevron-right","variant":"dark"}})]},proxy:true}],null,false,1687461257)},_vm._l((_vm.models),function(model,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('product-item',{attrs:{"model":model}})]},proxy:true}],null,true)})}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }