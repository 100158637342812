
import { Component, Prop, Vue } from 'vue-property-decorator';
import ShippingMethod from '@/components/shopping-cart/ShippingMethod.vue';
import { IShippingMethod } from '@/services/store.service';

@Component({
    components: { 
        'shipping-method': ShippingMethod
    }
})
export default class ShippingMethods extends Vue {
    @Prop() private models!: IShippingMethod[];

    data() {
        return {
        };
    }

    // created() {
    //     // this.$store.commit('loadShippingMethods');
    // }
}
