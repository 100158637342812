import { render, staticRenderFns } from "./ContentCarousel.vue?vue&type=template&id=be3fea08&scoped=true&"
import script from "./ContentCarousel.vue?vue&type=script&lang=ts&"
export * from "./ContentCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./ContentCarousel.vue?vue&type=style&index=0&id=be3fea08&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be3fea08",
  null
  
)

export default component.exports