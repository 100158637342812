
import { Component, Prop, Vue } from 'vue-property-decorator';
import QtyInput from '@/components/shopping-cart/QtyInput.vue';
import DeleteItemButton from '@/components/shopping-cart/DeleteItemButton.vue';

import StoreService, { ISaleOrderItem } from '@/services/store.service';

@Component({
    components: { 
        'qty-input': QtyInput,
        'delete-item-button': DeleteItemButton
    }
})
export default class CartItem extends Vue {
    @Prop() private model!: ISaleOrderItem;

    data() {
        return {
        };
    }
}
