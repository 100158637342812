
import { Component, Prop, Vue } from "vue-property-decorator";
import { ISaleOrderItem } from "@/services/store.service";
import QtyInput from "@/components/shopping-cart/QtyInput.vue";
import DeleteItemButton from "@/components/shopping-cart/DeleteItemButton.vue";

@Component({
  components: {
    "qty-input": QtyInput,
    "delete-item-button": DeleteItemButton,
  },
})
export default class CartItem extends Vue {
  @Prop() private model!: ISaleOrderItem;

  data() {
    return {};
  }
}
