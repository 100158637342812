
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);
import { Component, Prop, Vue } from "vue-property-decorator";
import ProductItem from "./products/ProductItem.vue";

@Component({
  components: {
    Swiper,
    SwiperSlide,
    "product-item": ProductItem,
  },
})
export default class HeaderCarousel extends Vue {
  @Prop() private title!: string;
  @Prop() private model!: string;
  @Prop() private loadingRelated!: boolean;

  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  }
}
