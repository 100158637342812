
import { IProductDetail } from "@/services/catalog.service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { filter } from "vue/types/umd";
import PiecesInCart from "./PiecesInCart.vue";
import AddToCartForm from "./shopping-cart/AddToCartForm.vue";

interface IConfigAttribute {
  index: number;
  value: number;
}

@Component({
  components: {
    "pieces-in-cart": PiecesInCart,
    "add-to-cart-form": AddToCartForm,
  },
})
export default class BuyByPiece extends Vue {
  @Prop() private model!: IProductDetail;

  data() {
    return {
      variant_id: null,
      selected: null,
      desabled: null,
      config: [] as IConfigAttribute[],
      options: [],
      enablePurchase: true,
      form: {
        qty: 0,
      },
      attributes_count: {},
      // disabledbtn: false,
    };
  }

  created() {
    this.model.variants[0].config.forEach((e, index) => {
      // console.log(index);

      let item = this.model.variants[0].config[index];
      // console.log(item);
      this.selectAttribute(index, {
        id: item.values[0].id,
        name: item.values[0].name,
        attribute_id: item.values[0].attribute_id,
      });
    });
    // console.log(this.model.variants);

    // this.model.variants_attributes.forEach((variant_attribute) => {
    //   this.$data.attributes_count[variant_attribute.name] = {};
    //   this.$data.disabledbtn;

    //   variant_attribute.values.forEach((value) => {
    //     this.$data.attributes_count[variant_attribute.name][value.name] = 0;
    //   });
    // });

    // this.model.variants.forEach((variant) => {
    //   variant.config.forEach((config) => {
    //     config.values.forEach((value) => {
    //       console.log(value);
    //       this.$data.attributes_count[config.name][value.name] += variant.stock;
    //     });
    //   });
    // });
    this.disabledbtn;
  }
  updateVariant(value) {
    this.$emit("updateVariant", value);
  }

  disabledbtn(atrr, item) {
    function isNumeric(val) {
      return /^-?\d+$/.test(val);
    }
    let SLD = true;

    // this.model.variants.forEach((variant) => {
    //   console.log(
    //     variant.stock,
    //     variant.config[0].values[0].name, //color
    //     variant.config[1].values[0].name //talla
    //   );
    // });

    if (atrr.name == "COLOR") {
      SLD = true;

      this.model.variants.forEach((variant) => {
        if (
          item.name == variant.config[0].values[0].name &&
          variant.stock > 0
        ) {
          SLD = false;
        }
      });
    }

    // if (atrr.name == "Tallas") {
    //   console.log(atrr, item);
    //   console.log(this.$data.selected.config[0].values[0].name);
    //   this.model.variants.forEach((variant) => {
    //     if (
    //       variant.config[0].values[0].name ==
    //         this.$data.selected.config[0].values[0].name &&
    //       variant.config[1].values[0].name == item.name &&
    //       variant.stock > 0
    //     ) {
    //       console.log(variant.config[1].values[0].name);
    //       SLD = false;
    //     }
    //   });
    // }

    //typeof val === 'string'

    if (atrr.name == "Tallas") {
      if (isNumeric(this.$data.selected.config[0].values[0].name)) {
        // console.log(this.model.variants, atrr, item);

        this.model.variants.forEach((variant) => {
          if (
            variant.config[0].values[0].name == item.name &&
            variant.stock > 0
          ) {
            SLD = false;
          }
        });

        //string
      } else {
        this.model.variants.forEach((variant) => {
          // console.log(variant.config.length);

          if (Number(variant.config.length) > 1) {
            if (
              variant.config[0].values[0].name ==
                this.$data.selected.config[0].values[0].name &&
              variant.config[1].values[0].name == item.name &&
              variant.stock > 0
            ) {
              // console.log(variant.config[1].values[0].name);
              SLD = false;
            }
          } else {
            if (
              variant.config[0].values[0].name == item.name &&
              variant.stock > 0
            ) {
              SLD = false;
            }
          }
        });
      }
    }

    return SLD;
  }

  get variants_attributes() {
    const mapVariants = this.model.variants_attributes.map((item) => {
      const orderValues = item.values.sort((a, b) => {
        if (a.id > b.id) 1;
        if (a.id < b.id) -1;
        return 0;
      });
      return {
        ...item,
        values: orderValues,
      };
    });

    // console.log(mapVariants);
    return mapVariants;
  }
  isAttrValueSelected(index, item) {
    return this.$data.config[index] == item.id;
  }

  selectAttribute(index, item) {
    if (this.$data.config[index] == undefined) {
      this.$data.config.push(item.id);
    } else {
      this.$data.config[index] = item.id;
    }

    this.$data.selected = null;
    const sv = this.$data.config;

    // console.log("sv", sv);s
    // console.log("variants", this.model.variants);

    // this.$data.selected = this.model.variants[0].config[2];
    // console.log(this.$data.selected);

    for (let i = 0; i < this.model.variants.length; i++) {
      // console.log('a1');
      let vv: number[] = [];
      for (let j = 0; j < this.model.variants[i].config.length; j++) {
        // console.log('b1');
        for (
          let k = 0;
          k < this.model.variants[i].config[j].values.length;
          k++
        ) {
          // console.log(vv);
          // console.log('c1');
          vv.push(this.model.variants[i].config[j].values[k].id);
        }
      }

      const diff = vv.filter((x) => !sv.includes(x));
      // console.log(diff, "heree");
      // console.log("hereee");

      if (diff.length == 0) {
        this.$data.selected = { ...this.model.variants[i] };

        if (this.$data.selected.picture) {
          this.$emit("changeImg", this.$data.selected.picture[0].picture);
        }

        break;
      }
      // } else if (diff.length > 0) {
      //   this.$data.selected = this.model.variants[i];
      //   break;
      // }
    }

    // console.log('=========');
  }

  get allVariantsId() {
    return this.model.variants.map((variant) => variant.id);
  }
}
