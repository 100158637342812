
import { Component, Vue } from "vue-property-decorator";
import HeaderCarousel from "@/components/home/HeaderCarousel.vue";
import ContentCarousel from "@/components/home/ContentCarousel.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import FeaturedCategories from "@/components/home/FaturedCategories.vue";
import FeaturedProducts from "@/components/products/FeaturedProducts.vue";
import TopProducts from "@/components/products/TopProducts.vue";
import TopNewProducts from "@/components/products/TopNewProducts.vue";
import BrandCarousel from "@/components/BrandCarousel.vue";
import Parallax from "@/components/Parallax.vue";
import ComingSoon from "@/components/ComingSoon.vue";
import { MetaInfo } from "vue-meta";
import PaymentMethods from "@/components/PaymentMethods.vue";

@Component({
  metaInfo(this: Home): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "header-carousel": HeaderCarousel,
    "content-carousel": ContentCarousel,
    "featured-categories": FeaturedCategories,
    parallax: Parallax,
    "featured-products": FeaturedProducts,
    "top-products": TopProducts,
    "top-new-products": TopNewProducts,
    "brand-carousel": BrandCarousel,
    "coming-soon": ComingSoon,
    PaymentMethods,
  },
})
export default class Home extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.(gif|jpg|png|svg)$/);
    return images(image);
  }

  data() {
    // console.log(process.env.VUE_COMING_SOON);
    return {
      title: `¡Las mejores marcas de ropa en un solo lugar!`,
      description:
        "Distribuidora textil multi marca, especializada en venta al por mayor y por menor.",
      coming_soon: process.env.VUE_COMING_SOON == "TRUE",
    };
  }
}
