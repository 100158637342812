
import { Component, Vue } from "vue-property-decorator";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import AuthService, { Identity } from "@/services/auth.service";
import axios, { AxiosError } from "axios";
import eventBus from "@/services/eventBus";
import StoreService, { ISaleOrder } from "@/services/store.service";
import { ILocalSaleOrderItem } from "@/services/local.store.service";

export interface CategoryOption {
  value: number;
  text: string;
}

const valNumber = (value) => {
  const regex = /^([0-9]+)$/;
  if (regex.test(value)) {
    return true;
  }

  return false;
};

@Component({
  components: {},
})
export default class Signup extends Vue {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        email: "",
        x_password: "",
        password_confirm: "",
      },
      showPassword: false,
      showPasswordConfirm: false,
      signupError: false,
      signupComplete: false,
      signupErrorMessage: "",
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        name: { required },
        phone: { required, valNumber },
        email: { email, required },
        x_password: { required },
        password_confirm: {
          required,
          password_confirm: sameAs("x_password"),
        },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    this.$data.signupComplete = false;
    this.$data.signupError = false;
    this.$data.signupErrorMessage = "";

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      let data = { ...this.$data.form };

      AuthService.signup(data).then(
        (response: Identity) => {
          this.$store.commit("setIdentity", response);
          // this.$store.commit('loadShoppingCart');
          // this.$router.push('/mi-carrito');

          StoreService.getShoppingCart().then((model: ISaleOrder) => {
            this.$store.commit("setShoppingCart", model); //ISaleOrder
            let addItemToCartRequests = this.$store.state.localCartItems.map(
              (lsoi: ILocalSaleOrderItem) => {
                return StoreService.addItemToCart({
                  order_id: this.$store.state.cart.id,
                  name: lsoi.id,
                  product_id: lsoi.product.id,
                  variant_id: lsoi.variant.id,
                  qty: lsoi.qty,
                  price: lsoi.product.price,
                });
              }
            );

            Promise.all(addItemToCartRequests).then((responses) => {
              this.$store.commit("loadShoppingCart");
              this.$store.commit("clearLocalCartItems");
              this.$router.replace("/");
            });
          });
        },
        (err: Error | AxiosError) => {
          console.log(axios.isAxiosError(err));

          this.$data.signupComplete = false;
          this.$data.signupError = true;

          let msg: string[] = [];
          // if (axios.isAxiosError(err))  {
          //   // Access to config, request, and response
          //     for(let fe of err.response?.data) {
          //       msg.push(fe.msg);
          //     }
          // } else {

          // }

          msg.push(
            "Error al crear la cuenta. Intenta con otros datos, tu email ya esta registrado."
          );

          this.$data.signupErrorMessage = msg.join(". ");
        }
      );
    } else {
      console.log("error");
    }
  }
}
