import { render, staticRenderFns } from "./Terms.vue?vue&type=template&id=a8b13164&scoped=true&"
import script from "./Terms.vue?vue&type=script&lang=ts&"
export * from "./Terms.vue?vue&type=script&lang=ts&"
import style0 from "./Terms.vue?vue&type=style&index=0&id=a8b13164&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b13164",
  null
  
)

export default component.exports