
import SwiperCore, { Pagination, Navigation, Virtual } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper-vue2";

import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Virtual]);
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";

@Component({
  components: {
    Swiper,
    SwiperSlide,
    "inner-image-zoom": InnerImageZoom,
  },
})
export default class HeaderCarousel extends Vue {
  @Prop() private model;
  @Prop() initialPicture;
  data() {
    const slides = Array.from({ length: 600 }).map(
      (_, index) => `Slide ${index + 1}`
    );
    return {
      slides,
      swiperRef: null,
      appendNumber: 600,
      prependNumber: 1,
    };
  }

  @Watch("initialPicture")
  oninitialPicture(newValue, oldValue) {
    const index = this.model.pictures.findIndex((item) => {
      // console.log(item.url);
      return item.url.toLowerCase() == this.initialPicture?.toLowerCase();
    });
    // console.log(index);
    if (index == -1) {
      return;
    } else {
      if (newValue != oldValue) {
        // const plateElement = this.$refs.myVueperSlides as any;

        this.slideTo(index + 1);
      }
    }
  }
  // goToSlide(index) {
  //   console.log(index);
  //   // this.thumbsSwiper = swiper;
  // }

  setSwiperRef(swiper) {
    this.$data.swiperRef = swiper;
  }
  slideTo(index) {
    this.$data.swiperRef.slideTo(index, 0);
  }
}
