import axios from "axios";
import Cookies from "js-cookie";
import eventBus from '@/services/eventBus';
import { IGenericResponse } from "./interceptor";

export interface Identity {
    token:     string,
    data:      IProfile;
}

export interface IProfile {
    id:            number;
    email:         string;
    name:          string;
    phone:         string;
    vat:           string;
    address:       string;
    type:          string;
    pricelist_id:  string;
    pricelist_name:string;
}

export interface IAddress {
    id:                       number;
    contact_address_complete: string;
    name:                     string;
    street:                   string;
    street2:                  boolean | string;
    zip:                      string;
    city:                     string;
    state_id:                 Array<number | string>;
    country_id:               Array<number | string>;
    l10n_mx_edi_colony:       string;
}

class AuthService {

    setIdentity(identity:Identity|null) {
        Cookies.set('identity', JSON.stringify(identity));
        eventBus.$emit('identity-updated', identity);
    }

    getIdentity():Identity|null {
        const data = Cookies.get('identity');
        if(data != undefined && data != 'undefined') {
            const identity = JSON.parse(data) as Identity;
            return identity;
        }

        return null;
    }

    login(email:string, password:string):Promise<Identity> {
        const data = { email, password };
        return axios.post('/auth/login', data);
    }

    passwordResetRequest(email:string):Promise<IGenericResponse> {
        const data = { email };
        return axios.post('/auth/reset-password', data);
    }

    passwordResetRequestConfirm(id:string, key:string):Promise<IGenericResponse> {
        const data = { id, key };
        return axios.post('/auth/reset-password-confirm', data);
    }
    

    signup(data:any):Promise<Identity> {
        return axios.post('/auth/signup', data);
    }

    isLoggedIn():Promise<IGenericResponse> {
        const identity = this.getIdentity();
        if(identity != null) {
            return axios.get('/auth/is-logged-in');
        }

        return Promise.resolve({ success: false } as IGenericResponse);
    }

    getProfile():Promise<Identity> {
        return axios.get('/auth/profile');
    }

    updateProfile(data:any):Promise<Identity> {
        return axios.put('/auth/profile', data);
    }

    updateIdentity():Promise<Identity> {
        return axios.get('/auth/profile');
    }

    getAddresses():Promise<IAddress[]> {
        return axios.get('/auth/profile/address');
    }

    getAddress(id:number):Promise<IAddress> {
        return axios.get(`/auth/profile/address/${id}`);
    }
    
    createAddress(data:any):Promise<IAddress> {
        return axios.post(`/auth/profile/address`, data);
    }
    
    updateAddress(id:number, data:any):Promise<IAddress> {
        return axios.put(`/auth/profile/address/${id}`, data);
    }
    deleteAddress(id:number, data:any):Promise<IAddress> {
        return axios.delete(`/auth/profile/address/${id}`, data);
    }
    changePassword(data:any) {
        return axios.post(`/auth/change-password`, data);
    }
 
}

export default new AuthService();
