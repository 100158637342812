
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
import VerticalAligned from "@/components/VerticalAligned.vue";

import Parallax, { ParallaxBackground } from "@/components/Parallax.vue";
import { Component, Vue } from "vue-property-decorator";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import CrmService from "@/services/crm.service";
import { IGenericResponse } from "@/services/interceptor";
import eventBus from "@/services/eventBus";

const valNumber = (value) => {
  const regex = /^([0-9]+)$/;
  if (regex.test(value)) {
    return true;
  }

  return false;
};
@Component({
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    parallax: Parallax,
    "vueper-slides": VueperSlides,
    "vueper-slide": VueperSlide,
    "vertical-aligned": VerticalAligned,
  },
})
export default class About extends Vue {
  data() {
    return {
      title: "Nosotros",
      description: "Luna Trend",

      parallax: {
        background: this.getAssetPath(
          "./wholesaler/quiero-ser-distribuidor.jpg"
        ),
        backgroundSize: "contain",
        backgroundMobile: this.getAssetPath(
          "./wholesaler/quiero-ser-distribuidor.jpg"
        ),
        backgroundMobileSize: "200%",
      } as ParallaxBackground,

      slides: [
        {
          title: "Envío con bajo costo",
          logo: "./wholesaler/shipping.svg",
          picture: "./wholesaler/benefit-1.jpg",
        },
        {
          title: "Incrementa las ventas de negocio",
          logo: "./wholesaler/increase.svg",
          picture: "./wholesaler/benefit-2.jpg",
        },
        {
          title: "Gana hasta $10,000 al mes o mas",
          logo: "./wholesaler/revenues.svg",
          picture: "./wholesaler/benefit-3.jpg",
        },
      ],

      form: {
        company: null,
        type: null,
        address: null,
        employees: null,
        brands: [],
        name: null,
        email: null,
        phone: null,
        body: "",
        tracing_whatsapp: true,
      },
      sent: false,
      success: null,
      tracing_whatsapp_yes: true,
      tracing_whatsapp_not: false,
      options: [
        "Apple",
        "Orange",
        "Banana",
        "Lime",
        "Peach",
        "Chocolate",
        "Strawberry",
      ],
      // value: [],
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        company: { required },
        type: { required },
        address: { required },
        employees: { required },
        brands: { required },
        name: { required },
        email: { email, required },
        phone: { required, valNumber },
        body: { required: false },
      },
      hasAll: false,
    };
  }

  get availableOptions() {
    const brands = this.getAllBrands.filter(
      (opt) => this.$data.form.brands.indexOf(opt) === -1
    );
    if (this.$data.hasAll) {
      return ["QUITAR TODAS"];
    }
    // console.log(brands);
    return ["TODAS", ...brands];
  }

  get getAllBrands() {
    const brands = this.$store.getters.getAllBrands.map((item) => {
      return item.name;
    });
    return brands;
  }

  // get getAllBrands() {
  //   const brands = this.$store.getters.getAllBrands.map((item) => {
  //     return item.name;
  //   });
  //   return brands;
  // }
  seletedBrands() {
    this!["$v"].form.brands.$touch();

    // console.log(this.$data.form.brands);
    // si hay marcas y viene todas remover el de todas y agegar todas las marcas
    // remover el item de todas
    const existAll = this.$data.form.brands.find((item) => {
      return item == "TODAS";
    });
    const removeAll = this.$data.form.brands.find((item) => {
      return item == "QUITAR TODAS";
    });
    if (existAll) {
      // this;
      // console.log("todas");
      this.$data.hasAll = true;
      this.$data.form.brands = this.getAllBrands;
    } else if (removeAll) {
      this.$data.hasAll = false;
      this.$data.form.brands = [];
    }
  }

  onSubmit(event) {
    event.preventDefault();

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      this.$data.sent = true;
      this.$data.success = true;
      CrmService.becomeAWholesaler(this.$data.form)
        .then((response: IGenericResponse) => {
          this.$data.sent = true;
          this.$data.success = response.success;
          if (response.success) {
            eventBus.$emit(
              "showSuccessModal",
              "Solicitud enviada correctamente"
            );
            this.reset();
            this!["$v"].$reset();
          }
        })
        .catch((error) => {
          this.$data.success = false;

          if (error.response.status == 401) {
            eventBus.$emit("showErrorModal", "Ya se encuentra registrado");
          }
        });
    } else {
      console.log("error");
    }
  }

  hasTracking(resp) {
    // console.log(resp);
    this.$data.form.tracing_whatsapp = resp;
    if (resp) {
      this.$data.tracing_whatsapp_yes = true;
      this.$data.tracing_whatsapp_not = false;
    } else {
      this.$data.tracing_whatsapp_not = true;
      this.$data.tracing_whatsapp_yes = false;
    }
  }

  reset() {
    this.$data.form.company = null;
    this.$data.form.type = null;
    this.$data.form.address = null;
    this.$data.form.employees = null;
    this.$data.form.brands = [];
    this.$data.form.tracing_whatsapp = true;

    this.$data.form.name = null;
    this.$data.form.email = null;
    this.$data.form.phone = null;
    this.$data.form.body = "";
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.(gif|jpg|png|svg)$/);
    return images(image);
  }
}
