
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import AuthService, { Identity } from "@/services/auth.service";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";
import UserProfileForm from "@/components/UserProfileForm.vue";

// const validateRFC = (value) => {};
const validateRFC = (value) => {
  const regex =
    /[A-Z,,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};
@Component({
  metaInfo(this: OrderPay): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
    UserProfileForm,
  },
})
export default class OrderPay extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Cambiar contraseña usuario`,
      description: "Le permite al usuario actualizar su contraseña.",
      model: null,
      form: {
        currentPassword: "",
        newPassword: "",
        repeatPassword: "",
      },
      error: false,
      submitComplete: false,
      errorMessage: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        currentPassword: { required },
        newPassword: {
          required,
          minLength: minLength(6),
        },
        repeatPassword: {
          required,
          sameAsPassword: sameAs("newPassword"),
        },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    this.$data.submitComplete = false;
    this.$data.error = false;
    this.$data.errorMessage = "";

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      let data = {
        old_password: this.$data.form.currentPassword,
        new_password: this.$data.form.newPassword,
      };
      AuthService.changePassword(data)
        .then((identity) => {
          console.log(data);
          // AuthService.setIdentity(identity);
          // this.$store.commit("setIdentity", identity);
          eventBus.$emit("showSuccessModal", "Solicitud enviada correctamente");
          // this.reset();
          this!["$v"].$reset();
        })
        .catch((error) => {
          console.log(error);
          eventBus.$emit("showErrorModal", "Datos enviados son incorrectos.");
          // this.$data.model = null;
          // AuthService.setIdentity(null);
          // this.$store.commit('loadIdentity');
        });
    } else {
      console.log("error");
    }
  }
}
