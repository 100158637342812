
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAddress } from '@/services/auth.service';
import CustomerAddress from '@/components/shopping-cart/CustomerAddress.vue';
import AddAddressCardButton from '@/components/shopping-cart/AddAddressCardButton.vue';


@Component({
    components: { 
        'customer-address': CustomerAddress,
        'add-address-card-button': AddAddressCardButton
    }
})
export default class CustomerAddresses extends Vue {
    @Prop() private models!: IAddress[];

    data() {
        return {
        };
    }
}
