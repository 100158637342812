
import { Component, Watch, Vue } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';
import StoreService, { IReturn } from '@/services/store.service';
import ReturnDetailSummary from '@/components/returns/ReturnDetailSummary.vue';

@Component({
  metaInfo(this: ReturnDetail):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    'page-header': PageHeader,
    'page-footer': PageFooter,
    'return-detail-summary': ReturnDetailSummary
  },
})
export default class ReturnDetail extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Luna Trend`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
            title: `Detalle de solicitud`,
            description: 'Consulta el estatus de tu solicitud de devolución.',
            model: null,
            paymentMethod: 'TDC'
        };
    }

    isPending() {
        return this.$data.model.x_studio_request == 'Pendiente';
    }

    isValid() {
        return this.$data.model.x_studio_request == 'Aplica';
    }

    isInvalid() {
        return this.$data.model.x_studio_request == 'No aplica';
    }

    isReceibed() {
        return this.$data.model.x_studio_state == 'status1';
    }

    isAccepted() {
        return this.$data.model.x_studio_state == 'status2';
    }

    isRejected() {
        return this.$data.model.x_studio_state == 'status3';
    }

    paymentRequiered() {
        return false;
    }

    @Watch('$route.params', { deep: true })
    onModelChange(newVal:any):void {
        this.updateModel(parseInt(this.$route.params.id));
    }

    created() {
        this.updateModel(parseInt(this.$route.params.id));
    }

    updateModel(id:number) {
        StoreService.getReturn(id).then((model:IReturn) => {
            this.$data.model = model;
        }).catch(error => {
            this.$data.model = null;
        });
    }
}
