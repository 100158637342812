var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login-form"},[_c('h1',{staticClass:"text-uppercase mb-3"},[_vm._v("Iniciar sesión")]),_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"label":"Correo electrónico:","label-for":"login-input-email"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
          'is-valid': _vm.$v.form.email.$dirty && !_vm.$v.form.email.$error,
        },attrs:{"type":"email","placeholder":"Tu email","autocomplete":"off","size":"sm"},on:{"input":_vm.$v.form.email.$touch},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" No ha ingresado un email válido. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"login-input-password",attrs:{"id":"login-input-group-password","label":"Contraseña:","label-for":"login-input-password"}},[_c('div',{staticClass:"login-content"},[_c('b-form-input',{class:{
            'is-invalid': _vm.$v.form.password.$dirty && _vm.$v.form.password.$error,
            'is-valid': _vm.$v.form.password.$dirty && !_vm.$v.form.password.$error,
          },attrs:{"id":"login-input-password","placeholder":"Tu contraseña","type":_vm.showPassword ? 'text' : 'password',"size":"sm","autocomplete":"off"},on:{"input":_vm.$v.form.password.$touch},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('b-icon',{attrs:{"icon":_vm.showPassword ? 'eye-slash' : 'eye-fill',"aria-hidden":"true"}})],1)],1)],1),(_vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" La contraseña es obligatoria ")]):_vm._e()]),_c('b-button',{attrs:{"type":"submit","block":"","variant":"secondary"}},[_vm._v("Entrar")]),(_vm.loginError != null && _vm.loginError == true)?_c('div',{staticClass:"alert alert-danger mt-3",attrs:{"role":"alert"}},[_vm._v(" Usuario y contraseña incorrectos ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }