import axios from "axios";
import { Identity } from "./auth.service";
import { IBanner } from "./banners.service";
import { IOrder, IQuantityPriceList, ISaleOrder } from "./store.service";

export interface IInitialData {
    webSite:           any|null;
    identity:           Identity|null;
    banners:            IBanner[];
    countries:          ICountry[];
    brands:             IBrand[];
    categories:         ICategory[];
    shoppingCart:       ISaleOrder|null;
    quantityPriceLists: IQuantityPriceList[];
}

export interface IBrand {
    id:               number;
    name:             string;
    slug:             string;
    description:      string;
    meta_description: string;
    meta_keywords:    string;
    logo:             string;
    banner:           string;
}

export interface ICategory {
    id:               number;
    parent_id:        number;
    featured:         boolean;
    name:             string;
    description:      string;
    seo_name:         string;
    slug:             string;
    meta_description: string;
    meta_keywords:    string;
    picture:          string;
    banner:           string;
    children:         ICategory[];
}


export interface IValueOption {
    value:          string;
    text:           string;
    count:          number;
    selected:       boolean;
}

export interface IAttr {
    key:   string;
    name:   string;
    values: IValueOption[];
}


export interface IProduct {
    id:                number;
    featured:          boolean;
    top:               boolean;
    sku:               null;
    name:              string;
    type:              string;
    size_guide_id:     number;
    category_id:       number;
    category_name:     string;
    category_seo_name: string;
    category_slug:     string;
    brand_id:          number;
    coming_soon:       string|null;
    brand_name:        string;
    brand_slug:        string;
    slug:              string;
    price:             string;
    min_price:         number;
    max_price:         number;
    has_discount:      boolean;
    old_price:         string;
    sale_ok:           boolean;
    description:       string;
    meta_description:  string;
    vimeo_id:          string;
    meta_keywords:     string;
    picture:           string;
    attributes:        string;
    attribute_value_ids: string;
    attribute_value_labels: string;
    filters:           IAttr[];
    gender:            string;
    dressing:          string;
    pockets:           string;
    season:            string;
    tiro:              string;
    cut:               string;
    typea:             string;
    stock:             number;
    variants:         IVariant[];
    NumPages:            string | number;
}

export interface IProductDetailPackage {
    boxes: any[],
    packages: any[]
}

export interface IPresentations {
    packages: IPresentation[];
    boxes:    IPresentation[];
}

export interface IPresentation {
    id:       number;
    name:     string;
    product_content: string;
    product:  IPresentationProduct;
    variants: IPresentationVariant[];
    pieces: number;
}

export interface IPresentationProduct {
    id:   number;
    name: string;
    type: string;
}

export interface IPresentationVariant {
    id:    number;
    price: number;
    stock: number;
}

export interface IProductDetailPresentations {
    boxes: any[],
    packages: any[]
}

export interface IProductDetail {
    id:               number;
    featured:         boolean;
    top:              boolean;
    sku:              null;
    name:             string;
    type:             string;
    size_guide_id:    number;
    slug:             string;
    price:            string;
    min_price:            number;
    max_price:            number;
    old_price:        string;
    sale_ok:          boolean;
    description:      string;
    meta_description: string;
    vimeo_id:         string;
    meta_keywords:    string;
    picture:          IAttachment;
    brand:            IBrand;
    category:         ICategory;
    size_guide:       IAttachment;
    product_attachment: string;
    downloadable_video: IAttachment;
    pictures:         IAttachment[];
    variants_attributes:       IVariantsAttribute[];
    variants:         IVariant[];
    gender:            string;
    dressing:          string;
    pockets:           string;
    season:            string;
    tiro:              string;
    cut:               string;
    typea:             string;
    showPrices:             boolean;
    stock:             number;
    stock_total:             number;
    presentations:     IPresentations;
}

export interface IAttachment {
    id: number;
    name: string;
    description: string;
    url: string;
}

export interface IAttribute {
    attribute: string;
    values:    IValue[];
}

export interface IValue {
    value: number;
    text:  string;
}

export interface IBrand {
    id:               number;
    name:             string;
    slug:             string;
    description:      string;
    meta_description: string;
    meta_keywords:    string;
    logo:             string;
    banner:           string;
}

export interface ISizeGuide {
    id:          number;
    name:        string;
    description: string;
    url:         string;
}

export interface IVariant {
    id:           number;
    default_code: string;
    picture:      null;
    config:       IConfig[];
    price:        number;
    stock:        number;
}

export interface IVariantConfig {
    id:           number;
    name:         string;
    display_type: string;
    values:       IVariantConfigValue[];
    attribute_id:           number;
}

export interface IVariantConfigValue {
    id:           number;
    name:         string;
    is_custom:    boolean;
    html_color:   null;
    attribute_id: number;
}

export interface IVariantsAttribute {
    id:     number;
    name:   string;
    values: IVariantsAttributeValue[];
}

export interface IVariantsAttributeValue {
    id:   number;
    name: string;
}

export interface IConfig {
    id:           number;
    name:         string;
    display_type: string;
    values:       IConfigValue[];

}

export interface IConfigValue {
    id:           number;
    name:         string;
    is_custom:    boolean;
    html_color:   string;
    html_color2:   string;
    attribute_id: number;
}


export interface IPicture {
    thumb_picture: string;
    picture:       string;
    name:          null | string;
}

export interface IProductQuery {
    brand_id:       number|null;
    category_id:    number|null;
    search:         string|null;
    page:           number|null|any;
    featured:       boolean|null;
    top:            boolean|null;
    catalog:            boolean|null;
    filters:        any;
    related:        any;
}

export interface IVariantDetail {
    id:              number;
    name:            string;
    sku:             boolean;
    price:           number;
    stock:           number;
    maximum_pieces:  number;
}

export interface ICountry {
    id:             number;
    name:           string;
}

export interface ICountryState {
    id:             number;
    name:           string;
}

export interface IGenericFilterValue {
    id:             number;
    name:           string;
}


export interface ISearchResultFilters {
    tags:       string[];
    attributes: ISearchResultAttribute[];
    genders: IGenericFilterValue[];
    dressings: IGenericFilterValue[];
    pockets: IGenericFilterValue[];
    seasons: IGenericFilterValue[];
    tiros: IGenericFilterValue[];
    cuts: IGenericFilterValue[];
    types: IGenericFilterValue[];
    brands: IGenericFilterValue[];
}

export interface ISearchResultAttribute {
    id:     number;
    name:   string;
    values: ISearchResultAttributeValue[];
}

export interface ISearchResultAttributeValue {
    id:   number;
    name: string;
}

export interface IDownloadFile {
    download_url:    string;
    transaction_ref: string;
    status:          string;
    template_id:     string;
}


class CatalogService {

    getVariant(id:number):Promise<IVariantDetail> {
        return axios.get(`/catalog/products/variant/${id}`);
    }

    getProduct(id:number):Promise<IProductDetail> {
        return axios.get(`/catalog/products/${id}`);
    }

    getProducts(params:any={}):Promise<IProduct[]> {
        // console.log(params);
        return axios.post(`/catalog/products`, params);
    }

    async getProductsFilters(params:any={}):Promise<ISearchResultFilters> {
        let result = (await axios.post(`/catalog/products-filters`, params)) as ISearchResultFilters;
        return result;
    }

    getCategoriesMenu():Promise<ICategory[]> {
        return axios.get(`/catalog/categories-menu`);
    }

    getCategory(id:number):Promise<ICategory> {
        return axios.get(`/catalog/categories/${id}`);
    }

    getBrand(id:number):Promise<IBrand> {
        return axios.get(`/catalog/brands/${id}`);
    }

    getCategories():Promise<ICategory[]> {
        return axios.get(`/catalog/categories`);
    }

    getBrands():Promise<IBrand[]> {
        return axios.get(`/catalog/brands`);
    }

    getCountries():Promise<ICountry[]> {
        return axios.get(`/catalog/country`);
    }

    getCountryStates(country_id:number):Promise<ICountryState[]> {
        return axios.get(`/catalog/country/${country_id}`);
    }

    getInitialData():Promise<IInitialData> {
        return axios.get(`/catalog/initial-data`);
    }

    async generatePdfCatalogDocument(body):Promise<IDownloadFile|null> {
        try {
            let result = (await axios.post(`/catalog/download`, body)) as IDownloadFile;
            return result;
        } catch(e) {
            return null;
        }
    }

}

export default new CatalogService();