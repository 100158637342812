
import { IPresentation, IProductDetail } from "@/services/catalog.service";
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import BoxPresentationAddToCartForm from "./shopping-cart/BoxPresentationAddToCartForm.vue";

@Component({
  components: {
    "box-presentation-add-to-cart-form": BoxPresentationAddToCartForm,
  },
})
export default class BuyByPiece extends Vue {
  @Prop() private model!: IPresentation;

  get hasModel() {
    return this.model != null && this.model.variants.length != 0;
  }

  get stock() {
    if (
      this.hasModel &&
      this.model.variants &&
      this.model.variants.length != 0
    ) {
      return this.model.variants[0].stock;
    }

    return 0;
  }

  get isDisabled() {
    return this.stock <= 0;
  }
}
