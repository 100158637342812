
import { Component, Prop, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import LoginForm from "@/components/auth/LoginForm.vue";
// import ResetPasswordForm from '@/components/auth/ResetPasswordForm.vue';

@Component({
  components: {
    "login-form": LoginForm,
    // 'reset-password-form': ResetPasswordForm,
  },
})
export default class Login extends Vue {
  data() {
    return {
      login: true,
    };
  }

  toggleLogin() {
    eventBus.$on(
      "toggle-login",
      (login: boolean) => (this.$data.login = login)
    );
  }
}
