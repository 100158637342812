
import { Navigation, Pagination, Autoplay } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    Swiper,
    SwiperSlide,
  },
})
export default class HeaderCarousel extends Vue {
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  }
  goTo(item) {
    window.location.href = item.button_link;
  }
}
