
import { Component, Watch, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import CatalogService, {
  IBrand,
  ICategory,
  IProduct,
  IProductQuery,
} from "@/services/catalog.service";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import QtyInput from "@/components/shopping-cart/QtyInput.vue";
import CartSummary from "@/components/shopping-cart/CartSummary.vue";
import CartItem from "@/components/shopping-cart/CartItem.vue";
import DeleteItemButton from "@/components/shopping-cart/DeleteItemButton.vue";
import CustomerAddresses from "@/components/shopping-cart/CustomerAddresses.vue";
import ShippingMethods from "@/components/shopping-cart/ShippingMethods.vue";
import EmptyCart from "@/components/shopping-cart/EmptyCart.vue";

@Component({
  metaInfo(this: ShoppingCart): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "qty-input": QtyInput,
    "cart-summary": CartSummary,
    "cart-item": CartItem,
    "delete-item-button": DeleteItemButton,
    "empty-cart": EmptyCart,
    "customer-addresses": CustomerAddresses,
    "shipping-methods": ShippingMethods,
  },
})
export default class ShoppingCart extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: "Mi carrito",
      description: "Luna Trend",
    };
  }

  created() {
    if (this.$store.state.cart != null) {
      this.$store.commit("loadAddresses");
    } else {
      this.$router.replace("/productos");
    }
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.jpg$/);
    return images(image);
  }
  validatePresentation(): boolean {
    const items = this.$store.getters.productItems.filter((item) => {
      // console.log(item);
      return (
        item.name.toLocaleLowerCase().includes("caja") ||
        item.name.toLocaleLowerCase().includes("paquete")
      );
    });
    // console.log(items.length > 0);
    return items.length > 0;
  }
}
