
import PageFooter from "@/components/PageFooter.vue";
import PageHeader from "@/components/PageHeader.vue";
import Parallax, { ParallaxBackground } from "@/components/Parallax.vue";
import { Component, Vue } from "vue-property-decorator";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import CrmService from "@/services/crm.service";
import { IGenericResponse } from "@/services/interceptor";
import eventBus from "@/services/eventBus";
import TheMap from "@/components/TheMap.vue";
import BannerIntro from "@/components/BannerIntro.vue";
const valNumber = (value) => {
  const regex = /^([0-9]+)$/;
  if (regex.test(value)) {
    return true;
  }

  return false;
};
@Component({
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    parallax: Parallax,
    TheMap,
    BannerIntro,
  },
})
export default class Contact extends Vue {
  data() {
    return {
      title: "Nuestros productos",
      description: "Luna Trend",

      form: {
        name: "",
        email: "",
        phone: "",
        body: "",
      },
      sent: false,
      success: null,

      parallax: {
        background: this.getAssetPath("./contact/contacto.jpg"),
        backgroundSize: "contain",
        backgroundMobile: this.getAssetPath("./contact/contacto.jpg"),
        backgroundMobileSize: "200%",
      } as ParallaxBackground,
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        name: { required },
        email: { email, required },
        phone: { required, valNumber },
        body: { required },
      },
    };
  }

  onSubmit(event) {
    event.preventDefault();

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      this.$data.sent = true;
      this.$data.success = true;
      CrmService.contact(this.$data.form).then((response: IGenericResponse) => {
        this.$data.sent = true;
        this.$data.success = response.success;
        if (response.success) {
          eventBus.$emit("showSuccessModal", "Solicitud enviada correctamente");
          this.reset();
          this!["$v"].$reset();
        }
      });
    } else {
      console.log("error");
    }
  }

  reset() {
    this.$data.form.name = "";
    this.$data.form.email = "";
    this.$data.form.phone = "";
    this.$data.form.location = "";
    this.$data.form.body = "";
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.jpg$/);
    return images(image);
  }
}
