
import { Component, Watch, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import StoreService, { IOrder, ISaleOrderItem } from "@/services/store.service";
import OrderDetailSummary from "@/components/orders/OrderDetailSummary.vue";

@Component({
  metaInfo(this: OrderDetail): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "order-detail-summary": OrderDetailSummary,
  },
})
export default class OrderDetail extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Pagar un pedido`,
      description:
        "Realiza el pago de tu pedido con tarjeta de crédito, debito, depósito o transferencia.",
      model: null,
      paymentMethod: "TDC",
    };
  }

  canBePayed() {
    return (
      this.$data.model.state !== "cancel" || this.$data.model.state == false
    );
    // return (
    //   this.$data.model.x_studio_payment_method == "No pagado" ||
    //   this.$data.model.x_studio_payment_method == false
    // );
  }

  @Watch("$route.params", { deep: true })
  onModelChange(newVal: any): void {
    this.updateModel(parseInt(this.$route.params.id));
  }

  created() {
    this.updateModel(parseInt(this.$route.params.id));
  }

  updateModel(id: number) {
    StoreService.getOrder(id)
      .then((model: IOrder) => {
        this.$data.model = model;
      })
      .catch((error) => {
        this.$data.model = null;
      });
  }
  download() {
    // this.$router.go(-1);
    window.print();
  }
}
