import axios from "axios";
import { IGenericResponse } from "./interceptor";

export interface IPaymentIntent {
    id:                          string;
    object:                      string;
    amount:                      number;
    status:                      string;
    url:                         string;
}

class StripeService {
    payWithCard(id:number, data:any):Promise<IGenericResponse> {
        return axios.post(`/stripe/pay-with-new-card/${id}`, data);
    }

    payWithOxxo(id:number, data:any):Promise<IGenericResponse> {
        return axios.post(`/stripe/pay-with-oxxo/${id}`, data);
    }
}

export default new StripeService();