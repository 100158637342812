var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"create-return"}},[_c('page-header'),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"py-3",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('hr',{staticClass:"my-0"}),_c('div',{staticClass:"my-2"},[_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"5"}},[_c('b-form-group',{attrs:{"id":"form-group-order","label":"Pedido:","label-for":"input-order","description":"Solo aparecen pedidos con producto entregado."}},[_c('b-form-select',{class:{
                          'is-invalid':
                            _vm.$v.form.order.$dirty && _vm.$v.form.order.$error,
                          'is-valid':
                            _vm.$v.form.order.$dirty && !_vm.$v.form.order.$error,
                        },attrs:{"id":"form-order","options":_vm.orders},on:{"input":_vm.$v.form.order.$touch,"change":function($event){return _vm.getOrderDetail()}},model:{value:(_vm.form.order),callback:function ($$v) {_vm.$set(_vm.form, "order", $$v)},expression:"form.order"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"7"}},[_c('b-form-group',{attrs:{"id":"form-group-product_tmpl_id","label":"Producto:","label-for":"input-product_tmpl_id","description":"Cantidad entregada y producto."}},[_c('b-form-select',{class:{
                          'is-invalid':
                            _vm.$v.form.product_tmpl_id.$dirty &&
                            _vm.$v.form.product_tmpl_id.$error,
                          'is-valid':
                            _vm.$v.form.product_tmpl_id.$dirty &&
                            !_vm.$v.form.product_tmpl_id.$error,
                        },attrs:{"id":"form-product_tmpl_id","options":_vm.products},on:{"input":_vm.$v.form.product_tmpl_id.$touch,"change":function($event){return _vm.updateMaxValue()}},model:{value:(_vm.form.product_tmpl_id),callback:function ($$v) {_vm.$set(_vm.form, "product_tmpl_id", $$v)},expression:"form.product_tmpl_id"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"form-group-qty","label":"Cantidad:","label-for":"input-qty"}},[_c('b-form-input',{class:{
                          'is-invalid':
                            _vm.$v.form.qty.$dirty && _vm.$v.form.qty.$error,
                          'is-valid':
                            _vm.$v.form.qty.$dirty && !_vm.$v.form.qty.$error,
                        },attrs:{"id":"form-qty","type":"text","autocomplete":"off"},on:{"input":_vm.$v.form.qty.$touch},model:{value:(_vm.form.qty),callback:function ($$v) {_vm.$set(_vm.form, "qty", $$v)},expression:"form.qty"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"form-group-reason","label":"Motivo:","label-for":"input-reason"}},[_c('b-form-select',{class:{
                          'is-invalid':
                            _vm.$v.form.reason.$dirty && _vm.$v.form.reason.$error,
                          'is-valid':
                            _vm.$v.form.reason.$dirty && !_vm.$v.form.reason.$error,
                        },attrs:{"id":"form-reason","options":_vm.reasons},on:{"input":_vm.$v.form.reason.$touch},model:{value:(_vm.form.reason),callback:function ($$v) {_vm.$set(_vm.form, "reason", $$v)},expression:"form.reason"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"form-group-comment","label":"Observaciones:","label-for":"input-comment"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.comment),expression:"form.comment"}],staticClass:"form-control",class:{
                          'is-invalid':
                            _vm.$v.form.comment.$dirty && _vm.$v.form.comment.$error,
                          'is-valid':
                            _vm.$v.form.comment.$dirty && !_vm.$v.form.comment.$error,
                        },attrs:{"id":"form-comment","type":"textarea","autocomplete":"off"},domProps:{"value":(_vm.form.comment)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "comment", $event.target.value)},_vm.$v.form.comment.$touch]}})])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"form-group-evidence","label":"Evidencia:","label-for":"input-evidence"}},[_c('b-form-file',{attrs:{"state":Boolean(_vm.form.evidence),"placeholder":"Seleciona una foto","drop-placeholder":"Arrastra aquí...","accept":".jpg,.jpeg,.png","browse-text":"Seleccionar..."},model:{value:(_vm.form.evidence),callback:function ($$v) {_vm.$set(_vm.form, "evidence", $$v)},expression:"form.evidence"}})],1)],1)],1),_c('div',{staticClass:"text-right mt-3"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Enviar solicitud")])],1),(_vm.error != null && _vm.error == true)?_c('div',{staticClass:"alert alert-danger mt-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.submitComplete == true)?_c('div',{staticClass:"alert alert-success mt-3",attrs:{"role":"alert"}},[_vm._v(" Se ha enviado su solicitud correctamente. ")]):_vm._e()],1)],1)]),_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('my-account-sidebar')],1)],1)],1)],1)],1),_c('page-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }