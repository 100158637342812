
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Breadcrumb extends Vue {
  @Prop() private path!: any[];

  data() {
    return {};
  }

  canGoBack() {
    return window.history.length;
  }

  goBack() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
  }
}
