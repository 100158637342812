
import { IProductDetail } from "@/services/catalog.service";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CardPackage from "./CardPackage.vue";
import StoreService, { ISaleOrder } from "@/services/store.service";
import { IPresentationItem } from "@/store";
import eventBus from "../services/eventBus";
import PiecesInCart from "./PiecesInCart.vue";

@Component({
  components: {
    CardPackage,
    "pieces-in-cart": PiecesInCart,
  },
})
export default class BuyByPiece extends Vue {
  @Prop() private model!: IProductDetail;

  addItemsToCart() {
    let addItemToCartRequests = this.selectedItems.map(
      (presentationItem: IPresentationItem) => {
        return StoreService.addItemToCart({
          order_id: this.$store.state.cart.id,
          name: presentationItem.presentation.name,
          product_id: presentationItem.presentation.id,
          variant_id: presentationItem.presentation.variants[0].id,
          qty: presentationItem.qty,
          price: presentationItem.presentation.variants[0].price,
        });
      }
    );

    Promise.all(addItemToCartRequests).then((responses) => {
      this.$store.commit("loadShoppingCart");
      eventBus.$emit("clear-selected-box-items");
    });
  }

  get allVariants() {
    let variants_id: number[] = [];
    if (this.model.presentations.boxes.length != 0) {
      for (let i = 0; i < this.model.presentations.boxes.length; i++) {
        variants_id.push(
          ...this.model.presentations.boxes[i].variants.map(
            (variant) => variant.id
          )
        );
      }

      return variants_id;
    }

    return [];
  }

  get price() {
    if (this.model.presentations.boxes.length != 0) {
      return this.model.presentations.boxes[0].variants[0].price;
    }

    return 0;
  }
  get getStock() {
    let totalStock = 0;
    this.model.presentations.boxes.forEach((paquete) => {
      // console.log(paquete.variants);
      const stocks = paquete.variants.reduce((previous, current) => {
        return previous + current.stock;
      }, 0);
      totalStock += stocks;
      console.log(totalStock);
    });

    return totalStock;
  }

  get numberPieces() {
    if (this.model.presentations.packages.length != 0) {
      const pieces = this.model.presentations.packages[0].pieces;

      return pieces;
    }

    return 0;
  }

  get pricePerPiece() {
    // console.log(this.model);
    if (this.model.presentations.boxes.length != 0) {
      const pieces = this.model.presentations.boxes[0].pieces;
      console.log({
        precio: this.price,
        pieces: Number(pieces),
        pieza: this.numberPieces,
      });
      if (pieces > 0) {
        return Number(this.price) / Number(pieces);
        // return Number(this.price) / Number(pieces) / Number(this.numberPieces);
      }
    }

    return 0;
  }

  get boxes() {
    if (this.model.presentations != null) {
      return this.model.presentations.boxes;
    }

    return [];
  }

  get selectedItems() {
    return this.$store.getters.getBoxItems;
  }

  get hasSelectedItems() {
    const itemsSelectByType = this.selectedItems.filter((item) => {
      return item.presentation.product.type == "BOX";
    });

    return itemsSelectByType.length != 0;
  }
}
