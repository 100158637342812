import { render, staticRenderFns } from "./BankPayment.vue?vue&type=template&id=9827a938&scoped=true&"
import script from "./BankPayment.vue?vue&type=script&lang=ts&"
export * from "./BankPayment.vue?vue&type=script&lang=ts&"
import style0 from "./BankPayment.vue?vue&type=style&index=0&id=9827a938&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9827a938",
  null
  
)

export default component.exports