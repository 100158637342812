
import { Component, Prop, Vue } from 'vue-property-decorator';
import Auth from '@/components/auth/Auth.vue';

@Component({
    components: {
        'auth': Auth,
    }
})
export default class SignIn extends Vue {
    data() {
        return {
        }
    }

  openModal(event) {
    event.preventDefault()
    if(this.$refs.signInModal) {
        this.$refs.signInModal['open']();
    }
  }

}
