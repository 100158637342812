
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { IProfile } from '@/services/auth.service';
import AuthService, { Identity } from '@/services/auth.service';
import MyAccountMenu from '@/components/my-account/MyAccountMenu.vue';

@Component({
    components: { 
        'my-account-menu': MyAccountMenu
    }
})
export default class MyAccountSidebar extends Vue {

    data() {
        return {
        };
    }
}
