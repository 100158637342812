import axios from "axios";
import { IVariantDetail } from "./catalog.service";
import { IGenericResponse } from "./interceptor";

export interface ISaleOrder {
    id:                                        number;
    date_order:                                Date;
    state:                                     string;
    display_name:                              string;
    amount_discount:                           number;
    amount_undiscounted:                       number;
    amount_untaxed:                            number;
    amount_tax:                                number;
    amount_total:                              number;
    discount:                                  number;
    amount_by_group:                           Array<Array<number | string>>;
    applied_coupon_ids:                        any[];
    promo_code:                                boolean|string;
    carrier_id:                                Array<Array<number | string>>;
    partner_invoice_id:                        Array<Array<number | string>>;
    partner_shipping_id:                       Array<Array<number | string>>;
    x_studio_tracking_link:                    boolean;
    x_studio_customer_shipping_label_filename: boolean;
    x_studio_cx_shipping_label:                 boolean;
    x_studio_confimation_id:                   boolean;
    x_studio_field_jqeIK:                      boolean;
    x_studio_payment_status:                   boolean|string;
    x_studio_payment_method:                   boolean|string;
    x_studio_reference:                        boolean;
    x_studio_delivered:                        boolean;
    items:                                     ISaleOrderItem[];
    tracing_whatsapp:                          boolean;
}


export interface ISaleOrderItem {
    id:                    number;
    name:                  string;
    slug:                  string;
    product_tmpl_id:       number;
    type:                  string;
    product_product_id:    number;
    product_uom_qty:       number;
    price_unit:            number;
    price_reduce_taxexcl:  number;
    discount:              number;
    price_subtotal:        number;
    price_tax:             number;
    price_total:           number;
    url:                   string;
    qty_delivered:         number;
    stock:                 number;
}

export interface IShippingMethod {
    carrier: ICarrier;
    quote:   ICarrierQuote;
}

export interface ICarrier {
    id:                           number;
    name:                         string;
    active:                       boolean;
    sequence:                     number;
    integration_level:            string;
    prod_environment:             boolean;
    debug_logging:                boolean;
    company_id:                   boolean;
    product_id:                   Array<number | string>;
    invoice_policy:               string;
    country_ids:                  any[];
    state_ids:                    any[];
    zip_from:                     boolean;
    zip_to:                       boolean;
    margin:                       number;
    free_over:                    boolean;
    amount:                       number;
    can_generate_return:          boolean;
    return_label_on_delivery:     boolean;
    get_return_label_from_portal: boolean;
    fixed_price:                  number;
    delivery_type:                string;
    price_rule_ids:               any[];
    display_name:                 string;
    create_uid:                   Array<number | string>;
    create_date:                  Date;
    write_uid:                    Array<number | string>;
    write_date:                   Date;
    __last_update:                Date;
}

export interface ICarrierQuote {
    success:            boolean;
    price:              number;
    error_message:      boolean;
    warning_message:    boolean;
    carrier_price:      number;
    apply: boolean;
}

export interface IOrder {
    id:                                        number;
    date_order:                                Date;
    state:                                     string;
    display_name:                              string;
    amount_undiscounted:                       number;
    amount_untaxed:                            number;
    amount_tax:                                number;
    amount_total:                              number;
    amount_by_group:                           Array<Array<number | string>>;
    partner_invoice_id:                        Array<number | string>;
    partner_shipping_id:                       Array<number | string>;
    applied_coupon_ids:                        any[];
    promo_code:                                boolean|string;
    carrier_id:                                Array<number | string>;
    sale_origin:                               string;
    sale_type:                                 string;
    x_studio_tracking_link:                    boolean;
    x_studio_customer_shipping_label_filename: boolean;
    x_studio_customer_shipping_label:          boolean;
    x_studio_confimation_id:                   boolean;
    x_studio_field_jqeIK:                      boolean;
    x_studio_payment_status:                   boolean|string;
    x_studio_payment_method:                   boolean|string;
    x_studio_reference:                        boolean;
    items:                                     ISaleOrderItem[];
    x_studio_delivered:                        boolean;
    tracing_whatsapp:                        boolean;
}

export interface IQuantityPriceList {
    id:                             number;
    display_name:                   string;
    x_studio_amount_of_pieces:      number;
    x_studio_discount:              number;
    x_studio_pricelist_description: boolean | string;
}

export interface IReturnReason {
    id:     number;
    x_name: string;
}

export interface IReturn {
    id:                        number;
    x_name:                    string;
    write_date:                Date;
    x_studio_amount:           number;
    x_studio_product:          string;
    x_studio_return_reason:    string | null;
    x_studio_state:            string;
    x_studio_sale_order:       string |null;
    x_studio_comment:          string; 
    x_studio_shipping_methods: string | null;
    x_studio_shipping_label:   null | string;
    x_studio_amount_to_pay:    number;
    x_studio_request:          string;
    x_studio_comments:         null | string;
    x_studio_evidence:         null | string;
    x_studio_attachment:       null | string;
}

export interface ShoppingCartUpdated {
    shoppingCart: ISaleOrder,
    quoteShipping: IShippingMethod[],
    variant: IVariantDetail
}

class StoreService {
    getShoppingCart():Promise<ISaleOrder> {
        return axios.get(`/store/shopping-cart`);
    }

    addItemToCart(data:any):Promise<IGenericResponse> {
        // console.log(data)
        return axios.post(`/store/shopping-cart`, data);
    }
    
    updateItemCart(data:any):Promise<IGenericResponse> {
        return axios.put(`/store/shopping-cart`, data);
    }

    deleteCartItem(order_id:number, order_line_id:number):Promise<IGenericResponse> {
        return axios.delete(`/store/shopping-cart/${order_id}/${order_line_id}`);
    }

    addCoupon(order_id:number, code:string):Promise<IGenericResponse> {
        return axios.put(`/store/shopping-cart/add-coupon`, {
            order_id,
            code
        });
    }

    removeCoupon():Promise<IGenericResponse> {
        return axios.put(`/store/shopping-cart/remove-coupon`, {});
    }

    setShippingAddress(order_id:number, address_id:number):Promise<IGenericResponse> {
        return axios.put(`/store/shopping-cart/set-shipping-address`, {
            order_id,
            address_id
        });
    }

    quoteShipping(order_id:number, address_id:number|string):Promise<any[]> {
        const data = { order_id, address_id };
        return axios.post(`/store/shopping-cart/quote-shipping`, data);
    }

    setShippingMethod(order_id:number, carrier_id:number):Promise<IGenericResponse> {
        return axios.put(`/store/shopping-cart/set-shipping-method`, {
            order_id,
            carrier_id
        });
    }

    confirm(order_id:number, tracing_whatsapp: boolean):Promise<IGenericResponse> {
        console.log({
            order_id,tracing_whatsapp
        })
        return axios.put(`/store/shopping-cart/confirm`, {
            order_id,
            tracing_whatsapp
        });
    }

    getTotalOrders():Promise<IOrder[]> {
        return axios.get(`/store/count-orders`);
    }
    getOrders(page:any, delivered = false):Promise<IOrder[]> {
        console.log(page)
        return axios.post(`/store/order`, { page, delivered });
    }

    getOrder(id:number):Promise<IOrder> {
        return axios.get(`/store/order/${id}`);
    }

    getQuantityPriceLists():Promise<IQuantityPriceList[]> {
        return axios.get(`/store/quantity-price-lists`);
    }

    getReturns():Promise<IReturn[]> {
        return axios.get(`/store/return`);
    }

    getReturn(id:number):Promise<IReturn> {
        return axios.get(`/store/return/${id}`);
    }
    cancelOrder(id:number):any {
        return axios.put(`/store/cancel/${id}`);
    }

    getReturnReasons():Promise<IReturnReason[]> {
        return axios.get(`/store/return-reason`);
    }

    uploadFile(file:File):Promise<IGenericResponse> {
        const formData = new FormData();
        formData.append('image', file, file.name);

        return axios.post(`/upload/single`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
    
    createReturn(data:any):Promise<any> {
        return axios.post(`/store/return`, data);
    }
}

export default new StoreService();