
import catalogService, { IProductQuery } from "@/services/catalog.service";
import eventBus from "@/services/eventBus";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NavBar extends Vue {
  data() {
    return {
      query: "",
      showDialog: false,
      searching: false,
      results: [],
      loadingQuery: false,
    };
  }

  // @Watch("query")
  queryForm() {
    if (this.$data.query.length <= 0) {
      this.$data.showDialog = false;
    } else {
      this.search();
    }
  }

  async search() {
    this.$data.showDialog = true;
    if (!this.$data.searching) {
      // this.$data.searching = true;

      // this!["$v"].$touch();
      // if (!this!["$v"].$invalid) {
      try {
        this.$data.loadingQuery = true;
        let config = {
          brand_id: null,
          category_id: null,
          search: this.$data.query,
          page: 1,
          featured: null,
          top: null,
          filters: {
            tags: null,
            attributes: null,
          },
        } as IProductQuery;

        // eventBus.$emit("loading", false);

        this.$data.results = await catalogService.getProducts(config);
        this.$data.loadingQuery = false;
        // console.log(this.$data.results);
      } catch (e) {
        console.log({ e });
        this.$data.loginError = true;
        eventBus.$emit("showErrorModal", "busqueda incorrecta!.");
      }
    }
    // }
  }

  clickOutside(event) {
    this.$data.showDialog = false;
    this.$data.query = "";
  }
  redirecTo(name, element) {
    if (element.table == "products") {
      this.$router.push({
        name,
        params: { id: element.id, slug: element.alias },
      });
    } else {
      this.$router.push({ name, query: { categories: element.name } });
    }
    this.$data.showDialog = false;
    this.$data.query = "";
  }
}
