var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"become-a-wholesaler"}},[_c('page-header'),(_vm.parallax != null)?_c('parallax',{attrs:{"background":_vm.parallax.background,"backgroundSize":_vm.parallax.backgroundSize,"height":"400px","backgroundMobile":_vm.parallax.backgroundMobile,"backgroundMobileSize":_vm.parallax.backgroundMobileSize,"heightMobile":"400px"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"text-uppercase mr-3 text-white"},[_vm._v(" Conviertete en distribuidor ")]),_c('h2',{staticClass:"text-uppercase mr-3 text-white"},[_vm._v(" Crea tu propio negocio o "),_c('br'),_vm._v(" incrementa tus ganancias ")])])],1)],1)],1):_vm._e(),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"py-3 py-md-5",attrs:{"cols":"12","md":"10","offset-md":"1","lg":"6","offset-lg":"3"}},[_c('p',{staticClass:"font-weight-bold text-center text-primary text-bigger"},[_vm._v(" Obtén los mejores precios en productos y envios, la mayor variedad de productos. ")])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('vueper-slides',{staticClass:"no-shadow mx-auto my-3",attrs:{"arrows":true,"bullets":false,"slide-multiple":1,"visible-slides":3,"gap":3,"dragging-distance":100,"arrowsOutside":false,"fixed-height":"300px","breakpoints":{
            900: { visibleSlides: 3 },
            500: { visibleSlides: 1 },
          }},scopedSlots:_vm._u([{key:"arrow-left",fn:function(){return [_c('div',{staticClass:"vueperslides__arrow"},[_c('b-icon',{attrs:{"icon":"chevron-left","variant":"dark"}})],1)]},proxy:true},{key:"arrow-right",fn:function(){return [_c('div',{staticClass:"vueperslides__arrow"},[_c('b-icon',{attrs:{"icon":"chevron-right","variant":"dark"}})],1)]},proxy:true}])},_vm._l((_vm.slides),function(slide,i){return _c('vueper-slide',{key:i,attrs:{"image":slide.picture},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"vueperslide__content",style:('background-image: url(' + _vm.getAssetPath(slide.picture) + ')')},[_c('vertical-aligned',{staticClass:"px-1 px-md-5",attrs:{"height":"300px"}},[_c('img',{staticClass:"d-block mx-auto mb-4",attrs:{"src":_vm.getAssetPath(slide.logo),"alt":slide.title,"width":"80"}}),_c('h3',{staticClass:"text-uppercase text-center text-white"},[_vm._v(" "+_vm._s(slide.title)+" ")])])],1)]},proxy:true}],null,true)})}),1)],1)],1)],1),_c('b-container',[_c('b-row',{staticClass:"pb-3"},[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('h1',{staticClass:"text-uppercase mt-3"},[_vm._v("¡Regístrate!")]),_c('p',[_vm._v("No pierdas la oportunidad de comenzar un gran negocio.")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"px-3"},[_c('b-alert',{attrs:{"show":_vm.sent && _vm.success == true,"variant":"success"}},[_c('p',{staticClass:"p-0 m-0"},[_vm._v(" Gracias por registrarte. Analizaremos tu solicitud y te contáctaremos pronto. ")])]),_c('b-alert',{attrs:{"show":_vm.sent && _vm.success == false,"variant":"danger"}},[_c('p',{staticClass:"p-0 m-0"},[_vm._v("Lo sentimos. No se pudo crear tu registro.")])]),_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-company","label":"Nombre comercial:","label-for":"input-company"}},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.form.company.$dirty && _vm.$v.form.company.$error,
                      'is-valid':
                        _vm.$v.form.company.$dirty && !_vm.$v.form.company.$error,
                    },attrs:{"id":"input-company","type":"text","placeholder":"Nombre de tu negocio"},on:{"input":_vm.$v.form.company.$touch},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-type","label":"Tipo de local:","label-for":"input-type"}},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.form.type.$dirty && _vm.$v.form.type.$error,
                      'is-valid': _vm.$v.form.type.$dirty && !_vm.$v.form.type.$error,
                    },attrs:{"id":"input-type","type":"text","placeholder":"Ej. Local en plaza comercial, local en avenida"},on:{"input":_vm.$v.form.type.$touch},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-address","label":"Dirección comercial:","label-for":"input-address"}},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.form.address.$dirty && _vm.$v.form.address.$error,
                      'is-valid':
                        _vm.$v.form.address.$dirty && !_vm.$v.form.address.$error,
                    },attrs:{"id":"input-address","type":"text","placeholder":"Calle, número exterior, número interior, colonia, código postal, ciudad y estado."},on:{"input":_vm.$v.form.address.$touch},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-employees","label":"Número de colaboradores:","label-for":"input-employees"}},[_c('b-form-select',{class:{
                      'is-invalid':
                        _vm.$v.form.employees.$dirty && _vm.$v.form.employees.$error,
                      'is-valid':
                        _vm.$v.form.employees.$dirty && !_vm.$v.form.employees.$error,
                    },attrs:{"id":"input-employees","options":[
                      { text: 'Selecciona', value: null },
                      '1 - 10',
                      '10 - 50',
                      '50 - 100',
                      'Más de 100',
                    ]},on:{"input":_vm.$v.form.employees.$touch},model:{value:(_vm.form.employees),callback:function ($$v) {_vm.$set(_vm.form, "employees", $$v)},expression:"form.employees"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Marcas de tu interés:","label-for":"tags-component-select"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"id":"tags-component-select","size":"lg","add-on-change":"","no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function({
                        tags,
                        inputAttrs,
                        inputHandlers,
                        disabled,
                        removeTag,
                      }){return [(tags.length > 0)?_c('ul',{staticClass:"list-inline d-inline-block mb-2"},_vm._l((tags),function(tag){return _c('li',{key:tag,staticClass:"list-inline-item"},[_c('b-form-tag',{attrs:{"title":tag,"disabled":disabled,"variant":"primary"},on:{"remove":function($event){return removeTag(tag)}}},[_vm._v(_vm._s(tag))])],1)}),0):_vm._e(),_c('b-form-select',_vm._g(_vm._b({class:{
                          'is-invalid':
                            _vm.$v.form.brands.$dirty && _vm.$v.form.brands.$error,
                          'is-valid':
                            _vm.$v.form.brands.$dirty && !_vm.$v.form.brands.$error,
                        },attrs:{"disabled":disabled || _vm.availableOptions.length === 0,"options":_vm.availableOptions},on:{"input":_vm.seletedBrands},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"disabled":"","value":""}},[_vm._v(" Selecciona tus marcas. ")])]},proxy:true}],null,true)},'b-form-select',inputAttrs,false),inputHandlers))]}}]),model:{value:(_vm.form.brands),callback:function ($$v) {_vm.$set(_vm.form, "brands", $$v)},expression:"form.brands"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-name","label":"Nombre de Contacto:","label-for":"input-name"}},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.form.name.$dirty && _vm.$v.form.name.$error,
                      'is-valid': _vm.$v.form.name.$dirty && !_vm.$v.form.name.$error,
                    },attrs:{"id":"input-name","type":"text","placeholder":"Nombre completo"},on:{"input":_vm.$v.form.name.$touch},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-phone","label":"Teléfono de contacto:","label-for":"input-phone"}},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.form.phone.$dirty && _vm.$v.form.phone.$error,
                      'is-valid':
                        _vm.$v.form.phone.$dirty && !_vm.$v.form.phone.$error,
                    },attrs:{"id":"input-phone","type":"text","placeholder":"Teléfono a 10 dígitos"},on:{"input":_vm.$v.form.phone.$touch},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(!_vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su teléfono ")]):(!_vm.$v.form.phone.valNumber)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Solo puede contener caracteres numéricos ")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-email","label":"Email:","label-for":"input-email"}},[_c('b-form-input',{class:{
                      'is-invalid':
                        _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
                      'is-valid':
                        _vm.$v.form.email.$dirty && !_vm.$v.form.email.$error,
                    },attrs:{"id":"input-email","type":"email"},on:{"input":_vm.$v.form.email.$touch},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mb-3 mb-lg-0"},[_c('div',{staticClass:"card-whatsapp"},[_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../assets/whatsapp.svg"),"alt":"whatsapp","width":"25","height":"25"}}),_c('b',{staticClass:"d-block"},[_vm._v("¿Quieres dar seguimiento a tu solicitud mediante whatsApp?")])]),_c('div',{staticClass:"ml-4"},[_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"variant":[
                          _vm.tracing_whatsapp_yes ? 'success' : 'primary',
                        ]},on:{"click":function($event){return _vm.hasTracking(true)}}},[_vm._v("Si ")]),_c('b-button',{staticClass:"mt-2",attrs:{"variant":[
                          _vm.tracing_whatsapp_not ? 'success' : 'primary',
                        ]},on:{"click":function($event){return _vm.hasTracking(false)}}},[_vm._v(" No")])],1)])])])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"input-group-body","label":"Notas o comentarios acerca tu comercio:","label-for":"input-body"}},[_c('b-form-textarea',{class:{
                      'is-invalid':
                        _vm.$v.form.body.$dirty && _vm.$v.form.body.$error,
                      'is-valid': _vm.$v.form.body.$dirty && !_vm.$v.form.body.$error,
                    },attrs:{"placeholder":"Ej. Links a redes sociales, sucursales u otros locales, etc.","rows":"3","max-rows":"6"},on:{"input":_vm.$v.form.body.$touch},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('b-button',{staticClass:"px-5 text-uppercase",attrs:{"variant":"primary","type":"submit"}},[_vm._v("Enviar")])],1)],1)],1)],1)])],1)],1),_c('page-footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }