
import { Component, Prop, Vue } from "vue-property-decorator";
import StoreService, { ISaleOrderItem } from "@/services/store.service";
import CatalogService, { IVariantDetail } from "@/services/catalog.service";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {},
})
export default class DeleteItemButton extends Vue {
  @Prop() private model!: ISaleOrderItem;

  data() {
    return {};
  }

  doIt() {
    StoreService.deleteCartItem(this.$store.state.cart.id, this.model.id)
      .then((response: IGenericResponse) => {
        if (response.success == true) {
          this.$store.commit("setShoppingCart", response.data.shoppingCart);
          this.$store.commit("setShippingMethods", response.data.quoteShipping);

          // this.$store.commit("loadShoppingCart");
          // this.$store.commit('loadShippingMethods');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
