var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"id":"signup-input-group-name","label":"Nombre(s):","label-for":"input-name"}},[_c('b-form-input',{class:{
        'is-invalid': _vm.$v.form.name.$dirty && _vm.$v.form.name.$error,
        'is-valid': _vm.$v.form.name.$dirty && !_vm.$v.form.name.$error,
      },attrs:{"readonly":_vm.isDisabled,"disabled":_vm.isDisabled,"id":"signup-input-name","type":"text","placeholder":"Tu nombre completo"},on:{"input":_vm.$v.form.name.$touch},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su(s) nombre(s) ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-phone","label":"Teléfono:","label-for":"signup-input-phone"}},[_c('b-form-input',{class:{
        'is-invalid': _vm.$v.form.phone.$dirty && _vm.$v.form.phone.$error,
        'is-valid': _vm.$v.form.phone.$dirty && !_vm.$v.form.phone.$error,
      },attrs:{"readonly":_vm.isDisabled,"disabled":_vm.isDisabled,"id":"signup-input-phone","type":"text","placeholder":"Teléfono a 10 dígitos"},on:{"input":_vm.$v.form.phone.$touch},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(!_vm.$v.form.phone.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su teléfono ")]):(!_vm.$v.form.phone.valNumber)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Solo puede contener caracteres numéricos ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-phone","label":"Whatsapp:","label-for":"signup-input-phone"}},[_c('b-form-input',{class:{
        'is-invalid': _vm.$v.form.mobile.$dirty && _vm.$v.form.mobile.$error,
        'is-valid': _vm.$v.form.mobile.$dirty && !_vm.$v.form.mobile.$error,
      },attrs:{"readonly":_vm.isDisabled,"disabled":_vm.isDisabled,"id":"signup-input-mobile","type":"text","placeholder":"Teléfono a 10 dígitos"},on:{"input":_vm.$v.form.mobile.$touch},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}}),(!_vm.$v.form.mobile.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su teléfono ")]):(!_vm.$v.form.mobile.valNumber)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Solo puede contener caracteres numéricos ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-email","label":"Correo electrónico:","label-for":"signup-input-email"}},[_c('b-form-input',{class:{
        'is-invalid': _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
        'is-valid': _vm.$v.form.email.$dirty && !_vm.$v.form.email.$error,
      },attrs:{"id":"signup-input-email","type":"email","placeholder":"Tu email","disabled":true},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" No ha ingresado un email válido. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-vat","label":"RFC:","label-for":"signup-input-vat"}},[_c('b-form-input',{class:{
        'is-invalid': _vm.$v.form.vat.$dirty && _vm.$v.form.vat.$error,
        'is-valid': _vm.$v.form.vat.$dirty && !_vm.$v.form.vat.$error,
      },attrs:{"readonly":_vm.isDisabled,"disabled":_vm.isDisabled,"id":"signup-input-vat","type":"text","placeholder":"Tu RFC (XAXX010101000)"},model:{value:(_vm.form.vat),callback:function ($$v) {_vm.$set(_vm.form, "vat", $$v)},expression:"form.vat"}}),(!_vm.$v.form.vat.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su RFC ")]):_vm._e(),(!_vm.$v.form.vat.validateRFC)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar un RFC válido (XAXX010101000) ")]):_vm._e()],1),(!_vm.isDisabled)?_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Guardarmis datos")])],1):_vm._e(),(_vm.error != null && _vm.error == true)?_c('div',{staticClass:"alert alert-danger mt-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.submitComplete == true)?_c('div',{staticClass:"alert alert-success mt-3",attrs:{"role":"alert"}},[_vm._v(" Se ha actualizado la información. ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }