
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: { }
})
export default class EmptyCart extends Vue {    
    data() {
        return {
        };
    }
}
