
import { Component, Prop, Vue } from "vue-property-decorator";
import StoreService, { ISaleOrder } from "@/services/store.service";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  components: {},
})
export default class CouponInput extends Vue {
  @Prop() private model!: ISaleOrder;

  data() {
    return {
      form: {
        coupon: null,
      },
      errMessage: null,
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        coupon: { required },
      },
    };
  }

  clearCoupon() {
    StoreService.removeCoupon().then((response: IGenericResponse) => {
      if (response.success == true) {
        this.$store.commit("setShoppingCart", response.data.shoppingCart);
        this.$store.commit("setShippingMethods", response.data.quoteShipping);

        // this.$store.commit('loadShoppingCart');
        // this.$store.commit('loadShippingMethods');
      }
    });
  }

  onSubmit() {
    if (this.$refs.confirmModal && this.$store.state.cart.amount_discount != 0) {
      this.$refs.confirmModal["open"]();
    } else {
      this.confirmCoupon();
    }
  }

  confirmCoupon() {
    if (this.$refs.confirmModal) {
      this.$refs.confirmModal["close"]();
    }

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      StoreService.addCoupon(this.$store.state.cart.id, this.$data.form.coupon).then(
        (response: IGenericResponse) => {
          if (response.success) {
            this.$store.commit("setShoppingCart", response.data.shoppingCart);
            this.$store.commit("setShippingMethods", response.data.quoteShipping);
            this.$data.form.coupon = "";
            if (this.$refs.successModal) {
              this.$refs.successModal["open"]();
            }
          } else {
            if (this.$refs.errorModal) {
              this.$data.errMessage = response.data.error;
              this.$refs.errorModal["open"]();
            }
          }
        },
        (err) => {
          this.$data.form.coupon = "";
          // console.log(error);
          if (this.$refs.notFoundModal) {
            this.$refs.notFoundModal["open"]();
          }
        }
      );
    } else {
      console.log("error");
    }
  }
}
