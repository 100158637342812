var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"signup"},[_c('h1',{staticClass:"text-uppercase mb-3"},[_vm._v("Crear una cuenta")]),_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"id":"signup-input-group-name","label":"Nombre(s):","label-for":"input-name"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.$v.form.name.$dirty && _vm.$v.form.name.$error,
          'is-valid': _vm.$v.form.name.$dirty && !_vm.$v.form.name.$error,
        },attrs:{"id":"signup-input-name","type":"text","placeholder":"Tu nombre completo","size":"sm"},on:{"input":_vm.$v.form.name.$touch},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su(s) nombre(s) ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-phone","label":"Teléfono:","label-for":"signup-input-phone"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.$v.form.phone.$dirty && _vm.$v.form.phone.$error,
          'is-valid': _vm.$v.form.phone.$dirty && !_vm.$v.form.phone.$error,
        },attrs:{"id":"signup-input-phone","type":"text","placeholder":"Teléfono a 10 dígitos","size":"sm"},on:{"input":_vm.$v.form.phone.$touch},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(!_vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su teléfono ")]):(!_vm.$v.form.phone.valNumber)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Solo puede contener caracteres numéricos ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-email","label":"Correo electrónico:","label-for":"signup-input-email"}},[_c('b-form-input',{class:{
          'is-invalid': _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
          'is-valid': _vm.$v.form.email.$dirty && !_vm.$v.form.email.$error,
        },attrs:{"id":"signup-input-email","type":"email","placeholder":"Tu email","size":"sm"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" No ha ingresado un email válido. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"login-input-password",attrs:{"id":"signup-input-group-password","label":"Contraseña:","label-for":"input-password"}},[_c('div',{staticClass:"login-content"},[_c('b-form-input',{class:{
            'is-invalid':
              _vm.$v.form.x_password.$dirty && _vm.$v.form.x_password.$error,
            'is-valid':
              _vm.$v.form.x_password.$dirty && !_vm.$v.form.x_password.$error,
          },attrs:{"id":"signup-input-password","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Tu contraseña","size":"sm","autocomplete":"off"},model:{value:(_vm.form.x_password),callback:function ($$v) {_vm.$set(_vm.form, "x_password", $$v)},expression:"form.x_password"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('b-icon',{attrs:{"icon":_vm.showPassword ? 'eye-slash' : 'eye-fill',"aria-hidden":"true"}})],1)],1)],1),(_vm.$v.form.x_password.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" La contraseña debe ser de al menos seis caracteres. ")]):_vm._e()]),_c('b-form-group',{staticClass:"login-input-password",attrs:{"id":"signup-input-group-password_confirm","label":"Confirmar contraseña:","label-for":"input-password_confirm"}},[_c('div',{staticClass:"login-content"},[_c('b-form-input',{class:{
            'is-invalid':
              _vm.$v.form.password_confirm.$dirty &&
              _vm.$v.form.password_confirm.$error,
            'is-valid':
              _vm.$v.form.password_confirm.$dirty &&
              !_vm.$v.form.password_confirm.$error,
          },attrs:{"id":"signup-input-password-confirm","type":_vm.showPasswordConfirm ? 'text' : 'password',"placeholder":"Repite tu contraseña","size":"sm","autocomplete":"off"},model:{value:(_vm.form.password_confirm),callback:function ($$v) {_vm.$set(_vm.form, "password_confirm", $$v)},expression:"form.password_confirm"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){_vm.showPasswordConfirm = !_vm.showPasswordConfirm}}},[_c('b-icon',{attrs:{"icon":_vm.showPasswordConfirm ? 'eye-slash' : 'eye-fill',"aria-hidden":"true"}})],1)],1)],1),(_vm.$v.form.password_confirm.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe repetir exactamente la contraseña. ")]):_vm._e()]),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"type":"submit","variant":"secondary","block":""}},[_vm._v("Crear mi cuenta")])],1),(_vm.signupError != null && _vm.signupError == true)?_c('div',{staticClass:"alert alert-danger mt-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.signupErrorMessage)+" ")]):_vm._e(),(_vm.signupComplete == true)?_c('div',{staticClass:"alert alert-success mt-3",attrs:{"role":"alert"}},[_vm._v(" Se ha creado tu cuenta correctamente. Ahora puedes realizar compras en el sitio. ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }