
import { IProductDetail, IVariant } from "@/services/catalog.service";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PiecesInCart extends Vue {
  @Prop() private variants!: number[];

  get productQty() {
    const items_products_ids = this.cartItems.map(
      (cartItem) => cartItem.product_product_id
    );

    let qty = 0;
    for (let i = 0; i < this.variantsId.length; i++) {
      const ii = items_products_ids.indexOf(this.variantsId[i]);
      if (ii != -1) {
        qty += this.cartItems[ii].product_uom_qty;
      }
    }

    return qty;
  }

  get variantsId() {
    return this.variants;
    // if(this.variants) {
    //   return this.variants.map((variant) => variant.id);
    // }

    // return [];
  }

  get cartItems() {
    return this.$store.getters.productItems;
  }
}
