import { render, staticRenderFns } from "./MyAccountSideBar.vue?vue&type=template&id=25ab18f3&scoped=true&"
import script from "./MyAccountSideBar.vue?vue&type=script&lang=ts&"
export * from "./MyAccountSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./MyAccountSideBar.vue?vue&type=style&index=0&id=25ab18f3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ab18f3",
  null
  
)

export default component.exports