var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$store.getters.carouselBrands.length != 0)?_c('div',{staticClass:"carousel-home brands bg-grey py-5"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-center text-uppercase mb-5 mb-lg-0"},[_vm._v(" Nuestras marcas destacadas ")]),_c('swiper',{attrs:{"spaceBetween":30,"slidesPerView":'auto',"loop":true,"autoplay":{
            delay: 4000,
            disableOnInteraction: false,
          },"pagination":{
            clickable: true,
            type: 'bullets',
          },"breakpoints":{
            '320': {
              navigation: false,
              slidesPerView: 2,
            },
            '640': {
              navigation: false,
              slidesPerView: 3,
            },
            '1000': {
              navigation: false,
              slidesPerView: 4,
            },
            '1200': {
              slidesPerView: 5,
              navigation: true,
            },
            '1500': {
              slidesPerView: 6,
              navigation: true,
            },
          }}},[_vm._l((_vm.$store.getters.carouselBrands),function(brand,index){return _c('swiper-slide',{key:index,staticClass:"slide"},[_c('router-link',{staticClass:"brand-img",attrs:{"to":`/productos/marca/${brand.id}/${_vm.string_to_slug(
                  brand.slug
                )}?page=1`}},[_c('img',{staticClass:"img-fluid d-block mx-auto",attrs:{"src":brand.logo,"alt":brand.name,"height":"100%","width":"100%"}})])],1)}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }