
import catalogService, {
  IProduct,
  IProductQuery,
} from "@/services/catalog.service";
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);
import { Component, Prop, Vue } from "vue-property-decorator";
import ProductItem from "./ProductItem.vue";

@Component({
  components: {
    Swiper,
    SwiperSlide,
    ProductItem,
  },
})
export default class HeaderCarousel extends Vue {
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      models: [],
    };
  }

  @Prop() private title!: string;

  created() {
    let config = {
      brand_id: null,
      category_id: null,
      search: null,
      page: 1,
      featured: true,
      top: null,
      filters: null,
    } as IProductQuery;

    catalogService
      .getProducts(config)
      .then((models: IProduct[]) => {
        // console.log(this.$data.models);
        this.$data.models = models;
        // console.log(models);
      })
      .catch((error) => {
        this.$data.models = [];
      });
  }
}
