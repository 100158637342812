
import { Component, Vue } from "vue-property-decorator";
import Cart from "../shopping-cart/widget/Cart.vue";
import SignIn from "@/components/SignIn.vue";
import MyAccountMenu from "@/components/my-account/MyAccountMenu.vue";

@Component({
  components: {
    cart: Cart,
    "sign-in": SignIn,
    "my-account-menu": MyAccountMenu,
  },
})
export default class MobileNavbar extends Vue {
  data() {
    return {
      showSidebar: false,
      activeTab: 0,
      showSidebarProfile: false,
    };
  }
  quantityItems() {
    return this.$store.state.cart.items.reduce((previous, current) => {
      return previous + current.product_uom_qty;
    }, 0);

    // }
  }

  get categories() {
    const slugs = [
      "variedad-de-ropa-para-caballero",
      "variedad-de-ropa-para-dama",
    ];

    let filtro = [
      ...this.$store.getters.rootCategories.filter((item) =>
        slugs.includes(item.slug)
      ),
    ];

    return filtro;
  }

  chooseTab(i) {
    this.$data.activeTab = i;
  }

  toggleSidebar() {
    this.$data.showSidebar = !this.$data.showSidebar;
  }

  toggleSidebarProfile() {
    this.$data.showSidebarProfile = !this.$data.showSidebarProfile;
  }
}
