
import { Component, Prop, Vue } from 'vue-property-decorator';
import { vueVimeoPlayer } from 'vue-vimeo-player'

@Component({
  components: {  'vimeo-player': vueVimeoPlayer }
})
export default class Video extends Vue {
    @Prop() id!: string;

    data() {
        return {
          options: {
            controls: false,
            responsive: true,
            autoplay: true,
            background: true,
            autopause: false,
            muted: false,
            loop: true
          }
        }
    }
}
