
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import UserProfileForm from "@/components/UserProfileForm.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import AuthService, { Identity } from "@/services/auth.service";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";
// const validateRFC = (value) => {};
const validateRFC = (value) => {
  const regex =
    /[A-Z,,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
};
@Component({
  metaInfo(this: OrderPay): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
    UserProfileForm,
  },
})
export default class OrderPay extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Perfil de usuario`,
      description: "Puedes consultar y actualizar la información de tu cuenta.",
      model: null,
      form: {
        name: "",
        phone: "",
        mobile: "",
        email: "",
        vat: "",
      },
      error: false,
      submitComplete: false,
      errorMessage: "",
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        name: { required },
        phone: { required },
        mobile: { required },
        vat: { required, validateRFC },
        email: { email, required },
      },
    };
  }

  created() {
    this.updateModel();
  }

  updateModel() {
    AuthService.getProfile()
      .then((model: Identity) => {
        this.$data.model = model.data;
        this.$data.form.name = this.$data.model.name;
        this.$data.form.email = this.$data.model.email;
        this.$data.form.phone = this.$data.model.phone;
        this.$data.form.mobile = this.$data.model.mobile;
        this.$data.form.vat = this.$data.model.vat;
        this.$data.form.constancy = this.$data.model.constancy;
      })
      .catch((error) => {
        this.$data.model = null;
      });
  }

  onSubmit(event) {
    event.preventDefault();

    this.$data.submitComplete = false;
    this.$data.error = false;
    this.$data.errorMessage = "";

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      let data = { ...this.$data.form };
      AuthService.updateProfile(data)
        .then((identity: Identity) => {
          this.$data.model = identity.data;
          // AuthService.setIdentity(identity);
          this.$store.commit("setIdentity", identity);
        })
        .catch((error) => {
          // this.$data.model = null;
          // AuthService.setIdentity(null);
          // this.$store.commit('loadIdentity');
        });
    } else {
      console.log("error");
    }
  }
}
