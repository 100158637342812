
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOrder } from "@/services/store.service";
import { SweetModal, SweetModalTab } from "sweet-modal-vue";

@Component({
  components: {
    SweetModal,
    SweetModalTab,
  },
})
export default class StripeOxxoPayment extends Vue {
  @Prop() private model!: IOrder;

  data() {
    return {
      owner: "Corporativo ANUL",
      account: "011 229 1622",
      account2: "012 222 00112291622 9",
    };
  }

  print() {
    window.print();
  }
}
