
import { Component, Vue } from "vue-property-decorator";
import AuthService from "@/services/auth.service";

@Component({
  components: {},
})
export default class MyAccountMenu extends Vue {
  data() {
    return {};
  }

  signout() {
    // AuthService.setIdentity(null);
    // this.$store.commit('loadIdentity');
    this.$store.commit("setIdentity", null);
    this.$store.commit("loadShoppingCart");
    this.$router.push("/");
  }
  closePopover() {
    this.$emit("close");
  }
}
