import axios from "axios";
import { IGenericResponse } from "./interceptor";

class CrmService {
    contact(data:any):Promise<IGenericResponse> {
        return axios.post(`/crm/contact`, data);
    }

    becomeAWholesaler(data:any):Promise<IGenericResponse> {
        return axios.post(`/crm/become-a-wholesaler`, data);
    }
}

export default new CrmService();