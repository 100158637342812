import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import ProductDetail from '../views/ProductDetail.vue'
import Contact from '../views/Contact.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import BecomeAWolesaler from '../views/BecomeAWolesaler.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import PublicShoppingCart from '../views/PublicShoppingCart.vue'
import Auth from '../views/Auth.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import PasswordResetResponse from '../views/PasswordResetResponse.vue'
import MyOrders from '../views/MyOrders.vue'
import MyReturns from '../views/MyReturns.vue'
import CreateReturn from '../views/CreateReturn.vue'
import OrderDetail from '../views/OrderDetail.vue'
import ReturnDetail from '../views/ReturnDetail.vue'
import OrderPay from '../views/OrderPay.vue'
import MyAccount from '../views/MyAccount.vue'
import MyAddresses from '../views/MyAddresses.vue'
import AddressPage from '../views/AddressPage.vue'
import ChangePassword from '../views/ChangePassword.vue'
import AuthService, { Identity } from '@/services/auth.service';
import { IGenericResponse } from '@/services/interceptor'
import eventBus from '../services/eventBus';
import ProductDetailNew from '@/views/ProductDetailNew.vue'


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/productos',
    name: 'Products',
    component: Products
  },
  {
    path: '/productos/:id',
    name: 'ProductDetailNew',
    component: ProductDetailNew
  },
  {
    path: '/productos/marca/:brand_id/:slug',
    name: 'ProductsBrand',
    component: Products
  },
  {
    path: '/productos/categoria/:category_id/:slug',
    name: 'ProductsCategory',
    component: Products
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/aviso-de-privacidad',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/cambiar-contrasena',
    name: 'changePassword',
    component: ChangePassword
  },
  {
    path: '/quiero-ser-distribuidor',
    name: 'BecomeAWolesaler',
    component: BecomeAWolesaler
  },
  {
    path: '/producto/:id/:slug',
    name: 'ProductDetailNew',
    component: ProductDetailNew,
  },
  {
    path: '/mi-carrito-publico',
    name: 'PublicShoppingCart',
    component: PublicShoppingCart
  },
  {
    path: '/mi-carrito',
    name: 'ShoppingCart',
    component: ShoppingCart,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'PublicShoppingCart' });
      }, error => {
        next({ name: 'PublicShoppingCart' });
      });
    }
  },
  {
    path: '/mis-pedidos',
    name: 'MyOrders',
    component: MyOrders,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/solicitar-devolucion',
    name: 'CreateReturn',
    component: CreateReturn,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/solicitudes-de-devolucion',
    name: 'MyReturns',
    component: MyReturns,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/solicitudes-de-devolucion/:id',
    name: 'ReturnDetail',
    component: ReturnDetail,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/pedidos/:id',
    name: 'OrderDetail',
    component: OrderDetail,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/pedidos/:id/pagar',
    name: 'OrderPay',
    component: OrderPay,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/mis-direcciones',
    name: 'MyAddresses',
    component: MyAddresses,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/agregar-direccion',
    name: 'AddressPage',
    component: AddressPage,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/editar-direccion/:id',
    name: 'AddressPage',
    component: AddressPage,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/recuperar-contrasena/:id/:key',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  {
    path: '/recuperar-contrasena',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  {
    path: '/mi-cuenta',
    name: 'MyAccount',
    component: MyAccount,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:IGenericResponse) => {
        response.success == true ? next() : next({ name: 'Auth' });
      }, error => {
        next({ name: 'Auth' });
      });
    }
  },
  {
    path: '/password-reset/:success',
    name: 'PasswordResetResponse',
    component: PasswordResetResponse
  },
  {
    path: '/nosotros',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return desired position
    // if (to?.hash) {
    //   return {
    //     el: to.hash,
    //   }
    // }
    if (to && to.hash) {
      return {
          selector: to.hash,
          offset: { x: 0, y: 300 }, // avoid blocking the view when having fixed components
          behavior: 'smooth'
      };
  }
    else if  (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    eventBus.$emit('loading', true);
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  setTimeout(() => { 
    eventBus.$emit('loading', false);
  }, 500);
  
})

export default router
