
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import StoreService, { IOrder } from "@/services/store.service";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";

@Component({
  metaInfo(this: MyOrders): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
  },
})
export default class MyOrders extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Mis pedidos`,
      description: "Consulta tu historial de pedidos.",
      models: [],
      fields: [
        { key: "display_name", label: "Nombre" },
        { key: "x_studio_payment_status", label: "Estatus" },
        // { key: "x_studio_payment_method", label: "Estatus" },
        { key: "date_order", label: "Fecha" },
        { key: "amount_total", label: "Total" },
        { key: "id", label: "" },
        // { key: 'update', label: '' },
      ],
      numberPages: null,
      perPage: 15,
      // currentPage: 1,
    };
  }

  created() {
    this.loadModels(1);
  }

  loadModels(currentPage) {
    StoreService.getTotalOrders().then((models: any) => {
      this.$data.numberPages = Math.ceil(models / this.$data.perPage);
    });

    StoreService.getOrders(currentPage)
      .then((models: IOrder[]) => {
        this.$data.models = models;
      })
      .catch((error) => {
        this.$data.model = null;
      });
  }
  linkGen(pageNum) {
    return {
      path: `/mis-pedidos`,
      query: { page: pageNum },
    };
  }
  updateOrderByPaginate() {
    const page = this.$route.query.page ?? 1;
    this.loadModels(page);
  }
  cancelOrder(id) {
    // console.log(id);
    StoreService.cancelOrder(id)
      .then((resp) => {
        // console.log(resp);
        // this.$data.models = models;
        eventBus.$emit("showSuccessModal", "Solicitud enviada correctamente");
        this.loadModels(1);
      })
      .catch((error) => {
        // this.$data.model = null;
        console.log(error);
        eventBus.$emit("showErrorModal", "Datos enviados son incorrectos.");
      });
  }
}
