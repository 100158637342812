import { render, staticRenderFns } from "./BuyByPiece.vue?vue&type=template&id=bc2de56e&scoped=true&"
import script from "./BuyByPiece.vue?vue&type=script&lang=ts&"
export * from "./BuyByPiece.vue?vue&type=script&lang=ts&"
import style0 from "./BuyByPiece.vue?vue&type=style&index=0&id=bc2de56e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc2de56e",
  null
  
)

export default component.exports