
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import QtyInput from "@/components/shopping-cart/QtyInput.vue";
import { IProductDetail, IVariant } from "@/services/catalog.service";
import StoreService from "@/services/store.service";
import { IGenericResponse } from "@/services/interceptor";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { ILocalSaleOrderItem } from "@/services/local.store.service";

@Component({
  components: {
    "qty-input": QtyInput,
  },
})
export default class AddToCartForm extends Vue {
  @Prop() private product!: IProductDetail;
  @Prop() private variant!: IVariant;

  data() {
    return {
      form: {
        qty: 0,
      },
      model: null,
      selected: null,
    };
  }

  created() {
    this.$data.model = this.product;
    this.$data.selected = this.variant;
    // this.$data.maximum = this.$data.selected.maximum_pieces != 0 ? this.$data.selected.maximum_pieces : this.$data.selected.stock;
  }

  @Watch("product")
  onProductChange(newProduct: IProductDetail): void {
    this.$data.model = newProduct;
  }

  @Watch("variant")
  onVariantChange(newVariant: IVariant): void {
    this.$data.selected = newVariant;
  }

  onSubmit(event) {
    event.preventDefault();
    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      if (!this.$store.getters.isLoggedIn) {
        console.log("111");
        this.$store.commit("addLocalCartItem", {
          id: `${this.$data.model.id}_${this.$data.selected.id}`,
          variant: this.$data.selected,
          product: this.$data.model,
          qty: this.$data.form.qty,
          total: this.$data.form.qty * this.$data.model.price,
        } as ILocalSaleOrderItem);
        this.$data.form.qty = 0;
        this.$router.push({ name: "PublicShoppingCart" });
      } else {
        // console.log("222");
        // console.log(this.$data.selected.price);
        const qty = parseInt(this.$data.form.qty);
        StoreService.addItemToCart({
          order_id: this.$store.state.cart.id,
          name: this.product.name,
          product_id: this.$data.model.id,
          variant_id: this.$data.selected.id,
          qty: qty,
          price: this.$data.selected.price,
          // price: this.$data.model.price,
        })
          .then((response: IGenericResponse) => {
            if (response.success == true) {
              this.$data.selected = response.data.variant;
              this.$data.model.price = this.$data.selected.price;
              // this.$data.maximum = this.$data.selected.maximum_pieces != 0 ? this.$data.selected.maximum_pieces : this.$data.selected.stock;
              this.$emit("updateVariant", {
                selected: this.$data.selected,
              });

              this.$data.form.qty = 0;

              this.$store.commit("setShoppingCart", response.data.shoppingCart);
              this.$store.commit(
                "setShippingMethods",
                response.data.quoteShipping
              );
              this.$store.commit("loadShoppingCart");

              // this.$router.push({ name: 'ShoppingCart' });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  dec() {
    if (this.$data.form.qty > 0) {
      this.$data.form.qty = parseInt(this.$data.form.qty) - 1;
    }
  }

  inc() {
    const qty = parseInt(this.$data.form.qty);
    if (qty < this.$data.selected.stock) {
      this.$data.form.qty = qty + 1;
    }
  }

  @Validations()
  validations() {
    return {
      form: {
        qty: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.$data.selected.stock),
        },
      },
    };
  }
}
