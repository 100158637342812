
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import AuthService, { IAddress, Identity } from "@/services/auth.service";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";
import CatalogService, { ICountryState } from "@/services/catalog.service";
import { SelectOption } from "@/store";

@Component({
  metaInfo(this: AddressPage): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
  },
})
export default class AddressPage extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Agregar dirección`,
      description: "Puedes consultar y actualizar la información de tu cuenta.",
      model: null,
      form: {
        name: null,
        street: null,
        street2: "",
        zip: null,
        city: null,
        state_id: null,
        country_id: null,
        l10n_mx_edi_colony: null,
      },
      error: false,
      submitComplete: false,
      errorMessage: "",
      countries: [],
      states: [],
    };
  }

  @Validations()
  validations() {
    return {
      form: {
        name: { required },
        street: { required },
        street2: {},
        zip: { required },
        city: { required },
        state_id: { required },
        country_id: { required },
        l10n_mx_edi_colony: { required },
      },
    };
  }

  created() {
    this.updateModel();
  }

  getCountryStates() {
    CatalogService.getCountryStates(parseInt(this.$data.form.country_id))
      .then((models: ICountryState[]) => {
        this.$data.states = models.map((item: ICountryState) => {
          return {
            value: item.id,
            text: item.name,
          } as SelectOption;
        });
      })
      .catch((error) => {
        this.$data.states = [];
      });
  }

  updateModel() {
    if (this.$route.params.id != undefined) {
      AuthService.getAddress(parseInt(this.$route.params.id))
        .then((model: IAddress) => {
          // console.log(model);
          this.$data.model = model;
          this.$data.title = this.$data.model.name;
          this.$data.form.name = this.$data.model.name;
          this.$data.form.street = this.$data.model.street;
          this.$data.form.street2 =
            this.$data.model.street2 == false ? "" : this.$data.model.street2;
          this.$data.form.zip = this.$data.model.zip;
          this.$data.form.city = this.$data.model.city;
          this.$data.form.l10n_mx_edi_colony =
            this.$data.model.l10n_mx_edi_colony == false
              ? ""
              : this.$data.model.l10n_mx_edi_colony;

          CatalogService.getCountryStates(this.$data.model.country_id[0])
            .then((states: ICountryState[]) => {
              this.$data.states = states.map((item: ICountryState) => {
                return {
                  value: item.id,
                  text: item.name,
                } as SelectOption;
              });

              this.$data.form.country_id = this.$data.model.country_id[0];
              this.$data.form.state_id = this.$data.model.state_id[0];
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          this.$data.model = null;
        });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    this.$data.submitComplete = false;
    this.$data.error = false;
    this.$data.errorMessage = "";

    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      let data = { ...this.$data.form };
      if (this.$route.params.id != undefined) {
        AuthService.updateAddress(parseInt(this.$route.params.id), data)
          .then((model: IAddress) => {
            this.$data.model = model;
            this.$store.commit("loadAddresses");
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        AuthService.createAddress(data)
          .then((model: IAddress) => {
            this.$store.commit("loadAddresses");
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log("errorrrrrrrrrrrrrrrrrrrrr");
            console.log(error);
          });
      }
    } else {
      console.log("error");
    }
  }
}
