
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import StoreService, { IReturn } from "@/services/store.service";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";

@Component({
  metaInfo(this: MyReturns): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
  },
})
export default class MyReturns extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Solicitudes de devolución`,
      description: "Consulta tu historial de solicitudes de devolución.",
      models: [],
      fields: [
        { key: "index", label: "#" },
        { key: "x_name", label: "Nombre" },
        { key: "write_date", label: "Fecha" },
        { key: "x_studio_state", label: "Estatus" },
        { key: "x_studio_product", label: "Producto" },
        { key: "id", label: "" },
        // { key: 'update', label: '' },
      ],
    };
  }

  created() {
    this.loadModels();
  }

  loadModels() {
    StoreService.getReturns()
      .then((models: IReturn[]) => {
        this.$data.models = models;
        console.log(models[0]);
      })
      .catch((error) => {
        this.$data.model = null;
      });
  }

  getProduct(index: number) {
    return `${this.$data.models[index].x_studio_amount} x ${this.$data.models[index].x_studio_product}`;
  }

  getStatus(index: number) {
    let state = this.$data.models[index].x_studio_request;
    if (this.$data.models[index].x_studio_state == "status2") {
      state += " / Aceptada";
    } else if (this.$data.models[index].x_studio_state == "status3") {
      state += " / Rechazada";
    }

    return state;
  }
}
