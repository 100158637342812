
import { Component, Vue } from "vue-property-decorator";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

@Component({
  metaInfo(this: Terms): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
  },
})
export default class Terms extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: "Términos y condiciones",
      description:
        "El presente documento constituye un contrato de adhesión para el uso de la página de Internet https://www.lunastore.mx/.",
    };
  }
}
