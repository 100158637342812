
import { Component, Prop, Vue } from "vue-property-decorator";
import CatalogService, {
  IAttribute,
  IConfig,
  IConfigValue,
  IProduct,
  IProductDetail,
  IVariant,
  IVariantConfig,
  IVariantConfigValue,
  IVariantsAttribute,
  IVariantsAttributeValue,
} from "@/services/catalog.service";

@Component
export default class DownloadCatalogButton extends Vue {
  data() {
    return {
      timer: null,
      loading: false,
      download: null,
      modalShow: false,
    };
  }

  get models() {
    return this.$store.getters.getProductsCatalog;
  }

  async handleClick(showPrices = true) {
    // console.log(showPrices);
    this.$emit("download", showPrices);

    // this.$data.loading = true;
    // // this.$bvModal.show("bv-modal-download");
    // this.$data.modalShow = true;

    // // console.log(this.models);

    // let items = this.models
    //   .filter((model: IProductDetail) => {
    //     return model.stock_total > 0;
    //   })
    //   .map((model: IProductDetail) => {
    //     return {
    //       name: model.name,
    //       price: model.min_price.toFixed(2),
    //       showPrices: showPrices,
    //       picture: model.picture,
    //       stock: model.stock,
    //       variants_attributes: model.variants_attributes?.map(
    //         (va: IVariantsAttribute) => {
    //           return {
    //             name: va.name,
    //             vals: va.values
    //               .map((vav: IVariantsAttributeValue) => vav.name)
    //               .join(", "),
    //           };
    //         }
    //       ),
    //       variants: model.variants
    //         ?.filter((variant: IVariant) => variant.stock > 0)
    //         .map((variant: IVariant) => {
    //           // console.log(variant);
    //           const config = variant.config
    //             .map((vc: IConfig) =>
    //               vc.values.map((vcv: IConfigValue) => vcv.name).join(", ")
    //             )
    //             .join("&");
    //           const color = variant.config
    //             .map((vc: IConfig) =>
    //               vc.values
    //                 .map((vcv: IConfigValue) => vcv.html_color)
    //                 .join(", ")
    //             )
    //             .join("");
    //           const color_two = variant.config
    //             .map((vc: IConfig) =>
    //               vc.values
    //                 .map((vcv: IConfigValue) => vcv.html_color2)
    //                 .join(", ")
    //             )
    //             .join("");
    //           const variant_text = variant.config
    //             .map((vc: IConfig) =>
    //               vc.values.map((vcv: IConfigValue) => vcv.name).join(", ")
    //             )
    //             .join("/");

    //           // console.log(config);
    //           return {
    //             stock: variant.stock,
    //             variant: config,
    //             color: `${color}&${color_two}`,
    //             variant_text,
    //           };

    //           // return config;
    //         }),
    //       variant_text: model.variants
    //         ?.filter((variant: IVariant) => variant.stock > 0)
    //         .map((variant: IVariant) => {
    //           return variant.config
    //             .map((vc: IConfig) =>
    //               vc.values.map((vcv: IConfigValue) => vcv.name).join(", ")
    //             )
    //             .join("/");
    //         })
    //         .map((variant) => `<b>${variant}</b>`)
    //         .join(", "),
    //     };
    //   });

    // console.log(items);
    // this.$data.download = await CatalogService.generatePdfCatalogDocument({
    //   items,
    // });

    // this.$data.loading = false;
  }
}
