
import { Component, Vue } from 'vue-property-decorator';
import NavBar from './page-header/NavBar.vue';
import TopBanner from './page-header/TopBanner.vue';
import eventBus from '@/services/eventBus';
import StoreService, { ISaleOrder } from '@/services/store.service';
import AuthService, { Identity } from '@/services/auth.service';

@Component({
    components: {
    }
})
export default class CommingSoon extends Vue {
    data() {
        return {
        };
    }

    // signout() {
    //     this.$store.commit('setIdentity', null);
    // }

    // created() {
    //     eventBus.$on('identity-updated', (identity:Identity) => { 
    //         this.$data.identity = identity; 
    //     });

    //     this.$data.identity = AuthService.getIdentity();
    // }
}
