
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import { IAttr, IProduct, IValueOption } from "@/services/catalog.service";
import { filter } from "vue/types/umd";

@Component
export default class FilterOpt extends Vue {
  @Prop() attribute!: IAttr;

  data() {
    return {
      attrValueOptions: this.attribute.values,
      selected: [],
    };
  }

  @Watch("attribute")
  onAttributeChange(selected: string[]): void {
    this.$data.selectedAttrs = [];
  }

  toggle(value: string): void {
    let filters: IAttr[] = this.$store.state.filters;
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].key == this.attribute.key) {
        let ii = filters[i].values.findIndex(
          (v: IValueOption) => v.value == value
        );
        if (ii != -1) {
          // console.log(filters[i].values[ii].selected, !filters[i].values[ii].selected);
          filters[i].values[ii].selected = !filters[i].values[ii].selected;
          break;
        }
      }
    }

    this.$store.commit("setFilters", filters);
  }
}
