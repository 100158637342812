
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import BuyByPiece from "./BuyByPiece.vue";
import BuyByPackage from "./BuyByPackage.vue";
import BuyByBox from "./BuyByBox.vue";
import { IProductDetail } from "@/services/catalog.service";

@Component({
  components: {
    BuyByPiece,
    BuyByPackage,
    BuyByBox,
  },
})
export default class ProductInfo extends Vue {
  @Prop() private model!: IProductDetail;
  changeImg(namePicture) {
    this.$emit("changeImg", namePicture);

    // console.log(namePicture);
  }
  updateVariant(value) {
    this.$emit("updateVariant", value);
  }
}
