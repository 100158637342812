var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"carousel-home py-3",attrs:{"id":"featured-products"}},[_c('h2',{staticClass:"text-center mt-3 mb-5 text-uppercase"},[_vm._v(_vm._s(_vm.title))]),(_vm.loadingRelated)?_c('div',[_c('swiper',{attrs:{"navigation":true,"spaceBetween":20,"loop":true,"breakpoints":{
        '500': {
          slidesPerView: 3,
        },
        '900': {
          slidesPerView: 3,
        },
        '1200': {
          slidesPerView: 3,
        },
      },"autoplay":{
        delay: 4000,
        disableOnInteraction: false,
      }}},[_vm._l((4),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide"},[_c('PuSkeleton',{attrs:{"height":"250px"}})],1)}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1):_c('swiper',{attrs:{"navigation":true,"spaceBetween":20,"loop":true,"breakpoints":{
      '500': {
        slidesPerView: 2,
      },
      '900': {
        slidesPerView: 2,
      },
      '1200': {
        slidesPerView: 3,
      },
    },"autoplay":{
      delay: 4000,
      disableOnInteraction: false,
    }}},[_vm._l((_vm.model.related),function(model,index){return _c('swiper-slide',{key:index,staticClass:"slide"},[_c('product-item',{attrs:{"model":model}})],1)}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }