
import { IProductDetail } from "@/services/catalog.service";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Video from '@/components/Video.vue';

@Component({
    components: {
      'vimeo-video': Video,
    }
})
export default class VideoProduct extends Vue {
  @Prop() private model!: IProductDetail;

  hasVideo() {
    if(this.$data.model != null && this.$data.model.vimeo_id != null) {
      return this.$data.model.vimeo_id.trim().length != 0;
    }

    return false;
  }
}
