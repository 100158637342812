import axios from "axios";

export interface IBanner {
    id:                      number;
    name:                    string;
    content:                 string;
    location:                string;
    button_text:             string;
    button_link:             string;
    desktop_picture:         string;
    mobile_picture:          string;
    position:                number;
}

class BannerService {
    getAll():Promise<IBanner[]> {
        return axios.get(`/banners`);
    }
}

export default new BannerService();