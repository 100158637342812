var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$store.getters.featuredCategories.length != 0)?_c('div',{staticClass:"carousel-home py-5"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('swiper',{attrs:{"navigation":true,"spaceBetween":30,"loop":true,"autoplay":{
            delay: 4000,
            disableOnInteraction: false,
          },"breakpoints":{
            '320': {
              slidesPerView: 1,
            },
            '430': {
              slidesPerView: 2,
            },
            '680': {
              slidesPerView: 3,
            },
            '1000': {
              slidesPerView: 4,
            },
            '1600': {
              slidesPerView: 5,
            },
            '1700': {
              slidesPerView: 6,
            },
          }}},[_vm._l((_vm.$store.getters.featuredCategories),function(item,index){return _c('swiper-slide',{key:index,staticClass:"slide"},[_c('div',{staticClass:"category"},[_c('div',{staticClass:"bg-img",style:({ backgroundImage: `url(${item.picture})` })},[_c('router-link',{attrs:{"to":`/productos/categoria/${item.id}/${item.slug}`}},[_c('div',{staticClass:"vueperslide__content text-center"},[_c('div',{staticClass:"title px-2 text-break"},[_c('h3',{staticClass:"text-uppercase",domProps:{"innerHTML":_vm._s(item.name)}})])])])],1)])])}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }