
import { Component, Watch, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import StoreService, { IOrder, ISaleOrderItem } from "@/services/store.service";
import OrderSummary from "@/components/orders/OrderSummary.vue";
import StripeCardPayment from "@/components/orders/StripeCardPayment.vue";
import StripeOxxoPayment from "@/components/orders/StripeOxxoPayment.vue";
import BankPayment from "@/components/orders/BankPayment.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  metaInfo(this: OrderPay): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "order-summary": OrderSummary,
    "stripe-card-payment": StripeCardPayment,
    "stripe-oxxo-payment": StripeOxxoPayment,
    "bank-payment": BankPayment,
  },
})
export default class OrderPay extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Pagar pedido`,
      description:
        "Realiza el pago de tu pedido con tarjeta de crédito, debito, depósito o transferencia.",
      model: null,
      paymentMethod: "TDC",
      // isFollow: null,
      // form: {
      //   phone: null,
      // },
    };
  }
  @Validations()
  validations() {
    return {
      // form: {
      //   phone: { required },
      // },
    };
  }

  @Watch("$route.params", { deep: true })
  onModelChange(newVal: any): void {
    this.updateModel(parseInt(this.$route.params.id));
  }

  created() {
    this.updateModel(parseInt(this.$route.params.id));
  }

  updateModel(id: number) {
    StoreService.getOrder(id)
      .then((model: IOrder) => {
        this.$data.model = model;
        if (model.x_studio_payment_status == "Pagado") {
          this.$router.push(`/pedidos/${this.$data.model.id}`);
        }
      })
      .catch((error) => {
        this.$data.model = null;
      });
  }
}
