
import { Component, Prop, Vue } from "vue-property-decorator";
import { IProduct } from "@/services/catalog.service";

@Component
export default class ProductSearchItem extends Vue {
  @Prop() private model!: IProduct;

  data() {
    return {};
  }

  getAssetPath(image: string): string {
    let images = require.context("../../assets/", true, /\.(gif|jpg|png|svg)$/);
    return images(image);
  }

  get comingSoon() {
    if (this.model.coming_soon) {
      return new Date(this.model.coming_soon);
    }

    return null;
  }

  //
  handleClick() {
    this.$router.push(`/producto/${this.model.id}/${this.model.slug}`);
    this.$store.dispatch("toggleSearch");
  }
}
