
import { Component, Vue } from "vue-property-decorator";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import { Validate, Validations } from "vuelidate-property-decorators";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import MyAccountSidebar from "@/components/my-account/MyAccountSideBar.vue";
import { SelectOption } from "@/store";
import StoreService, {
  IOrder,
  IReturnReason,
  ISaleOrderItem,
} from "@/services/store.service";
import { IGenericResponse } from "@/services/interceptor";

@Component({
  metaInfo(this: CreateReturn): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    "my-account-sidebar": MyAccountSidebar,
  },
})
export default class CreateReturn extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: `Crear una solicitud de devolución`,
      description: "Puedes crear una nueva solicitud de devolución.",
      model: null,
      form: {
        order: null,
        product_tmpl_id: null,
        qty: null,
        reason: null,
        comment: null,
        evidence: null,
      },
      maxValue: 0,
      error: false,
      submitComplete: false,
      errorMessage: "",
      orders: [],
      products: [],
      reasons: [],
    };
  }

  updateMaxValue() {
    console.log("update");
    this.$data.maxValue = 0;
    const product = this.$data.model.items.find((item: ISaleOrderItem) => {
      return (
        item.type == "product" &&
        item.product_tmpl_id == this.$data.form.product_tmpl_id
      );
    });
    if (product != undefined) {
      this.$data.form.product = product.name;

      this.$data.maxValue = product.qty_delivered;
    }
  }

  getOrderDetail() {
    if (this.$data.form.order != null) {
      this.$data.products = [];
      StoreService.getOrder(this.$data.form.order)
        .then((model: IOrder) => {
          this.$data.model = model;
          //   console.log(this.$data.model.items);
          this.$data.products = this.$data.model.items
            .filter(
              (item: ISaleOrderItem) =>
                item.type == "product" && item.qty_delivered != 0
            )
            .map((item: ISaleOrderItem) => {
              return {
                value: `${item.product_tmpl_id}`,
                text: `${item.qty_delivered} x ${item.name}`,
              } as SelectOption;
            });
        })
        .catch((error) => {
          this.$data.model = null;
        });
    }
  }

  validateFile(event) {
    this.$data.form.evidence = event.target.files[0];
  }

  @Validations()
  validations() {
    return {
      orders: [],
      reasons: [],
      form: {
        order: { required },
        product_tmpl_id: { required },
        qty: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.$data.maxValue),
        },
        reason: { required },
        comment: { required: false },
        evidence: { required },
      },
    };
  }

  created() {
    StoreService.getOrders(-1, true)
      .then((models: IOrder[]) => {
        this.$data.orders = models
          .filter((item: IOrder) => {
            return item.sale_type == "order" && item.x_studio_delivered == true;
          })
          .map((item: IOrder) => {
            let date = item.date_order.toString().substring(0, 10);
            return {
              value: item.id,
              text: `${item.display_name} / ${date} / $${item.amount_total}`,
            } as SelectOption;
          });
      })
      .catch((error) => {
        this.$data.orders = [];
      });

    StoreService.getReturnReasons()
      .then((models: IReturnReason[]) => {
        this.$data.reasons = models.map((item: IReturnReason) => {
          return {
            value: item.id,
            text: item.x_name,
          } as SelectOption;
        });
      })
      .catch((error) => {
        this.$data.reasons = [];
      });
  }

  onSubmit(event) {
    event.preventDefault();

    this.$data.submitComplete = false;
    this.$data.error = false;
    this.$data.errorMessage = "";

    this!["$v"].$touch();
    if (!this!["$v"].$invalid && Boolean(this.$data.form.evidence)) {
      StoreService.uploadFile(this.$data.form.evidence)
        .then((result: IGenericResponse) => {
          if (result.success) {
            let data = { ...this.$data.form, evidence: result.data };

            StoreService.createReturn(data)
              .then((model: any) => {
                this.$data.model = model;
                this.$store.commit("loadAddresses");
                this.$router.go(-1);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("error");
    }
  }
}
