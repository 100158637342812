var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"top-slider"},[_c('vueper-slides',{staticClass:"no-shadow mx-auto my-1",attrs:{"arrows":false,"bullets":false,"slide-multiple":1,"visible-slides":5,"gap":3,"slide-ratio":1 / 2,"dragging-distance":200,"arrowsOutside":false,"fixed-height":"15px","breakpoints":{
      3000: { visibleSlides: 5 },
      2000: { visibleSlides: 5 },
      1600: { visibleSlides: 4 },
      1200: { visibleSlides: 4 },
      900: { visibleSlides: 2 },
      500: { visibleSlides: 1 },
    }},scopedSlots:_vm._u([{key:"arrow-left",fn:function(){return [_c('b-icon',{attrs:{"icon":"chevron-left","variant":"dark"}})]},proxy:true},{key:"arrow-right",fn:function(){return [_c('b-icon',{attrs:{"icon":"chevron-right","variant":"dark"}})]},proxy:true}])},_vm._l((10),function(model,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v(" Te faltan "),_c('strong',[_vm._v("5")]),_vm._v(" prendas para obtener un "),_c('strong',[_vm._v("15% de descuento")])])]},proxy:true}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }