
import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";
import AuthWidget from "@/components/auth/Auth.vue";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

@Component({
  metaInfo(this: Auth): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    auth: AuthWidget,
    "page-header": PageHeader,
    "page-footer": PageFooter,
  },
})
export default class Auth extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  data() {
    return {
      title: "Inicia sesión o crear una cuenta",
      description: "Registrate y obtén una cuenta.",
    };
  }
}
