
import { Component, Vue, Watch } from "vue-property-decorator";
import TopProducts from "@/components/products/TopProducts.vue";

import eventBus from "@/services/eventBus";
import { Validations } from "vuelidate-property-decorators";
import { required, minLength } from "vuelidate/lib/validators";
import CatalogService, {
  IProduct,
  IProductQuery,
} from "@/services/catalog.service";
import ProductSearchItem from "@/components/products/ProductSearchItem.vue";

export interface ResultType {
  type: string;
  label: string;
  data: any;
}

@Component({
  components: {
    "product-search-item": ProductSearchItem,
    "top-products": TopProducts,
  },
})
export default class Search extends Vue {
  data() {
    return {
      loading: false,
      timer: null,
      form: {
        query: "",
      },
      results: [],
    };
  }

  @Watch("form.query")
  onFomQueryChange(newVal: string, oldVal: string) {
    clearTimeout(this.$data.timer);
    this.$data.timer = setTimeout(async () => {
      this.search();
    }, 500);
  }

  @Validations()
  validations() {
    return {
      form: {
        query: { required, minLength: minLength(3) },
      },
    };
  }

  async onSubmit(event) {
    event.preventDefault();
    await this.search();
  }

  async search() {
    this!["$v"].$touch();
    if (!this!["$v"].$invalid) {
      try {
        // console.log(this.$data.form);

        this.$data.loading = true;
        let config = {
          brand_id: null,
          category_id: null,
          search: this.$data.form.query,
          page: 1,
          featured: null,
          top: null,
          filters: {
            tags: null,
            attributes: null,
          },
        } as IProductQuery;

        this.$data.results = await CatalogService.getProducts(config);
        this.$data.loading = false;
      } catch (e) {
        console.log({ e });
        this.$data.loginError = true;
        eventBus.$emit("showErrorModal", "Usuario o contraseña incorrectos.");
      }
    }
  }

  get tags() {
    this.$data.loading = true;
    let tags: string[] = [];
    for (let i = 0; i < this.$data.results.length; i++) {
      const new_tags = this.$data.results[i].meta_keywords
        .split(",")
        .filter((ntag: string) => {
          return tags.indexOf(ntag) == -1;
        });

      if (new_tags.length != 0) {
        tags.push(
          ...new_tags.filter(
            (tag: string) =>
              tag.toLowerCase().indexOf(this.$data.form.query.toLowerCase()) !=
              -1
          )
        );
      }
    }
    this.$data.loading = false;

    return tags;
  }

  get show() {
    return this.$store.getters.getShowSearch;
  }

  goTo(tag) {
    this.$router.push({ name: "Products", query: { search: [tag] } });
    this.$store.dispatch("toggleSearch");
  }

  prepareTag(tag) {
    return tag
      .toLowerCase()
      .replace(
        this.$data.form.query.toLowerCase(),
        `<b>${this.$data.form.query.toLowerCase()}</b>`
      );
  }

  handleClick() {
    this.$data.show = !this.$data.show;
  }
}
