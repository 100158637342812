<template>
  <section class="py-5">
    <b-container>
      <b-row class="">
        <b-col cols="12" lg="4" class="border-rigth pl-lg-4 mb-4 mb-lg-0">
          <h3 class="text-center text-lg-left text-uppercase mb-3">
            pago de tiendas de autoservicio
          </h3>
          <div class="d-flex  justify-content-center justify-content-lg-start">
            <img
              class=""
              width="70px"
              height="30px"
              src="../assets/oxxo.jpg"
              alt="7-eleven"
            />
            <!-- <img
              class="mr-2 mb-3 flex-xl-grow-1"
              width="70px"
              height="70px"
              src="../assets/pagos/7-eleven.png"
              alt="7-eleven"
            /> -->
            <!-- <img
              class="mr-2 mb-3 flex-xl-grow-1"
              width="80px"
              height="80px"
              src="../assets/pagos/walmart.png"
              alt="walmart"
            />
            <img
              class="mr-2 mb-3 flex-xl-grow-1"
              width="70px"
              height="70px"
              src="../assets/pagos/farmarcias-del-ahorro.png"
              alt="farmarcias-del-ahorro"
            /> -->
            <!-- <img
              class="mr-2 mb-3 flex-xl-grow-1"
              width="70px"
              height="70px"
              src="../assets/pagos/super-farmacia.png"
              alt="super-farmacia"
            /> -->
            <!-- <img
              width="70px"
              height="70px"
              class="mr-2 mb-3"
              src="../assets/pagos/waldos.png"
              alt="waldos"
            /> -->
            <!-- <img
              class="mr-2 mb-3"
              width="120px"
              height="70px"
              src="../assets/pagos/bodega-aurrera.png"
              alt="bodega-aurrera"
            /> -->
          </div>
          <!-- <div class="d-flex align-items-center justify-content-center">
            <img
              width="70px"
              height="70px"
              class="mr-2 mb-3"
              src="../assets/pagos/waldos.png"
              alt="waldos"
            />
            <img
              class="mr-2 mb-3"
              width="120px"
              height="70px"
              src="../assets/pagos/bodega-aurrera.png"
              alt="bodega-aurrera"
            />
          </div> -->
        </b-col>
        <b-col cols="12" lg="4" class="border-rigth pl-lg-4 mb-4 mb-lg-0 ">
          <h3 class="text-left text-uppercase mb-3 text-center">
            pago con tarjeta
          </h3>
          <div class="mt-2 d-flex justify-content-center">
            <img
              width="240"
              src="../assets/pagos/metodos-de-pagos.png"
              alt="metodos-de-pagos"
            />
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="pl-lg-4 mb-4 mb-lg-0">
          <h3 class="text-center text-lg-left text-uppercase mb-3">
            transferencias bancaria
          </h3>
          <div
            class="mt-2 d-flex justify-content-center justify-content-lg-start"
          >
            <img
              class="mr-3"
              width="100"
              src="../assets/pagos/bbva.png"
              alt="bbva"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
section {
  background-color: rgba(232, 232, 232, 1);
}
img {
  //   border: 1px solid red;
  object-fit: cover;
}
h3 {
}

@media (min-width: 992px) {
  .border-rigth {
    border: none !important;
    border-right: 1px solid black !important;
  }
  //   display: none;
}
// * {
//   border: 1px solid red;
// }
</style>
