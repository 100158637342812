
import { Component, Watch, Vue } from "vue-property-decorator";
import Parallax, { ParallaxBackground } from "@/components/Parallax.vue";
import ProductItem from "@/components/products/ProductItem.vue";
import Filters from "@/components/products/Filters.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import eventBus from "@/services/eventBus";
import { MetaInfo } from "vue-meta";
import CatalogService, {
  IBrand,
  ICategory,
  IProductQuery,
  IVariantsAttribute,
  IConfigValue,
  IConfig,
  IProductDetail,
  IVariant,
  IVariantsAttributeValue,
} from "@/services/catalog.service";

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import DownloadCatalogButton from "@/components/products/DownloadCatalogButton.vue";

@Component({
  metaInfo(this: Products): MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [{ name: "description", content: this.getPageDescription() }],
    };
  },
  components: {
    "page-header": PageHeader,
    "page-footer": PageFooter,
    parallax: Parallax,
    breadcrumb: Breadcrumb,
    "product-item": ProductItem,
    filters: Filters,
    "download-catalog-button": DownloadCatalogButton,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.name == "ProductsCategory") {
        //
        if (
          to.params?.category_id != from.params?.category_id &&
          from.params?.category_id != undefined
        ) {
          // console.log("pasooo");
          vm.$data.clearModel = true;
        }
      }
    });
  },
})
export default class Products extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Luna Trend`;
  }

  getPageDescription() {
    return this.$data.description;
  }
  // async linkGen(pageNum) {
  //   // return pageNum === 1 ? "?" : `?page=${pageNum}`;
  //   console.log(pageNum);
  //   if (pageNum != 1) {
  //     await this.loadModels(pageNum);
  //   }
  //   return pageNum === 1 ? "?" : `?page=${pageNum}`;
  // }

  // @Watch("currentPage")
  // onCurrentPage(newVal: any, oldVal): void {
  //   console.log(newVal);
  //   if (newVal != 1) {
  //     console.log("llamar productos");
  //     this.loadModels(newVal);
  //   }
  // }

  data() {
    return {
      title: "Nuestros productos",
      description: "Luna Trend",

      breadcrumbPath: [
        {
          text: "Inicio",
          href: "/",
        },
        // {
        //   text: "Productos",
        //   href: "/",
        // },
      ],
      timer: null,
      loading: false,
      loadingDowloand: false,
      download: null,
      modalShow: false,
      brand: null,
      category: null,
      page: 1,
      pageEnd: false,
      // models: [],
      items: [],
      parallax: {
        background: this.getAssetPath("./top-banner.jpg"),
        backgroundSize: "contain",
        backgroundMobile: this.getAssetPath("./top-banner.jpg"),
        backgroundMobileSize: "200%",
      } as ParallaxBackground,
      numberPages: null,
      perPage: 16,
      currentPage: 1,
      clearModel: false,
    };
  }

  get getProductsCatalog() {
    return this.$store.getters.getProductsCatalog;
  }

  get models() {
    return this.$store.getters.getProducts;
    // return this.$store.getters.getProducts.slice(
    //   (this.$data.currentPage - 1) * this.$data.perPage,
    //   this.$data.currentPage * this.$data.perPage
    // );
  }

  // loadMoreVisibilityChanged(
  //   isVisible: boolean,
  //   entry: IntersectionObserverEntry
  // ) {
  //   if (isVisible) {
  //     this.nextPage();
  //   }
  // }

  async created() {
    eventBus.$on("load-models", async () => {
      this.clearProductLists();
      // this.$data.$models = [];
      await this.loadModels(1);
    });

    this.$store.commit("setFilters", []);
    this.$data.page =
      this.$route.params?.page != undefined
        ? Number.parseInt(this.$route.params?.page)
        : 1;
    await this.loadModels(parseInt(this.$data.page));
  }

  @Watch("$route")
  onModelChange(newVal: any, oldVal): void {
    // this.$data.models = [];
    // console.log("cambiooo");
    this.$data.items = [];
    this.$data.breadcrumbPath = [
      {
        text: "Inicio",
        href: "/",
      },
      // {
      //   text: "Productos",
      //   href: "/",
      // },
    ];
    // this.$data.parallax.background = this.getAssetPath("./top-banner.jpg");
    // this.$data.parallax.backgroundMobile = this.getAssetPath(
    //   "./top-banner.jpg"
    // );

    eventBus.$emit("load-models");
    if (oldVal.name == "ProductsCategory") {
      // console.log(oldVal.params.category_id);
      // console.log(newVal.params.category_id);
      //
      if (
        oldVal.params?.category_id != newVal.params?.category_id &&
        newVal.params?.category_id != undefined
      ) {
        // console.log(vm);
        // console.log("pasooo");
        this.$data.clearModel = true;
      } else {
        this.$data.clearModel = false;
      }
    }
  }

  dowloadCatalog(showPrices) {
    this.loadProductsCatalog(showPrices);
  }

  // async nextPage() {
  //   this.$data.page += 1;
  //   await this.loadModels(parseInt(this.$data.page));
  // }

  linkGen(pageNum) {
    const attributes = this.$route.query.attributes ?? [];
    const brands = this.$route.query.brands ?? [];
    // console.log(brands);
    // console.log(attributes);
    // attributes: attributes.length != 0 ? attributes : null,
    let query = {};

    if (brands.length != 0) {
      query = {
        brands,
      };
    }
    if (attributes.length != 0) {
      query = {
        attributes,
      };
    }
    if (brands.length != 0 && attributes.length != 0) {
      query = {
        brands,
        attributes,
      };
    }

    // console.log(this.$route.query?.page);

    // console.log(pageNum);
    if (this.$data.category) {
      return {
        path: `/productos/categoria/${this.$data.category.id}/${this.$data.category.slug}`,
        query: { page: pageNum, ...query },
      };
    }

    if (this.$data.brand) {
      return {
        path: `/productos/marca/${this.$data.brand.id}/${this.string_to_slug(
          this.$data.brand.slug
        )}`,
        query: { page: pageNum, ...query },
      };
    }
  }

  clearProductLists() {
    this.$data.page = 1;
    this.$data.pageEnd = false;
    this.$store.dispatch("setProducts", []);
    this.$store.dispatch("setAttributes", []);
    this.$store.dispatch("setTags", []);
    this.$store.dispatch("setGenders", []);
    this.$store.dispatch("setDressings", []);
    this.$store.dispatch("setPockets", []);
    this.$store.dispatch("setSeasons", []);
    this.$store.dispatch("setTiros", []);
    this.$store.dispatch("setCuts", []);
    this.$store.dispatch("setTypes", []);
  }

  async loadModels(page) {
    this.$data.loading = true;

    const tags = this.$route.query.tags ?? [];
    const attributes = this.$route.query.attributes ?? [];
    const gender = this.$route.query.gender ?? [];
    const brands = this.$route.query.brands ?? [];
    const search = this.$route.query.search ?? null;

    let config = {
      brand_id: null,
      category_id: null,
      search: search,
      page: null,
      featured: null,
      top: null,
      filters: null,
      catalog: false,
    } as IProductQuery;

    // try {
    const currentPage =
      this.$route.query?.page != undefined ? this.$route.query?.page : "1";
    config.page = currentPage;

    config.brand_id =
      this.$route.params?.brand_id != undefined
        ? parseInt(this.$route.params?.brand_id)
        : null;
    config.category_id =
      this.$route.params?.category_id != undefined
        ? parseInt(this.$route.params?.category_id)
        : null;

    config.filters = {
      tags: tags.length != 0 ? tags : null,
      attributes: attributes.length != 0 ? attributes : null,
      brands: brands.length != 0 ? brands : null,
      gender: Array.isArray(gender)
        ? gender.length != 0
          ? gender
          : null
        : null,
    };

    if (config.brand_id != null) {
      // console.log(this.$store.getters.getAllBrands);
      const brand = this.$store.getters.getAllBrands.find(
        (brand: IBrand) => brand.id == config.brand_id
      );
      // console.log(brand);
      if (brand) {
        this.$data.brand = brand;

        this.setBreadCrumb(brand.name, brand.meta_description);
        if (brand.banner != null) {
          this.$data.parallax.background = brand.banner;
          this.$data.parallax.backgroundMobile = brand.banner;
        }
      }
    }

    if (config.category_id != null) {
      const category = this.$store.getters.getAllCategories.find(
        (category: ICategory) => category.id == config.category_id
      );
      // console.log(category);
      if (category) {
        this.$data.category = category;
        this.setBreadCrumb(category.name, category.meta_description);
        if (category.banner != null) {
          // console.log(category.banner);
          this.$data.parallax.background = category.banner;
          this.$data.parallax.backgroundMobile = category.banner;
        }
      }
    }

    // if (this.$data.page == 1) {
    this.clearProductLists();
    // console.time();
    Promise.all([
      CatalogService.getProducts(config),
      CatalogService.getProductsFilters(config),
    ]).then((responses) => {
      const products = responses[0];

      const total = responses[0][0].NumPages;

      // console.log("total", responses[0][0]);
      // console.log(total);
      this.$data.numberPages = total > 0 ? total : 1;

      this.$store.dispatch("setProducts", products);

      const filters = responses[1];
      if (filters) {
        this.$store.dispatch("sertBrands", filters.brands);
        this.$store.dispatch("setAttributes", filters.attributes);
        this.$store.dispatch("setTags", filters.tags);
        this.$store.dispatch("setGenders", filters.genders);
        this.$store.dispatch("setDressings", filters.dressings);
        this.$store.dispatch("setPockets", filters.pockets);
        this.$store.dispatch("setSeasons", filters.seasons);
        this.$store.dispatch("setTiros", filters.tiros);
        this.$store.dispatch("setCuts", filters.cuts);
        this.$store.dispatch("setTypes", filters.types);
      }

      this.$data.loading = false;
    });
    // }

    // else {
    //   Promise.all([CatalogService.getProducts(config)]).then((responses) => {
    //     console.log("sino", responses);
    //     const products = responses[0];
    //     products.length != 0
    //       ? this.$store.dispatch("appendProducts", products)
    //       : (this.$data.pageEnd = true);
    //     this.$data.loading = false;
    //   });
    // }

    // } catch (e) {
    //   console.log({ e });
    //   this.$data.loading = false;
    // }
  }
  async loadProductsCatalog(showPrices) {
    const tags = this.$route.query.tags ?? [];
    const attributes = this.$route.query.attributes ?? [];
    const gender = this.$route.query.gender ?? [];
    const brands = this.$route.query.brands ?? [];
    const search = this.$route.query.search ?? null;

    let config = {
      brand_id: null,
      category_id: null,
      search: search,
      page: null,
      featured: null,
      top: null,
      filters: null,
      catalog: true,
    } as IProductQuery;

    // try {

    config.page = 1;

    config.brand_id =
      this.$route.params?.brand_id != undefined
        ? parseInt(this.$route.params?.brand_id)
        : null;
    config.category_id =
      this.$route.params?.category_id != undefined
        ? parseInt(this.$route.params?.category_id)
        : null;

    config.filters = {
      tags: tags.length != 0 ? tags : null,
      attributes: attributes.length != 0 ? attributes : null,
      brands: brands.length != 0 ? brands : null,
      gender: Array.isArray(gender)
        ? gender.length != 0
          ? gender
          : null
        : null,
    };

    if (config.brand_id != null) {
      // console.log(this.$store.getters.getAllBrands);
      const brand = this.$store.getters.getAllBrands.find(
        (brand: IBrand) => brand.id == config.brand_id
      );
      // console.log(brand);
      if (brand) {
        this.setBreadCrumb(brand.name, brand.meta_description);
        if (brand.banner != null) {
          this.$data.parallax.background = brand.banner;
          this.$data.parallax.backgroundMobile = brand.banner;
        }
      }
    }

    if (config.category_id != null) {
      const category = this.$store.getters.getAllCategories.find(
        (category: ICategory) => category.id == config.category_id
      );
      // console.log(category);
      if (category) {
        this.$data.category = category;
        this.setBreadCrumb(category.name, category.meta_description);
        if (category.banner != null) {
          // console.log(category.banner);
          this.$data.parallax.background = category.banner;
          this.$data.parallax.backgroundMobile = category.banner;
        }
      }
    }

    this.$data.loadingDowloand = true;

    Promise.all([
      CatalogService.getProducts(config),
      CatalogService.getProductsFilters(config),
    ]).then(async (responses) => {
      const products = responses[0];

      this.$store.dispatch("setProductsCatalog", products);

      let items = this.getProductsCatalog
        .filter((model: IProductDetail) => {
          return model.stock_total > 0;
        })
        .map((model: IProductDetail) => {
          return {
            name: model.name,
            price: model.min_price.toFixed(2),
            showPrices: showPrices,
            picture: model.picture,
            stock: model.stock,
            variants_attributes: model.variants_attributes?.map(
              (va: IVariantsAttribute) => {
                return {
                  name: va.name,
                  vals: va.values
                    .map((vav: IVariantsAttributeValue) => vav.name)
                    .join(", "),
                };
              }
            ),
            variants: model.variants
              ?.filter((variant: IVariant) => variant.stock > 0)
              .map((variant: IVariant) => {
                // console.log(variant);
                const config = variant.config
                  .map((vc: IConfig) =>
                    vc.values.map((vcv: IConfigValue) => vcv.name).join(", ")
                  )
                  .join("&");
                const color = variant.config
                  .map((vc: IConfig) =>
                    vc.values
                      .map((vcv: IConfigValue) => vcv.html_color)
                      .join(", ")
                  )
                  .join("");
                const color_two = variant.config
                  .map((vc: IConfig) =>
                    vc.values
                      .map((vcv: IConfigValue) => vcv.html_color2)
                      .join(", ")
                  )
                  .join("");
                const variant_text = variant.config
                  .map((vc: IConfig) =>
                    vc.values.map((vcv: IConfigValue) => vcv.name).join(", ")
                  )
                  .join("/");

                // console.log(config);
                return {
                  stock: variant.stock,
                  variant: config,
                  color: `${color}&${color_two}`,
                  variant_text,
                };

                // return config;
              }),
            variant_text: model.variants
              ?.filter((variant: IVariant) => variant.stock > 0)
              .map((variant: IVariant) => {
                return variant.config
                  .map((vc: IConfig) =>
                    vc.values.map((vcv: IConfigValue) => vcv.name).join(", ")
                  )
                  .join("/");
              })
              .map((variant) => `<b>${variant}</b>`)
              .join(", "),
          };
        });

      // console.log(items);
      this.$data.download = await CatalogService.generatePdfCatalogDocument({
        items,
      });

      this.$data.loadingDowloand = false;
    });

    // this.$bvModal.show("bv-modal-download");
    this.$data.modalShow = true;
  }

  setBreadCrumb(title: string, description: string) {
    // this.$data.brand = brand;
    // this.$data.parallax = {
    //   background:               brand.banner,
    //   backgroundSize:           'contain',
    //   backgroundMobile:         brand.banner,
    //   backgroundMobileSize:     '200%'
    // } as ParallaxBackground;

    this.$data.title = title;
    this.$data.description = description;

    this.$data.breadcrumbPath = [
      {
        text: "Inicio",
        href: "/",
      },
      // {
      //   text: "Productos",
      //   href: `/`,
      // },
      {
        text: title,
        active: true,
      },
    ];
  }

  string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  }

  getAssetPath(image: string): string {
    let images = require.context("../assets/", true, /\.jpg$/);
    return images(image);
  }
}
