
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IOrder } from '@/services/store.service';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email } from 'vuelidate/lib/validators'
import StripeService, { IPaymentIntent } from '@/services/stripe.service';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
import { IGenericResponse } from '@/services/interceptor';

@Component({
    components: {
		SweetModal,
		SweetModalTab
	}
})
export default class StripeOxxoPayment extends Vue {
    @Prop() private model!:IOrder;

    data() {
        return {
        };
    }

    createOxxoPayment() {
        StripeService.payWithOxxo(this.model.id, this.$data.form).then((response:IGenericResponse) => {
            if(response.success) {
                if(response.data.url != null) {
                    window.location.href = response.data.url;
                }
            }
        }, error => {
            console.log(error);
        });
    }

}
